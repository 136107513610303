import * as React from "react";
import * as levelsCss from "./css/permissionLevelsCss";
import * as permissionsUtils from "../permissions-utils";
import {
	getDomain,
	getProject
} from "../permissions-utils";
import {PermissionBulletData} from "./PermissionBulletData";
import {
	ApiCaller_PermissionsAccessLevel,
	ApiCaller_PermissionsDomain,
	ApiCaller_PermissionsProject,
	OnPermissionsDomainsLoaded,
	OnPermissionsGroupsLoaded,
	OnPermissionsLevelsLoaded,
	OnPermissionsProjectsLoaded,
	OnPermissionsUsersLoaded
} from "@intuitionrobotics/permissions/frontend";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {
	DB_PermissionAccessLevel,
	DB_PermissionDomain,
	DB_PermissionProject
} from "@intuitionrobotics/permissions/shared/manager-types";
import {
	LevelIdObject,
	PermissionsDomainLevel
} from "../permissions-api/PermissionsDomainLevel";
import {CustomDropdown} from "../../../components/CustomDropdown";
import * as apiCss from "../permissions-api/css/permissionsApiCss";
import {Loader} from "../../../ui/Loader";

const icon__add = require('@res/images/icon_add_blue.svg');

type State = {
	selectedLevelId: string,
	newGroupLevelMode: boolean,
	openDomainLevel: boolean,
	showLoader: boolean
}

type Props = {
	domains: DB_PermissionDomain[],
	accessLevelIds: string[],
	hideExistDomains: string[],
	updateUnit: (accessIds: string[]) => void
}

export class PermissionUnitLevels
	extends BaseComponent<Props, State>
	implements OnPermissionsLevelsLoaded, OnPermissionsProjectsLoaded, OnPermissionsGroupsLoaded, OnPermissionsUsersLoaded, OnPermissionsDomainsLoaded {

	private levels: DB_PermissionAccessLevel[];
	private projects: DB_PermissionProject[];
	private domains: DB_PermissionDomain[];
	private selectedProjectId: string;

	constructor(props: Props) {
		super(props);

		this.state = {
			selectedLevelId: '',
			newGroupLevelMode: false,
			openDomainLevel: false,
			showLoader: false
		}

		this.selectedProjectId = '';
		this.levels = [];
		this.projects = [];
		this.domains = [];
		this.selectLevel = this.selectLevel.bind(this);
		this.deleteGroupLevel = this.deleteGroupLevel.bind(this);
	}

	__onPermissionsLevelsLoaded = () => {
		this.forceUpdate();
	}

	__onPermissionsDomainsLoaded = () => {
		this.forceUpdate();
	}

	__onPermissionsGroupsLoaded = () => {
		this.setState({showLoader: false});
	}

	__onPermissionsUsersLoaded = () => {
		this.setState({showLoader: false});
	}

	__onPermissionsProjectsLoaded = () => {
		this.forceUpdate();
	}

	async componentDidMount() {
		ApiCaller_PermissionsAccessLevel.query();
		ApiCaller_PermissionsProject.query();
		ApiCaller_PermissionsDomain.query();
	}

	getLevel = (levelId: string) => {
		return this.levels.find((level) => level._id === levelId);
	}

	selectLevel(methodId: string) {
		const newSelectedMethod = this.levels.find((method) => {
			return method._id === methodId;
		}) || {_id: ''};

		this.setState({selectedLevelId: newSelectedMethod._id, newGroupLevelMode: false});
	}

	deleteGroupLevel(levelId: string) {
		const {selectedLevelId} = this.state;

		this.props.updateUnit(this.props.accessLevelIds.filter(e => e !== levelId));

		this.setState({
			              showLoader: true,
			              selectedLevelId: selectedLevelId === levelId ? "" : this.state.selectedLevelId
		              });
	}

	handleLevelUpdated = (level: LevelIdObject) => {
		this.props.updateUnit([...this.props.accessLevelIds, level.id]);
		this.setState({showLoader: true});
	}

	renderLevelNames() {
		const {selectedLevelId} = this.state;
		const {accessLevelIds} = this.props;
		this.levels = ApiCaller_PermissionsAccessLevel.getAllLevels();

		const levels = accessLevelIds.map((levelId, index) => {
			const isOn = levelId === selectedLevelId;
			const level = this.getLevel(levelId);
			if (!level)
				return null;

			const domain = getDomain(level.domainId, this.domains);
			if (!domain)
				return null;

			const project = getProject(domain.projectId, this.projects);
			if (!project)
				return null;

			return <PermissionBulletData onLevelDeleted={this.deleteGroupLevel} onMethodSelected={this.selectLevel} isOn={isOn} key={index}
			                             bulletData={{_id: level._id, name: `${project.name}--${domain.namespace}--${level.name}`}}/>
		});

		return (<div className={`${levelsCss.methodsContainerStyle} ll_h_c`}>{levels}</div>);
	}

	renderAddGroupLevel() {
		const {newGroupLevelMode, selectedLevelId} = this.state;
		if (selectedLevelId || !newGroupLevelMode)
			return;

		return <div style={{marginBottom: '25px', marginTop: '5px'}}>
			<div style={{width: '300px'}}>
				<div className={apiCss.ddlLabelStyle}>Project</div>
				<CustomDropdown menuDirection={'top'} placeholder={'Choose project from list'} onChange={(option) => {
					this.selectedProjectId = option.value;
					this.setState({openDomainLevel: true});
				}} options={permissionsUtils.getDDlOptions("name", "_id", this.projects)}/>
			</div>
			{this.renderAddPermissionsDomainLevel()}
		</div>
	}


	renderDisplayGroupLevel() {
		const {newGroupLevelMode, selectedLevelId} = this.state;
		if (!selectedLevelId || newGroupLevelMode)
			return;

		const selectedLevel = this.getLevel(selectedLevelId);
		if (!selectedLevel)
			return;

		const selectedDomain = getDomain(selectedLevel.domainId, this.domains);
		if (!selectedDomain)
			return;

		const selectedProject = getProject(selectedDomain.projectId, this.projects);
		if (!selectedProject)
			return;

		this.selectedProjectId = selectedProject._id;
		const selectedProjectDdlOption = {value: selectedProject._id, label: selectedProject.name};

		return <div style={{marginBottom: '25px', marginTop: '5px'}}>
			<div style={{width: '300px'}}>
				<div className={apiCss.ddlLabelStyle}>Project</div>
				<CustomDropdown menuDirection={'top'} placeholder={'Choose project from list'} isDisabled={true} value={selectedProjectDdlOption} onChange={() => {
				}}
				                options={permissionsUtils.getDDlOptions("name", "_id", this.projects)}/>
			</div>
			{this.renderPermissionsDomainLevel(selectedDomain._id, selectedLevel._id)}
		</div>
	}

	renderAddPermissionsDomainLevel() {
		const {openDomainLevel} = this.state;
		if (!openDomainLevel)
			return;

		return this.renderPermissionsDomainLevel();
	}

	renderPermissionsDomainLevel(selectedDomainId?: string, selectedLevelId?: string) {
		const {hideExistDomains, domains} = this.props;
		return <PermissionsDomainLevel key={selectedLevelId} hideRowActions={true}
		                               domains={domains}
		                               hideExistDomains={selectedDomainId ? [] : hideExistDomains}
		                               isDisabled={!!selectedDomainId}
		                               domainId={selectedDomainId || ''}
		                               filterByProjectId={this.selectedProjectId}
		                               levelIdObject={{id: selectedLevelId || ''}}
		                               onLevelUpdated={(level: LevelIdObject) => {
			                               this.handleLevelUpdated(level);
			                               this.setState({newGroupLevelMode: false});
		                               }}/>;
	}

	renderNewLevel() {
		return <div id={"specific"} className={`${levelsCss.methodsContainerStyle} ${levelsCss.addMethodContainer}`}>
			<img onClick={() => {
				this.setState({selectedLevelId: '', newGroupLevelMode: true, openDomainLevel: false});
			}} className={levelsCss.addMethodIcon} src={icon__add}/>
		</div>;
	}

	renderLoader() {
		const {showLoader} = this.state;
		if (!showLoader)
			return;

		return <Loader/>;
	}

	render() {
		this.projects = ApiCaller_PermissionsProject.getProjects();
		this.domains = ApiCaller_PermissionsDomain.getAllDomains();
		return <>
			<div style={{marginTop: '15px', color: '#3d474f'}}>Access levels:</div>
			<div className={'ll_h_c'}>
				{this.renderNewLevel()}
				{this.renderLevelNames()}
			</div>
			{this.renderAddGroupLevel()}
			{this.renderDisplayGroupLevel()}
			{this.renderLoader()}
		</>
	}
}
