import * as React from "react";
import * as levelsCss from "./css/permissionLevelsCss";
import {PermissionsLevelValue} from "./PermissionsLevelValue";
import * as permissionsCss from "../css/permissionsCss";
import {DB_PermissionAccessLevel} from "@intuitionrobotics/permissions/shared/manager-types";

type State = {
	editMode: boolean,
	inputLevelNameChange: boolean
}

type Props = {
	action1: action,
	action2: action,
	mode: updateLevelDisplayMode,
	level: DB_PermissionAccessLevel,
	updateLevel: () => void
}

export enum updateLevelDisplayMode {
	newLevel  = 1,
	editLevel = 2
}


export type action = {
	text: string,
	callback: (level: DB_PermissionAccessLevel) => void
}

export class UpdateLevel
	extends React.Component<Props, State> {
	private inputRef: React.RefObject<HTMLInputElement> = React.createRef();

	constructor(props: Props) {
		super(props);
		this.state = {
			editMode: false,
			inputLevelNameChange: false
		}

		this.handleLevelNameChange = this.handleLevelNameChange.bind(this);
	}


	renderActions() {
		const {action1, action2, level} = this.props;
		return <div>
			<span onClick={() => {action1.callback(level)}} className={permissionsCss.cancelTextStyle}>{action1.text}</span>
			<span onClick={() => {this.createLevel()}} className={permissionsCss.saveTextStyle}>{action2.text}</span>
		</div>;
	}

	createLevel = () => {
		const {action2, level} = this.props;
		action2.callback(level)
	}

	handleInputOnBlur = () => {
		const {level, mode} = this.props;

		if (mode !== updateLevelDisplayMode.newLevel && level.name)
			this.setState({editMode: false});

		this.handleUpdateName();
	}

	renderLevelName() {
		const {level, mode} = this.props;
		const {editMode} = this.state;

		if (editMode || mode === updateLevelDisplayMode.newLevel)
			return <div className={levelsCss.inputContainer}>
				<input autoFocus value={level.name} onChange={this.handleLevelNameChange} onBlur={() => {this.handleInputOnBlur()}} ref={this.inputRef} className={levelsCss.domainInputStyle} placeholder={"Type level name"}/>
			</div>;

		return <div className={levelsCss.levelNameReadModeContainer}>
			<span onClick={() => {this.setState({editMode: true})}} className={levelsCss.levelNameReadMode}>{level.name}</span>
		</div>;
	}

	handleUpdateValue = (levelValue: number) => {
		const {level} = this.props;
		level.value = levelValue;
		this.handleUpdateLevel();
	}

	handleUpdateLevel() {
		const {level, updateLevel, mode} = this.props;
		if (mode !== updateLevelDisplayMode.newLevel && level.name)
			updateLevel();
	}

	handleUpdateName = () => {
		const levelName = this.inputRef?.current?.value;
		if (!levelName)
			return;

		this.handleUpdateLevel();
	}

	handleLevelNameChange() {
		const {level} = this.props;
		const levelName = this.inputRef?.current?.value;

		level.name = levelName || '';
		this.setState({inputLevelNameChange: true});
	}

	renderLevelValue() {
		const {level} = this.props;
		return <PermissionsLevelValue readOnly={false} levelNumber={level.value || 0} createLevel={() => {this.createLevel()}} updateValue={this.handleUpdateValue} />;
	}

	render() {
		return <div className={'ll_h_c'}>
			<div className={`ll_h_c ${levelsCss.updateLevelContainer}`}>
				{this.renderLevelName()}
				{this.renderLevelValue()}
				</div>
			{this.renderActions()}
		</div>
	}
}
