import * as React from "react";
import { StringMap } from "@intuitionrobotics/ts-common";
import {CustomField} from "./CustomField";
import * as levelsCss from "../permissions-levels/css/permissionLevelsCss";

const icon__add = require('@res/images/icon_add_blue.svg');

type State = {
	addClicked: boolean
}

type Props = {
	customFields: StringMap[],
	limitToOneCF?: boolean,
	onCustomFieldsUpdate: (customFields: StringMap[]) => void
}

export class CustomFields
	extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			addClicked: false
		}
	}

	handleAddCustomField() {
		const {customFields} = this.props;
		customFields.unshift({});
		this.setState({addClicked: true});
	}

	renderAddCF = () => {
		const {limitToOneCF} = this.props;
		if (limitToOneCF)
			return null;

		return <div style={{cursor: 'pointer', marginTop: '10px'}}>
			<img className={levelsCss.addMethodIcon} onClick={() => {this.handleAddCustomField()}} src={icon__add}/>
		</div>;
	}

	render() {
		const {customFields, onCustomFieldsUpdate} = this.props;
		const customFieldList = customFields.map((customField, index) => <CustomField key={index} onCustomFieldUpdate={() => {onCustomFieldsUpdate(customFields)}} customField={customField}/>);
		return <>
			{this.renderAddCF()}
			{customFieldList}
		</>;
	}
}
