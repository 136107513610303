import {Module} from "@intuitionrobotics/ts-common";
import {ThunderDispatcher, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {
	KasperoAllUnpairedUnits,
	KasperoUnitFullList,
	KasperoUnitFullListByEnvs,
	KasperoUnpairDelete
} from "@app/ir-q-app-common/types/api";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {DB_UnpairedUnit, FullUnitConfig, Response_FullListUnitsByEnvs, Unit} from "@app/ir-q-app-common/types/units";

export interface OnPairedUnitsLoaded {
	__onPairedUnitsLoaded: () => void;
}

export interface OnPairedUnitsByEnvsLoaded {
	__onPairedUnitsByEnvsLoaded: () => void;
}

export interface OnUnpairedUnitsLoaded {
	__onUnpairedUnitsLoaded: () => void;
}

const dispatch_onPairedUnitsLoaded = new ThunderDispatcher<OnPairedUnitsLoaded, "__onPairedUnitsLoaded">("__onPairedUnitsLoaded");
const dispatch_onPairedUnitsByEnvsLoaded = new ThunderDispatcher<OnPairedUnitsByEnvsLoaded, "__onPairedUnitsByEnvsLoaded">("__onPairedUnitsByEnvsLoaded");
const dispatch_onUnpairedUnitsLoaded = new ThunderDispatcher<OnUnpairedUnitsLoaded, "__onUnpairedUnitsLoaded">("__onUnpairedUnitsLoaded");

export class UnitsModule_Class
	extends Module {
	private units: FullUnitConfig[] = [];
	private unitsByEnvs: Response_FullListUnitsByEnvs = {
		dev: [],
		stg: [],
		prod: []
	};
	private unpairedUnits: DB_UnpairedUnit[] = [];

	getUnits = () => this.units;

	getUnitsByEnvs = () => this.unitsByEnvs;

	getUnpairedUnits = () => this.unpairedUnits;

	fetchUnits = () => {
		XhrHttpModule
			.createRequest<KasperoUnitFullList>(HttpMethod.GET, 'full-list')
			.setRelativeUrl("/v1/unit/full-list")
			.execute(async response => {
				this.units = response;
				dispatch_onPairedUnitsLoaded.dispatchUI();
			});
	}

	fetchUnitsByEnvs = () => {
		XhrHttpModule
			.createRequest<KasperoUnitFullListByEnvs>(HttpMethod.GET, 'full-list-by-envs')
			.setRelativeUrl("/v1/kaspero-proxy/unit/full-list-by-envs")
			.execute(async response => {
				this.unitsByEnvs = response;
				dispatch_onPairedUnitsByEnvsLoaded.dispatchUI();
			});
	}

	fetchUnpairedUnits = () => {
		XhrHttpModule
			.createRequest<KasperoAllUnpairedUnits>(HttpMethod.GET, 'get-all-unpaired-units')
			.setRelativeUrl("/v1/unit/get-all-unpaired-units")
			.execute(async response => {
				this.unpairedUnits = response;
				dispatch_onUnpairedUnitsLoaded.dispatchUI();
			});
	}

	unpairUnitsAndDeleteGoogleAccount = (units: Unit[]) => {
		XhrHttpModule
			.createRequest<KasperoUnpairDelete>(HttpMethod.POST, 'unpair-delete')
			.setRelativeUrl("/v1/gsuite/unpair-delete")
			.setJsonBody({units})
			.setOnSuccessMessage("The units are unpaired and their google account deleted")
			.setOnError("Failed unpairing and deleting google accounts")
			.execute(async () => {
				UnitsModule.fetchUnits();
				UnitsModule.fetchUnitsByEnvs();
			});

	}
}

export const UnitsModule = new UnitsModule_Class("UnitsModule");
