
import * as React from "react";
import {
	BaseComponent,
	RoutingModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {Page_Permissions} from "../pages/Page_Permissions";
import {Page_Logout, Page_Register} from "../pages/Account_Pages";
import {Route_Logout, Route_Register} from "./navigation-bar";
import {Page_Permissions_Groups} from "../pages/Page_Permissions_Groups";
import {Page_Permissions_Users} from "../pages/Page_Permissions_Users";
import {Page_Products} from "../pages/Page_Products";
import {Page_PairedUnits} from "../pages/Page_PairedUnits";
import {Page_UnitConfigurations} from "../pages/Page_UnitConfigurations";
import {Page_AddingDBUnitConfItem} from "../pages/Page_AddingDBUnitConfItem";
import * as emotion from "emotion";
import {ServicesModule} from "@modules/ServicesModule";
import {LoginModule} from "@modules/LoginModule";
import {Page_BackedUpAgents} from "../pages/Page_BackedUpAgents";
import {Page_Versions} from "../pages/Page_Versions";
import {Page_Permissions_To_Users} from "../pages/Page_Permissions_To_Users";
import {Page_PairedUnitsActivationByEnvs} from "../pages/Page_PairedUnitsActivationByEnvs";
import {Page_MigrateFMContacts} from "../pages/Page_MigrateFMContacts";
import {Page_MigrateData} from "../pages/Page_MigrateData";

export const Route_Unit_Configurations = "unit-configurations";
export const Route_Add_DB_Unit_Conf_Item = "unit-conf-item";
export const Route_Backed_Up_Agents_List = "backed-up-agents";
export const Route_Products = "products";
export const Route_Versions = "versions";
export const Route_MigrateFMContacts = "migrate-fm-contacts";
export const Route_MigrateData = "migrate-data"
export const Route_PairedList = "paired-list";
export const Route_PairedListActivationByEnv = "paired-list-activation-by-env";
export const Route_Permissions = "permissions";
export const Route_Permissions_To_Users = "permissions-to-users";
export const Route_Permissions_Groups = "permissions-groups";
export const Route_Permissions_Users = "permissions-users";
export const Route_Home = "home";

export const ParamKey_UserPermissions = "user";

export const registerRoutes = () => {
	RoutingModule.clearRoutes();
	RoutingModule.addRoute(Route_Unit_Configurations, "/unit-configurations", Page_UnitConfigurations);
	RoutingModule.addRoute(Route_Add_DB_Unit_Conf_Item, "/unit-conf-item", Page_AddingDBUnitConfItem);
	RoutingModule.addRoute(Route_Backed_Up_Agents_List, "/backed-up-agents", Page_BackedUpAgents);
	RoutingModule.addRoute(Route_Products, "/products", Page_Products);
	RoutingModule.addRoute(Route_Versions, "/versions", Page_Versions);
	RoutingModule.addRoute(Route_PairedList, "/paired-list", Page_PairedUnits);
	RoutingModule.addRoute(Route_MigrateFMContacts, "/migrate-fm-contacts", Page_MigrateFMContacts);
	RoutingModule.addRoute(Route_MigrateData, "/migrate-data", Page_MigrateData);
	RoutingModule.addRoute(Route_PairedListActivationByEnv, "/paired-list-activation-by-env", Page_PairedUnitsActivationByEnvs);
	RoutingModule.addRoute(Route_Permissions_To_Users, "/permissions-to-users", Page_Permissions_To_Users);
	RoutingModule.addRoute(Route_Permissions, "/permissions", Page_Permissions);
	RoutingModule.addRoute(Route_Permissions_Groups, "/permissions-groups", Page_Permissions_Groups);
	RoutingModule.addRoute(Route_Permissions_Users, "/permissions-users", Page_Permissions_Users);
	RoutingModule.addRoute(Route_Logout, "/logout", Page_Logout);
	RoutingModule.addRoute(Route_Register, "/register", Page_Register);
	RoutingModule.addRoute(Route_Home, "/", Page_Main).setExact(false);
};

export class LoggedBar
	extends React.Component {

	render() {
		registerRoutes();

		return RoutingModule.getRoutesMap();
	}
}

const logoStyle = emotion.css`
	width: 50px
`;

const logoLabelStyle = emotion.css`
	margin-left: 15px
`;

const hoverServiceStyle = emotion.css`
	background-color: aliceblue
`;

const logoHoverLabel = emotion.css`
	text-decoration: underline
`;

const servicesContainer = emotion.css`
	padding: 10px 10px 5px 10px;
	box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px
`;

type State = {
	serviceHoverKey: string
}

class Page_Main
	extends BaseComponent<{}, State> {
	private readonly redirecting: boolean;

	constructor(props: {}) {
		super(props);
		this.redirecting = !!LoginModule.goToApp();
		this.state = {
			serviceHoverKey: ''
		};
	}

	render() {
		if (this.redirecting)
			return null;

		const serviceList = ServicesModule.getServices().map(service => <div key={service.key} onMouseEnter={() => {
			this.setState({serviceHoverKey: service.key});
		}} onMouseLeave={() => {
			this.setState({serviceHoverKey: ''});
		}} onClick={() => LoginModule.goToService(service.link)} style={{
			border: "1px solid #808080ad",
			borderRadius: 1,
			marginBottom: "10px",
			width: "300px",
			padding: "5px",
			cursor: "pointer"
		}} className={service.key === this.state.serviceHoverKey ? `${hoverServiceStyle} ll_h_c` : 'll_h_c'}>
			<img alt={'service logo'} className={logoStyle} src={service.logo}/>
			<label style={{cursor: "pointer"}} className={service.key === this.state.serviceHoverKey ? `${logoHoverLabel} ${logoLabelStyle}` : `${logoLabelStyle}`}>{service.name}</label>
		</div>);

		return <div style={{position: "fixed", height: "100%", width: "100%", justifyContent: "center"}} className={'ll_v_c'}>
			<div className={servicesContainer}>
				{serviceList}
			</div>
		</div>;
	}
}
