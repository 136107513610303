import * as emotion from "emotion";

export const methodNameStyle = emotion.css`
	margin-left: 10px;
	display: inline-block;
  color: #9ca8af;
	border-radius: 12px;
  border: solid 1px #b1b4b5;
  font-size: 14px;
  padding: 1px 7px 1px 7px;
  cursor: pointer;
  height: auto;
`;

export const methodNameOnStyle = emotion.css`
	margin-left: 10px;
	display: inline-block;
  color: white;
	border-radius: 12px;
	background-color: #157dd2;
  border: solid 1px #157dd2;
  font-size: 14px;
  padding: 1px 7px 1px 7px;
  cursor: pointer;
  height: auto;
`;