import * as React from "react";
import * as projectsCss from "../permissions-projects/css/permissionsProjectsCss";
import {DB_PermissionProject} from "@intuitionrobotics/permissions";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import { GroupList } from "../permissions-groups/GroupList";

type State = {
	selectedGroupId: string
}

type Props = {}

export class PermissionsGroupsContainer
	extends BaseComponent<Props, State> {
	private groupsList: DB_PermissionProject[];

	constructor(props: {}) {
		super(props);
		this.state = {
			selectedGroupId: ''
		};

		this.selectGroup = this.selectGroup.bind(this);
		this.groupsList = [];
	}

	private renderGroupsContainer = () => {
		return <>
			<div className={projectsCss.projectsListContainer}>
				{this.renderGroups()}
			</div>
			</>
	};

	private renderGroups = () => {
		return <GroupList />;
	};

	selectGroup(groupId: string) {
		const newSelectedProject = this.groupsList.find((project) => {
			return project._id === groupId;
		}) || { _id: ''};

		this.setState({selectedGroupId: newSelectedProject._id});
	}

	render() {
		return <div className={projectsCss.projectsPageContainer}>
			{<div className={projectsCss.projectsTitleStyle}>GROUPS PERMISSIONS</div>}
			<div className={projectsCss.sectionsWrapperStyle}>
				{this.renderGroupsContainer()}
			</div>
		</div>
	}
}
