import * as React from "react";
import * as domainsCss from "./css/domainsCss";
import {PermissionLevels} from "../permissions-levels/PermissionLevels";
import {DB_PermissionDomain} from "@intuitionrobotics/permissions";
import * as levelsCss from "../permissions-levels/css/permissionLevelsCss";
import {AreYouSureDialog} from "../AreYouSureDialog";

const icon__trash = require('@res/images/icn-trash.svg');

type State = {
	displayHoverClass: boolean,
	editMode: boolean,
	inputDomainNameChange: boolean
}

type Props = {
	isOn: boolean,
	domain: DB_PermissionDomain,
	onDomainSelected: (src: string) => void;
	onDeleteDomain: (src: string) => void;
	onUpdateDomain: () => void;
}

export class DomainItem
	extends React.Component<Props, State> {
	private inputRef: React.RefObject<HTMLInputElement> = React.createRef();

	constructor(props: Props) {
		super(props);
		this.state = {
			displayHoverClass: false,
			editMode: false,
			inputDomainNameChange: false,
		}

		this.handleClickOnDomainName = this.handleClickOnDomainName.bind(this);
		this.handleDomainNameChange = this.handleDomainNameChange.bind(this);
	}

	deleteDomain = () => {
		const {onDeleteDomain, domain} = this.props;
		new AreYouSureDialog(<div></div>, () => {onDeleteDomain(domain._id)}, `Are you sure you want to delete domain item: ${domain.namespace}?`).show();
	}

	renderRowActions = () => {
		const {isOn} = this.props;
		if (!isOn) {
			return null;
		}

		return <div style={{position: "absolute", right: 0}}>
			<img onClick={(event) => {
				event.stopPropagation();
				this.deleteDomain()
			}} style={{marginLeft: '20px', verticalAlign: 'middle'}} height="24px" width="24px" src={icon__trash}/>
		</div>;
	}

	setDisplayHoverClass(value: boolean) {
		const {isOn} = this.props;
		if (!isOn) {
			this.setState({displayHoverClass: value});
			return;
		}

		this.setState({displayHoverClass: false});
	}

	handleDomainSelected = () => {
		const {domain, onDomainSelected} = this.props;
		onDomainSelected(domain._id);
		this.setDisplayHoverClass(false);
	};

	handleInputOnBlur = () => {
		const {onUpdateDomain} = this.props;
		this.setState({editMode: false});
		onUpdateDomain();
	};

	handleDomainNameChange = () => {
		const {domain} = this.props;
		const domainName = this.inputRef?.current?.value;
		domain.namespace = domainName || '';
		this.setState({inputDomainNameChange: true});
	};

	handleClickOnDomainName = (event: React.MouseEvent) => {
		const {isOn} = this.props;
		if (!isOn)
			return;

		event.stopPropagation();
		this.setState({editMode: true});
	};

	renderDomainHeader() {
		const {isOn, domain} = this.props;
		const {editMode} = this.state;

		if (editMode) {
			return <div className={levelsCss.inputContainer}>
				<input autoFocus value={domain.namespace} onChange={this.handleDomainNameChange} onBlur={() => {
					this.handleInputOnBlur()
				}} ref={this.inputRef} className={levelsCss.domainInputStyle} placeholder={"Type domain name"}/>
			</div>;
		}

		return <div onClick={this.handleDomainSelected}
		            onMouseEnter={() => this.setDisplayHoverClass(true)}
		            onMouseLeave={() => this.setDisplayHoverClass(false)}
		            style={{position: "relative"}}
		            className={`${domainsCss.domainRow} ll_h_c`}>
			<div onClick={this.handleClickOnDomainName}
			     className={isOn ? domainsCss.domainOnNameStyle : domainsCss.domainNameStyle}>{domain.namespace || 'Input name'}</div>
			{this.renderRowActions()}
		</div>;
	}

	renderPermissionLevels() {
		const {isOn, domain} = this.props;
		if (!isOn)
			return null;

		return <>
			<div style={{marginTop: '15px', color: '#3d474f'}}>Access levels:</div>
			<PermissionLevels filterByDomainId={domain._id}/>
		</>;
	}

	render() {
		const {isOn} = this.props;
		const {displayHoverClass} = this.state;

		const className = isOn ? domainsCss.domainOnItemStyle : (displayHoverClass ? domainsCss.domainHoverItemStyle : domainsCss.domainItemStyle);
		return <div className={className}>
			{this.renderDomainHeader()}
			{this.renderPermissionLevels()}
		</div>;
	}
}
