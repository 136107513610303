import * as React from "react";
import { StringMap } from "@intuitionrobotics/ts-common";
import { CustomFieldRow, newCustomFieldRow } from "./CustomFieldRow";
import * as cfCss from "./css/customFieldCss";

const icon__add = require('@res/images/icon_add_blue.svg');

type State = {
	addRowOpen: boolean
}

type Props = {
	customField: StringMap,
	onCustomFieldUpdate: () => void,
	disableEdit?: boolean
}

export class CustomField
	extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			addRowOpen: false
		}
	}

	renderAddRow() {
		const {disableEdit} = this.props;
		const {addRowOpen} = this.state;

		if (disableEdit)
			return null;

		if (addRowOpen) {
			return <CustomFieldRow onRowDelete={() =>{}} onRowUpdate={(customKey, newRow: newCustomFieldRow) => {this.handleCustomFieldUpdate(customKey, newRow)}} customKey={''}
			                       onRowClose={() => {this.setState({addRowOpen: false})}} customFieldRow={{'':''}} emptyRowMode={true} />;
		}
		return <div style={{cursor: 'pointer'}}>
			<img onClick={() => {this.setState({addRowOpen: true})}} src={icon__add}/>
		</div>;
	}

	handleCustomFieldUpdate(customKey: string, newRow: newCustomFieldRow) {
		const {customField, onCustomFieldUpdate} = this.props;
		if (!newRow.newKey || !newRow.newTextValue)
			return;

		if (newRow.newKey === customKey && newRow.newTextValue === customField[customKey])
			return;

		delete customField[customKey];
		delete customField[newRow.newKey];

		customField[newRow.newKey] = newRow.newTextValue;
		onCustomFieldUpdate();
	}

	handleCustomRowDelete(customKey: string) {
		const {customField, onCustomFieldUpdate} = this.props;
		delete customField[customKey];
		onCustomFieldUpdate();
	}

	render() {
		const {customField, disableEdit} = this.props;
		const customFieldTable = Object.keys(customField).map((key: string) => {
			return <CustomFieldRow disableEdit={disableEdit} onRowDelete={(customKey) => {this.handleCustomRowDelete(customKey)}} key={`${key}--${customField[key]}`} onRowUpdate={(customKey, newRow: newCustomFieldRow) => {this.handleCustomFieldUpdate(customKey, newRow)}} customKey={key} customFieldRow={{[key]: customField[key]}}/>;
		});

		return <div className={cfCss.customFieldContainer}>
			{this.renderAddRow()}
			{customFieldTable}
		</div>;
	}
}
