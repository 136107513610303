import * as React from "react";
import * as emotion from "emotion";
import {CustomDropdown} from "../../components/CustomDropdown";
import {
	BaseComponent,
	ToastModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {PartialProperties} from "@intuitionrobotics/ts-common";
import {
	ApiCaller_UnitEnvItems,
	OnEnvConfItemsLoaded
} from "@modules/ApiCaller_UnitEnvItems";
import {UnitEnvConfigItem} from "@app/ir-q-app-common/types/unit-env-config";
import {Loader} from "../../ui/Loader";

const textAreaStyle = emotion.css`
	height: 500px;
	width: 90%;
	font-family: monospace !important;
`;

const addBtnStyle = emotion.css`
	margin-top: 20px;
	width: 100px
`;

type Props = {};
type State = {
	ddlChosenConfItemId: string,
	showLoader: boolean
};

export class AddUnitEnvItem
	extends BaseComponent<Props, State>
	implements OnEnvConfItemsLoaded {
	private inputRef: React.RefObject<HTMLTextAreaElement> = React.createRef();
	private ddlDefaultOption = {value: '', label: "Example - new env configuration"};

	constructor(props: Props) {
		super(props);
		this.state = {
			ddlChosenConfItemId: '',
			showLoader: false
		}
	}

	componentDidMount() {
		ApiCaller_UnitEnvItems.query();
	}

	__onEnvConfItemsLoaded = () => {
		this.setState({showLoader: false});
	}

	addEnv = () => {
		const inputRef = this.inputRef.current;
		if (!inputRef)
			return;

		const confItemStringObt = inputRef.value;
		if (!confItemStringObt)
			return;

		const confItemJson = JSON.parse(confItemStringObt);
		this.setState({showLoader: true});
		ApiCaller_UnitEnvItems.create(confItemJson)
		                      .setOnError("error create unit env config item")
		                      .setOnSuccessMessage("conf item created successfully");
	}

	deleteEnv = () => {
		const inputRef = this.inputRef.current;
		if (!inputRef)
			return;

		const confItemStringObt = inputRef.value;
		if (!confItemStringObt)
			return;

		const confItemJson = JSON.parse(confItemStringObt);
		if (!confItemJson._id) {
			ToastModule.toastError(`No document '_id' to delete, make sure you delete obj with '_id' property`);
			return;
		}

		this.setState({showLoader: true});
		ApiCaller_UnitEnvItems.delete(confItemJson._id)
		                      .setOnError("error delete unit env config item")
		                      .setOnSuccessMessage("conf item deleted successfully");
	}

	getDdlOptions = () => {
		const arrayConfItems = ApiCaller_UnitEnvItems.getConfItems();
		return [...arrayConfItems.map(confItem => {
			return {value: confItem._id, label: confItem.label};
		}), this.ddlDefaultOption];
	}

	renderLoader() {
		const {showLoader} = this.state;
		if (!showLoader)
			return;

		return <Loader/>;
	}

	render() {
		const {ddlChosenConfItemId} = this.state;
		const ddlChosenConfItem = ApiCaller_UnitEnvItems.getConfItems().find(p => p._id === ddlChosenConfItemId);

		const ddlValue = ddlChosenConfItem ? {value: ddlChosenConfItem._id, label: ddlChosenConfItem.label} : this.ddlDefaultOption;
		const exampleUnitEnvConfItem: PartialProperties<UnitEnvConfigItem, "_id"> = {
			"label": "Firebase project",
			"clientKey": "dbName",
			"key": "dbName",
			"defaultKey": "prod",
			"values": [
				{
					"key": "dev",
					"label": "Dev",
					"value": "elliq-env-dev"
				},
				{
					"key": "prod",
					"label": "Prod",
					"value": "elliq-env-prod"
				}]
		};

		const confItemObjText = ddlChosenConfItem ? JSON.stringify(ddlChosenConfItem) : JSON.stringify(exampleUnitEnvConfItem);

		if (this.inputRef.current)
			this.inputRef.current.value = JSON.stringify(JSON.parse(confItemObjText), null, 2);

		return <div style={{marginTop: "50px"}} className={'ll_h_c'}>
			<div className={'ll_v_c'} style={{flex: 0.2, alignSelf: "flex-start", marginTop: "100px"}}>
				<div style={{width: "90%"}}>
					<h4>Example by select:</h4>
					<div style={{marginBottom: "10px"}}>
						<CustomDropdown placeholder="Select configuration item..." options={this.getDdlOptions()} onChange={(option) => {
							this.setState({ddlChosenConfItemId: option.value});
						}} value={ddlValue}/>
					</div>
				</div>
			</div>
			<div className={'ll_v_c'} style={{flex: 0.6}}>
				<h2>Add Unit Environment Config Item</h2>
				<textarea className={textAreaStyle} ref={this.inputRef}>
					</textarea>
				<div>
					<button className={addBtnStyle} style={{marginRight: "20px"}} onClick={this.addEnv}>Add</button>
					<button className={addBtnStyle} onClick={this.deleteEnv}>Delete</button>
				</div>
			</div>
			{this.renderLoader()}
		</div>
	}
}
