import * as emotion from "emotion";

export const cancelTextStyle = emotion.css`
	color: #6c777f;
	font-size: 14px;
	margin-left: 15px;
	cursor: pointer;
`;

export const saveTextStyle = emotion.css`
	color: #157dd2;
	font-size: 14px;
	margin-left: 10px;
	cursor: pointer;
	text-decoration: underline;
`;

export const filterInputClass = emotion.css`
	::-webkit-input-placeholder {
		color: #babcbe
	};
	background-color: inherit;
	font-style: italic;
	font-size: 12px;
	outline: none;
	border-bottom: solid 1px rgba(0, 0, 0, 0.23);
	width: 100%;
`;
