import {
	ThunderDispatcher,
	XhrHttpModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {Api_MigrateData} from "@app/ir-q-app-common/types/api";

export interface OnMigrationComplete {
	__onMigrationComplete: () => void;
}

const dispatch_onMigrationComplete = new ThunderDispatcher<OnMigrationComplete, "__onMigrationComplete">("__onMigrationComplete");

export class ApiCaller_MigrateData_Class {

	runMigration(runMigration: boolean) {
		XhrHttpModule
			.createRequest<Api_MigrateData>(HttpMethod.POST, 'migrate-data')
			.setJsonBody({runMigration})
			.setRelativeUrl("/v1/migration")
			.execute(() => {
				dispatch_onMigrationComplete.dispatchUI()
			});
	}
}

export const ApiCaller_MigrateData = new ApiCaller_MigrateData_Class();
