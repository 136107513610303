import * as React from 'react';
import * as emotion from "emotion";
import {KeyboardEvent} from 'react';

const error = require('@res/images/icn-warning.svg');

const labelStyle = emotion.css`
  color: #00b5ff;
	font-size: 16px;
  letter-spacing: -0.21px;
  padding-bottom: 4px;
`;

const inputStyle = emotion.css`
	&:-internal-autofill-selected {
	    background-color: #2e304f !important;
	}
	background-color: inherit;
  padding-left: 10px;
  width: 87%;
  color: #8392a6;
  outline: none;
  ::placeholder {
    color: #8392a6;
    opacity: 0.5;
    font-size: 13px;
    font-style: italic;
    letter-spacing: -0.16px;
  }
`;

const inputContainerStyle = emotion.css`
	border-bottom: 1px solid #cbd3dd;
	margin-bottom: 30px;
	width: 100%;
`;

type Props<Key> = {
	onChange: (value: string, id: Key) => void
	onAccept?: () => void

	disabled: boolean
	value?: string
	error?: string
	label: string
	type: 'text' | 'number' | 'password'
	placeholder?: string
	icon?: string
	id: Key
}

type State = {}

export class StyledInput<Key extends string>
	extends React.Component<Props<Key>, State> {
	static defaultProps: Partial<Props<"">> = {
		disabled: false,
	};

	constructor(props: Props<Key>) {
		super(props);

		this.state = {};
	}

	changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onChange(event.target.value, event.target.id as Key)
	};

	handleKeyPress = (event: KeyboardEvent) => {
		if (!this.props.onAccept)
			return;

		if (event.keyCode === 13)
			this.props.onAccept();

	};

	render() {
		const {label, id, type, value, placeholder} = this.props;
		return (
			<div key={id} className="ll_v_l">
				<div className={labelStyle}>{`${label}`}</div>
				<div className={`ll_h_c`} style={{width: 220}}>
					<div className={inputContainerStyle} style={this.props.error ? {borderBottom: "1px solid #e6004a"} : {}}>
						{this.props.icon && <img src={this.props.icon}/>}
						<input
							key={id}
							id={id}
							className={inputStyle}
							type={type}
							value={value || ''}
							placeholder={placeholder || label}
							onChange={this.changeValue}
							onKeyPress={this.handleKeyPress}
							autoComplete={'off'}
							// onFocus={}
							//// onBlur={}
						/>
					</div>
					{this.props.error && <img src={error} style={{marginBottom: 25, width: "13%"}}/>}
				</div>
			</div>
		);
	}

}
