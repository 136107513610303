import * as React from "react";
import * as levelValueCss from "./css/permissionsLevelValueCss";

type State = {
	bulletLeftPosition: number,
	selectedLevelNumber: string
}

type Props = {
	levelNumber: number,
	updateValue: (src: number) => void,
	createLevel: () => void,
	readOnly: boolean
}


export class PermissionsLevelValue
	extends React.Component<Props, State> {
	private readonly BULLET_WIDTH: number;
	private readonly LINE_BAR_WIDTH: number;
	// private readonly UPPER_BULLET_POSITION: number;
	// private readonly LOWER_BULLET_POSITION: number;
	private readonly MAX_LEVEL_VALUE: number;
	private readonly MIN_LEVEL_VALUE: number;

	constructor(props: Props) {
		super(props);
		this.MAX_LEVEL_VALUE = 1000;
		this.MIN_LEVEL_VALUE = 0;
		this.BULLET_WIDTH = 18;
		this.LINE_BAR_WIDTH = 200;
		// this.LOWER_BULLET_POSITION = - this.BULLET_WIDTH / 2;
		// this.UPPER_BULLET_POSITION = this.LINE_BAR_WIDTH - this.BULLET_WIDTH / 2;

		this.state = {
			bulletLeftPosition:  this.getBulletLeftPositionByLevel(props.levelNumber),
			selectedLevelNumber: props.levelNumber.toString()
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		const newLevelNumber = this.props.levelNumber;
		if (prevProps.levelNumber !== newLevelNumber) {
			this.setInputNumber(newLevelNumber, false);
			this.setBarBulletPosition(this.getBulletLeftPositionByLevel(newLevelNumber));
		}
	}

	private getBulletLeftPositionByLevel = (levelValue: number) => {
		const centerBulletOffset = this.BULLET_WIDTH / 2;
		return (this.LINE_BAR_WIDTH / this.MAX_LEVEL_VALUE) * levelValue - (centerBulletOffset);
	};

	// private getLevelByBulletLeftPosition = (bulletLeftPosition: number) => {
	// 	const centerBulletOffset = this.BULLET_WIDTH / 2;
	// 	if (bulletLeftPosition <= 0) {
	// 		return Math.floor(bulletLeftPosition + centerBulletOffset);
	// 	}
	//
	// 	return (Math.round((this.MAX_LEVEL_VALUE / this.LINE_BAR_WIDTH) * (bulletLeftPosition + centerBulletOffset)));
	// }

	private setBarBulletPosition = (leftPosition?: number) => {
		this.setState({bulletLeftPosition: leftPosition || 0});
	};

	private setInputNumber = (levelNumber: number, isNewValue: boolean) => {
		// const {updateValue} = this.props;
		// if (isNewValue)
		// 	updateValue(levelNumber);
		this.setState({selectedLevelNumber: levelNumber.toString()});
	};

	private saveValueInServer = () => {
		const {selectedLevelNumber} = this.state;
		const {updateValue} = this.props;
		updateValue(Number(selectedLevelNumber));
	};

	// private handleLineBarClick = (event: React.MouseEvent) => {
	// 	const rect = event.currentTarget.getBoundingClientRect();
	// 	let left = event.clientX - rect.left - this.BULLET_WIDTH / 2 + window.scrollX; //x position within the element.
	//
	// 	if (left < this.LOWER_BULLET_POSITION) {
	// 		left = this.LOWER_BULLET_POSITION;
	// 	}
	//
	// 	else if (left > this.UPPER_BULLET_POSITION) {
	// 		left = this.UPPER_BULLET_POSITION;
	// 	}
	//
	// 	this.setBarBulletPosition(left);
	// 	this.setInputNumber(this.getLevelByBulletLeftPosition(left), true);
	// }

	private handleInputNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = parseInt(event.target.value) || 0;
		let newValue;
		if (inputValue < this.MIN_LEVEL_VALUE) {
			newValue = this.MIN_LEVEL_VALUE;
		} else if (inputValue > this.MAX_LEVEL_VALUE) {
			newValue = this.MAX_LEVEL_VALUE;
		} else {
			newValue = inputValue;
		}

		this.setInputNumber(newValue, true);
		this.setBarBulletPosition(this.getBulletLeftPositionByLevel(newValue));
	};

	createLevel = () => {
		const { createLevel } = this.props;
		this.saveValueInServer();
		createLevel();
	}

	render() {
		const { selectedLevelNumber } = this.state;
		const { readOnly } = this.props;
		return <div className={levelValueCss.levelContainerStyle}>
			{/*<div className={levelValueCss.lowerBoundaryValueStyle}>0</div>*/}
			{/*<div className={levelValueCss.lineBarContainerStyle} onClick={readOnly ? () => {} : this.handleLineBarClick}>*/}
			{/*	<div className={levelValueCss.levelLineBarStyle}>*/}
			{/*		<div style={{left: bulletLeftPosition}} className={levelValueCss.levelBulletStyle}></div>*/}
			{/*	</div>*/}
			{/*</div>*/}
			{/*<div className={levelValueCss.upperBoundaryValueStyle}>1000</div>*/}
			<div>
				<input
					onKeyUp={(e) => { (e.keyCode === 13) ? this.createLevel() : null}}
					onBlur={this.saveValueInServer}
					disabled={readOnly}
					className={levelValueCss.levelValueInputStyle}
					value={selectedLevelNumber}
					min={this.MIN_LEVEL_VALUE}
					max={this.MAX_LEVEL_VALUE}
					onChange={this.handleInputNumberChange}
					type="number"/>
			</div>
		</div>
	}
}
