import * as React from "react";
import {CSSProperties} from "react";
import * as emotion from "emotion";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {StyledInput} from "./StyledInput";
import {
	_keys,
	StringMap
} from "@intuitionrobotics/ts-common";
import {Request_CreateAccount} from "@intuitionrobotics/user-account/shared/api";
import {
	AccountModule,
	RequestKey_AccountCreate
} from "@intuitionrobotics/user-account/frontend";
import { OnRequestListener } from "@intuitionrobotics/thunderstorm";

const icons = {
	avatar: require('@res/images/icn-avatar.svg'),
	lock: require('@res/images/icn-lock.svg'),
}

type State<T> = {
	data: Partial<T>,
	errors?: { [index: string]: string },
	errMessages?: string[]
}
type Props<T> = {
	validate?: (data: Partial<T>) => { [field: string]: string }
}
const style: CSSProperties = {
	"height": "35px",
	width: "220px",
	"backgroundColor": "#00b5ff",
	"borderWidth": "0",
	borderRadius: 4,
	fontSize: 13,
	letterSpacing: "-0.21px",
	"textAlign": "center",
	"padding": "0 15px",
	"color": "#fbfbfb",
	"marginTop": "10px",
	marginBottom: 45,
	outline: "none"
};

const errMessageStyle = emotion.css`
	width: 220px;
  border-radius: 4px;
  background-color: #e4afc0;
  font-size: 10px;
  font-style: italic;
  letter-spacing: -0.16px;
  color: #2f304f;
  margin-bottom: 23px;
  padding: 6px 17px;
`;

type InputField = {
	label: string
	hint: string
	type: 'text' | 'number' | 'password'
}

type Form<T> = { [K in keyof T]: InputField & { icon?: string } }

const form: Form<Request_CreateAccount> = {
	email: {
		type: "text",
		hint: "email",
		label: "Username",
		icon: icons.avatar
	},
	password: {
		type: "password",
		hint: "type your password",
		label: "Password",
		icon: icons.lock
	},
	password_check: {
		type: "password",
		hint: "type your password again",
		label: "Confirm  password",
		icon: icons.lock
	},
};

export class Component_Register
	extends BaseComponent<Props<Request_CreateAccount>, State<Request_CreateAccount>>
	implements OnRequestListener {

	state = {
		data: {} as Partial<Request_CreateAccount>,
		errors: {} as StringMap,
		errMessages: []
	};

	__onRequestCompleted = (key: string, success: boolean) => {
		if (success || key !== RequestKey_AccountCreate)
			return

		this.setState(() => ({errMessages: ['Failed to register user!']}))
	}

	render() {
		const data = this.state.data;
		return <>
			{this.state.errMessages.length > 0 &&
			<div className={errMessageStyle}>Oops, wrong input:{this.state.errMessages.map(error => <div key={error}>{error}</div>)}</div>}
			<div>
				{_keys(form).map(key => {
					                 const field = form[key];
					                 return <div key={key}><StyledInput
						                 id={key}
						                 value={data[key]}
						                 label={field.label}
						                 icon={field.icon}
						                 type={field.type}
						                 placeholder={field ? field.hint : ""}
						                 onChange={this.onValueChanged}
						                 onAccept={this.registerClicked}
						                 error={this.state.errors[key]}
					                 /></div>
				                 }
				)}
			</div>
			<div className={'ll_h_c'} style={{justifyContent: 'center'}}>
				<button onClick={this.registerClicked} className={`clickable`} style={style}>Register
				</button>
			</div>
		</>;
	}

	private onValueChanged = (value: string, id: keyof Request_CreateAccount) => {
		this.setState(state => {
			state.errors && delete state.errors[id];
			state.data[id] = value;
			return state
		});
	};

	private registerClicked = () => {
		const data: Partial<Request_CreateAccount> = this.state.data;
		const errors = this.props.validate && this.props.validate(data);
		const errMessages = errors ? _keys(errors).map(err => errors[err]) : [];

		this.setState(() => ({errors, errMessages}));

		if (errors && _keys(errors).length > 0)
			return;
		// return ToastModule.toastError(`Oops, wrong input:\n${errors.join("\n")}`);

		AccountModule.create(data as Request_CreateAccount)
	};
}
