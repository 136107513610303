import * as emotion from "emotion";

export const regularBtnStyle = emotion.css`
	margin-top: 20px;
	width: 100px
`;

export const clickedRowStyle = emotion.css`
	font-family: Lato;
	font-size: 14px;
	padding: 10px 35px 10px 25px;
	background-color: #c0d6ec;
`;