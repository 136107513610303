import * as emotion from "emotion";

export const addDomainContainer = emotion.css`
	padding: 10px 35px 10px 25px;
`;

export const addDomainStyle = emotion.css`
	cursor: pointer;
`;

export const closeModeArea = emotion.css`
	display: flex;
	cursor: pointer;
`;

export const openModeArea = emotion.css`
	display: flex;
	align-items: flex-end;
`;

export const addIconStyle = emotion.css`
	vertical-align: bottom;
	margin-left: 5px;
`;

export const inputContainer = emotion.css`
	width: 10%;
	transition: width 1s;
`;

export const domainInputStyle = emotion.css`
	background-color: inherit;
	font-style: italic;
	font-size: 14px;
	outline: none;
	border-bottom: solid 1px rgba(0, 0, 0, 0.23);
	width: 100%;
`;

export const addDomainText = emotion.css`
	text-decoration: underline;
	color: #157dd2;
	font-size: 14px;
	margin-left: 5px;
`;
