

import {Module} from "@intuitionrobotics/ts-common";
import {StorageKey_JWT, StorageKey_UserEmail} from "@intuitionrobotics/user-account/frontend";
import {BaseComponent, StorageKey} from "@intuitionrobotics/thunderstorm/frontend";
import {
    QueryParam_Email,
    QueryParam_JWT,
    QueryParam_RedirectUrl,
    QueryParam_SessionId
} from "@intuitionrobotics/user-account/shared/api";
import {EnvironmentModule} from "@modules/EnvironmentModule";

export const StorageKey_Redirect: StorageKey<string> = new StorageKey<string>(`storage-redirectUrl`, false);

export class LoginModule_Class
    extends Module {

    constructor() {
        super("LoginModule");
        // Need for now! I'll change this I swear!
        if (window.location.pathname === '/logout') {
            StorageKey_JWT.delete();
            StorageKey_UserEmail.delete();
        }
    }

    protected init(): void {
        // document.referrer is useful but beware of circular redirection
        const redirectUrl = BaseComponent.getQueryParameter(QueryParam_RedirectUrl);
        if (!redirectUrl)
            return;

        StorageKey_Redirect.set(redirectUrl);
    }

    getSamlRedirectUrl = () => {
        const queryRedirectUrl = BaseComponent.getQueryParameter(QueryParam_RedirectUrl)
        if (queryRedirectUrl)
            return queryRedirectUrl;

        const authParam = `${QueryParam_JWT}=${QueryParam_JWT.toUpperCase()}`;
        const userEmailParam = `${QueryParam_Email}=${QueryParam_Email.toUpperCase()}`;
        return `${EnvironmentModule.getOrigin()}?${authParam}&${userEmailParam}`;
    };

    goToApp() {
        return this.buildUrlAndGo(this.getRedirectUrl());
    }

    private getRedirectUrl = () => {
        let redirectUrl = BaseComponent.getQueryParameter(QueryParam_RedirectUrl);
        if (!redirectUrl)
            redirectUrl = StorageKey_Redirect.get();
        return redirectUrl;
    };

    private buildUrlAndGo = (_redirectUrl: string) => {
        if (!_redirectUrl)
            return;
        let redirectUrl = _redirectUrl;
        const sessionId = this.getSessionId();
        if (redirectUrl.includes(QueryParam_SessionId) && !sessionId)
            return;

        redirectUrl = redirectUrl.replace(new RegExp(QueryParam_SessionId.toUpperCase(), "g"), sessionId || "");
        redirectUrl = redirectUrl.replace(new RegExp(QueryParam_JWT.toUpperCase(), "g"), StorageKey_JWT.get());
        const url = redirectUrl.replace(new RegExp(QueryParam_Email.toUpperCase(), "g"), StorageKey_UserEmail.get());
        return this.goToUrl(url);
    };

    private getSessionId(): string | undefined {
        const jwt = StorageKey_JWT.get();
        const encoded = jwt.split('.')[1];
        if (!encoded)
            return;

        const stringPayload = atob(encoded);
        if (!stringPayload)
            return;

        try {
            const payload = JSON.parse(stringPayload)
            if (!payload || typeof payload !== "object")
                return;

            return payload['sessionId'];
        } catch (e) {
            this.logWarning('Failed to parse token, ', e)
        }
    }

    goToService(service: string) {
        // not changing this
        const jwtParam = `${QueryParam_JWT}=${StorageKey_JWT.get()}`;
        const userEmailParam = `${QueryParam_Email}=${StorageKey_UserEmail.get()}`;
        const url = `${service}?${jwtParam}&${userEmailParam}`;
        this.goToUrl(url);
    }

    private goToUrl = (url: string) => {
        StorageKey_Redirect.delete();
        window.location.href = url;
        return url;
    };
}

export const LoginModule = new LoginModule_Class();
