import * as React from "react";
import * as addDomainCss from "../permissions-domains/css/addDomainCss";
import * as permissionsCss from "../css/permissionsCss";
import {generateHex} from "@intuitionrobotics/ts-common";
import { ApiCaller_PermissionsGroup } from "@intuitionrobotics/permissions/frontend";

const icon__add = require('@res/images/icon_add_blue.svg');

type State = {
	openMode: boolean
}

type Props = {}

export class AddGroup
	extends React.Component<Props, State> {
	private inputRef: React.RefObject<HTMLInputElement> = React.createRef();
	private addGroupContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
	private domainInputContainerRef: React.RefObject<HTMLDivElement> = React.createRef();

	constructor(props: Props) {
		super(props);
		this.state = {
			openMode: false
		}

		this.handleCancelClick = this.handleCancelClick.bind(this);
	}

	handleCancelClick = () => {
		this.setOpenMode(false);
	}

	setOpenMode = (value: boolean) => {
		this.setState({openMode: value}, () => {
			const domainInputContainerRef = this.domainInputContainerRef?.current;
			setTimeout(() => {
				if (domainInputContainerRef) {
					domainInputContainerRef.style.width = "100%";
				}
			});

			const time = value ? 1000 : 0;
			setTimeout(() => {
				const addGroupContainerRef = this.addGroupContainerRef?.current;
				const inputRef = this.inputRef?.current;
				if (addGroupContainerRef) {
					addGroupContainerRef.style.backgroundColor = value ? "#d5d8da73" : "inherit";
				}

				if (inputRef) {
					inputRef.focus();
				}
			}, time);
		});
	}

	renderOpenArea() {
		return (<div className={addDomainCss.openModeArea}>
			<div ref={this.domainInputContainerRef} className={addDomainCss.inputContainer}>
				<input onKeyUp={(e) => { (e.keyCode === 13) ? this.addGroup() : null}} ref={this.inputRef}
				       className={addDomainCss.domainInputStyle} placeholder={"Type group name"}/>
			</div>
			<div className={addDomainCss.addDomainStyle}>
				<img onClick={() => {this.addGroup()}} className={addDomainCss.addIconStyle} src={icon__add}/>
			</div>
			<div onClick={this.handleCancelClick} className={permissionsCss.cancelTextStyle}>cancel</div>
		</div>);
	}

	addGroup = () => {
		const inputRef = this.inputRef.current;
		if (!inputRef)
			return;

		const newGroupName = inputRef.value;
		if (!newGroupName)
			return;

		ApiCaller_PermissionsGroup.create({_id: generateHex(32), label: newGroupName});
		inputRef.value = '';
		this.setOpenMode(false);
	}

	renderCloseArea() {
		return (<div onClick={() => {this.setOpenMode(true)}} className={addDomainCss.closeModeArea}>
			<div>
				<img src={icon__add}/>
			</div>
			<div className={addDomainCss.addDomainText}>
				New group
			</div>
		</div>);
	}

	renderAddGroupArea() {
		const {openMode} = this.state;
		if (!openMode)
			return this.renderCloseArea();

		return this.renderOpenArea();
	}

	render() {
		const {openMode} = this.state;
		return <div ref={this.addGroupContainerRef} className={addDomainCss.addDomainContainer}>
			{!openMode && this.renderCloseArea()}
			{!!openMode && this.renderOpenArea()}
		</div>;
	}
}
