import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {
	CustomDropdown,
	CustomSelectOption
} from "../components/CustomDropdown";
import {
	ApiCaller_MigrateFMContacts,
	OnMigrationComplete
} from "@modules/ApiCaller_MigrateFMContacts";
import {Loader} from "../ui/Loader";

type State = {
	runMigration: CustomSelectOption
	loading: boolean
}
export class Page_MigrateFMContacts
	extends BaseComponent<{}, State>
  implements OnMigrationComplete {

	constructor(props: {}) {
		super(props);

		this.state = {
			runMigration: {value: false, label: "false"},
			loading: false
		}
	}

	__onMigrationComplete(): void {
		this.setState({loading: false})
	}

	componentDidMount() {
		document.title = "Migrate family member contacts to BigQuery"
	}

	render() {
		return <>
			<h1>Clicking on the below button will create entries in BigQuery for each familyMember contact</h1>
			Run migration: <CustomDropdown options={[{value: true, label: "true"}, {value: false, label: "false"}]} value={this.state.runMigration}
			                onChange={elem => this.setState({runMigration: elem})}/>
			<button style={{marginTop: 10}} onClick={() => {
				this.setState({loading: true})
				ApiCaller_MigrateFMContacts.runMigration(this.state.runMigration.value)
			}}>Run</button>
			{this.state.loading && <Loader/>}
		</>;
	}

}
