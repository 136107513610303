import * as React from "react";
import * as domainsCss from "../../permissions-domains/css/domainsCss";
import {DB_PermissionAccessLevel} from "@intuitionrobotics/permissions";
import {
	ApiCaller_PermissionsGroup,
	ApiCaller_PermissionsUser
} from "@intuitionrobotics/permissions/frontend";
import {UI_User} from "../../permissions-users/AccountsList";
import {getAllUsers} from "../../permissions-utils";

type State = {
	displayHoverClass: boolean,
	editMode: boolean,
	inputDomainNameChange: boolean
}

type Props = {
	isOn: boolean,
	level: DB_PermissionAccessLevel,
	domainId: string,
	onLevelSelected: (src: string) => void
}

export class LevelToUsersItem
	extends React.Component<Props, State> {
	private allUsers!: UI_User[];

	constructor(props: Props) {
		super(props);
		this.state = {
			displayHoverClass: false,
			editMode: false,
			inputDomainNameChange: false,
		}

		this.handleClickOnDomainName = this.handleClickOnDomainName.bind(this);
		this.allUsers = getAllUsers();
	}

	setDisplayHoverClass(value: boolean) {
		const {isOn} = this.props;
		if (!isOn) {
			this.setState({displayHoverClass: value});
			return;
		}

		this.setState({displayHoverClass: false});
	}

	handleDomainSelected = () => {
		const {level, onLevelSelected} = this.props;
		onLevelSelected(level._id);
		this.setDisplayHoverClass(false);
	};

	handleClickOnDomainName = (event: React.MouseEvent) => {
		const {isOn} = this.props;
		if (!isOn)
			return;

		event.stopPropagation();
		this.setState({editMode: true});
	};

	renderLevelHeader() {
		const {isOn, level} = this.props;

		return <div onClick={this.handleDomainSelected}
		            onMouseEnter={() => this.setDisplayHoverClass(true)}
		            onMouseLeave={() => this.setDisplayHoverClass(false)}
		            style={{position: "relative"}}
		            className={`${domainsCss.domainRow} ll_h_c`}>
			<div onClick={this.handleClickOnDomainName}
			     className={isOn ? domainsCss.domainOnNameStyle : domainsCss.domainNameStyle}>{level.name}</div>
		</div>;
	}

	getPermittedUsers = () => {
		const relevantGroups = ApiCaller_PermissionsGroup.getGroups().filter(group => {
			return group.__accessLevels?.find(acLevel => acLevel.domainId === this.props.domainId && acLevel.value >= this.props.level.value);
		});

		return this.allUsers.filter(user => {
			const permissionUser = ApiCaller_PermissionsUser.getUserByAccountId(user._id);
			return relevantGroups.find(group => permissionUser?.groups?.find(userGroup => userGroup.groupId === group._id));
		});
	}

	renderUsers() {
		const {isOn} = this.props;
		if (!isOn)
			return null;

		this.getPermittedUsers();
		return <div style={{marginTop: '15px', color: '#3d474f'}}>
			{this.getPermittedUsers().map(user => <div key={user._id}>{user.email}</div>)}
		</div>;
	}

	render() {
		const {isOn} = this.props;
		const {displayHoverClass} = this.state;

		const className = isOn ? domainsCss.domainOnItemStyle : (displayHoverClass ? domainsCss.domainHoverItemStyle : domainsCss.domainItemStyle);
		return <div style={{border: "none"}} className={className}>
			{this.renderLevelHeader()}
			{this.renderUsers()}
		</div>;
	}
}
