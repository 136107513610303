import * as React from "react";
import * as levelsCss from "./permissions-levels/css/permissionLevelsCss";

type State = {
	editMode: boolean,
	localInputText: string
}

type Props = {
	inputText: string,
	onSaveInput: (inputText: string) => void,
	beginWithEditMode: boolean,
	placeholder: string,
	disableEdit?: boolean
}

export class EditableInput
	extends React.Component<Props, State> {
	private inputRef: React.RefObject<HTMLInputElement> = React.createRef();

	constructor(props: Props) {
		super(props);
		this.state = {
			localInputText: '',
			editMode: false
		}

		this.handleClickOnText = this.handleClickOnText.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	componentDidMount() {
		const {inputText, beginWithEditMode} = this.props;
		this.setState({localInputText: inputText, editMode: beginWithEditMode});
	}

	handleInputChange() {
		const input = this.inputRef?.current?.value;
		if (input === null || input === undefined)
			return;

		this.setState({localInputText: input});
	}

	handleBlur() {
		const { onSaveInput } = this.props;
		const { localInputText } = this.state;
		onSaveInput(localInputText);
		this.setState({editMode: false});
	}

	handleClickOnText() {
		this.setState({editMode: true});
	}

	render() {
		const {placeholder, disableEdit} = this.props;
		const {editMode, localInputText} = this.state;

		if (editMode && !disableEdit) {
			return <div className={levelsCss.inputContainer}>
				<input autoFocus value={localInputText} onChange={this.handleInputChange} onBlur={() => {this.handleBlur()}}
				       onKeyUp={(e) => { (e.keyCode === 13) ? this.handleBlur() : null}}
				       ref={this.inputRef} className={levelsCss.domainInputStyle} placeholder={placeholder}/>
			</div>;
		}

		return <div onClick={this.handleClickOnText}>{localInputText || placeholder}</div>;
	}
}
