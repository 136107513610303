import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {UnitsActivation} from "./units-activation/UnitsActivation";
import {UnitsActivationTabs} from "./UnitsActivationTabs";
import {PermissionsComponent} from "@intuitionrobotics/permissions/frontend";

export class Page_PairedUnitsActivationByEnvs
	extends BaseComponent {

	constructor(props: {}) {
		super(props);
	}

	componentDidMount() {
		document.title = "Paired Activation List";
	}

	render() {
		return <>
			<PermissionsComponent
				url={"/v1/gsuite/unpair-delete"}
				fallback={() => <>"You have no permissions to view this page!"</>}
			>
				<UnitsActivation/>
			</PermissionsComponent>
			<UnitsActivationTabs/>
		</>;
	}
}
