import * as React from "react";
import {BaseComponent, ToastModule} from "@intuitionrobotics/thunderstorm/frontend";
import {
    BadImplementationException,
    ObjectTS,
    PartialProperties,
    sortArray,
    TypedMap
} from "@intuitionrobotics/ts-common";
import {UnitEnvConfigItem, UnitEnvs, ValueOption} from "@app/ir-q-app-common/types/unit-env-config";
import {ApiCaller_UnitEnvItems, OnEnvConfItemsLoaded} from "@modules/ApiCaller_UnitEnvItems";
import * as emotion from "emotion";
import {ApiCaller_UnitEnvs, OnUnitEnvsLoaded} from "@modules/ApiCaller_UnitEnvs";
import {CustomDropdown} from "../../components/CustomDropdown";

const textAreaStyle = emotion.css`
	width: 100%; 
	height: 100%;
	font-family: monospace !important;
`;

const configurationsTitleStyle = emotion.css`
  font-size: small;
	font-weight: 500;
`;

type Props = {};
type State = {
    displayUpdateData: boolean,
    selectedEnv: UnitEnvs
};

export class UnitEnvItems
    extends BaseComponent<Props, State>
    implements OnEnvConfItemsLoaded, OnUnitEnvsLoaded {

    private inputRef: React.RefObject<HTMLInputElement> = React.createRef();
    private textAreaRef: React.RefObject<HTMLTextAreaElement> = React.createRef();
    private textValuesAreaRef: React.RefObject<HTMLTextAreaElement> = React.createRef();

    private defaultConfigurations: TypedMap<string> = {};
    private defaultValuesConfigurations: TypedMap<string | ObjectTS> = {};
    private configurationsOptions: PartialProperties<UnitEnvConfigItem, "_id">[] = [];
    private defaultEnv: UnitEnvs = {_name: "New Env", _id: ''};

    __onEnvConfItemsLoaded = () => this.forceUpdate();
    __onUnitEnvsLoaded = () => {
        this.forceUpdate()
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            displayUpdateData: false,
            selectedEnv: this.defaultEnv
        }
    }

    componentDidMount(): void {
        ApiCaller_UnitEnvItems.query();
        ApiCaller_UnitEnvs.query();
    }

    fillDefaultConfigurations = () => {
        this.configurationsOptions = sortArray(ApiCaller_UnitEnvItems.getConfItems(), (env) => env.clientKey, true);
        if (Object.keys(this.defaultConfigurations).length)
            return;

        this.configurationsOptions.map(confItem => {
            const defaultValue = confItem.values.find(value => value.key === confItem.defaultKey);
            if (!defaultValue)
                throw new BadImplementationException(`No default value to '${confItem.label}' config item`);

            this.defaultConfigurations[confItem.key] = defaultValue.key;
            this.defaultValuesConfigurations[confItem.clientKey] = defaultValue.value;
        });
    }

    getOnlyConfItems(env: UnitEnvs) {
        const confItems: PartialProperties<UnitEnvs, "_id"> = {...env};
        delete confItems["_id"];
        delete confItems["_name"];
        return confItems;
    }

    fillConfigurationDataByExistEnv = (env: UnitEnvs) => {
        const confItems = this.getOnlyConfItems(env);
        this.defaultConfigurations = confItems;
        Object.keys(confItems).forEach(confKey => {
            if (confKey === "_name")
                return;

            const confOption = this.configurationsOptions.find(option => option.key === confKey);
            if (!confOption)
                throw new BadImplementationException(`No env item for '${confKey}' key, when trying to fill data by exist Env`);

            const confValue = confOption.values.find(value => value.key === confItems[confKey])
            if (!confValue)
                throw new BadImplementationException(`No value for key '${confKey}' and env name '${confItems[confKey]}'`);

            this.defaultValuesConfigurations[confOption.clientKey] = confValue.value;
        });

        this.defaultValuesConfigurations["_name"] = env._name;
    }

    handleEnvNameChange = () => {
        const envName = this.inputRef?.current?.value;
        if (!envName) {
            delete this.defaultConfigurations["_name"];
            delete this.defaultValuesConfigurations["_name"];
            this.setState({displayUpdateData: true});
            return;
        }

        this.defaultConfigurations["_name"] = envName;
        this.defaultValuesConfigurations["_name"] = envName;
        this.setState({displayUpdateData: true});
    }

    submitUnitEnv = () => {
        if (!this.defaultConfigurations["_name"]) {
            ToastModule.toastError("No env label input, pls fill env label");
            return;
        }

        const upsertEnv: PartialProperties<UnitEnvs, "_id"> = {...this.defaultConfigurations};
        if (this.state.selectedEnv && this.state.selectedEnv._id)
            upsertEnv["_id"] = this.state.selectedEnv._id;

        ApiCaller_UnitEnvs.create(upsertEnv)
            .setOnError("error create unit env")
            .setOnSuccessMessage("unit env created successfully");
        this.resetEnvToDefaults();
    }


    resetEnvToDefaults = () => {
        this.setEnvLabelInput('');
        this.defaultConfigurations = {};
        this.defaultValuesConfigurations = {};
        this.setState({selectedEnv: this.defaultEnv});
    }

    renderEnvsDdl = () => {
        const envs = [this.defaultEnv, ...ApiCaller_UnitEnvs.getUnitEnvs()];

        return <div style={{marginLeft: "50px", marginBottom: "20px"}} className={'ll_h_c'}>
            <label style={{width: "120px", fontWeight: "bold"}}>Select Unit Env:</label>
            <CustomDropdown
                width={200}
                value={{value: this.state.selectedEnv._id, label: this.state.selectedEnv._name}}
                options={envs.map(e => ({value: e._id, label: e._name}))}
                onChange={selected => {
                    if (selected.value) {
                        this.fillConfigurationDataByExistEnv({_name: selected.label, _id: selected.value});
                        this.setEnvName(selected.label);
                        this.setEnvLabelInput(selected.label);
                    } else {
                        this.resetEnvToDefaults();
                    }
                    this.setState({displayUpdateData: true, selectedEnv: {_name: selected.label, _id: selected.value}});
                }}/>
        </div>;

    }

    setEnvLabelInput = (envLabel: string) => {
        const envLabelInput = this.inputRef.current;
        if (envLabelInput)
            envLabelInput.value = envLabel;
    }

    setEnvName = (envName: string) => {
        this.defaultConfigurations["_name"] = envName;
        this.defaultValuesConfigurations["_name"] = envName;
    }

    getOrderObj = (obj: TypedMap<any>) => {
        const orderd: TypedMap<any> = {};
        Object.keys(obj).sort().forEach((key) => {
            orderd[key] = obj[key];
        });

        return orderd;
    }

    render() {
        this.fillDefaultConfigurations();

        const orderdDefaultConfigurations: TypedMap<string> = this.getOrderObj(this.defaultConfigurations);
        const orderdDefaultValuesConfigurations: TypedMap<string> = this.getOrderObj(this.defaultValuesConfigurations);

        if (this.textAreaRef.current)
            this.textAreaRef.current.value = JSON.stringify(orderdDefaultConfigurations, null, 2);

        if (this.textValuesAreaRef.current)
            this.textValuesAreaRef.current.value = JSON.stringify(orderdDefaultValuesConfigurations, null, 2);

        const rows = this.configurationsOptions.map((configItem) => {
            const selectedValue = configItem.values.find(value => value.key === (this.defaultConfigurations[configItem.key] || configItem.defaultKey));
            if (!selectedValue)
                throw new BadImplementationException(`No default value to ${configItem.label} config item`);

            return <div key={configItem.key} className={'ll_h_c'}>
                <label style={{marginRight: "20px", width: "250px"}}>{configItem.label}:</label>
                <CustomDropdown
                    key={`${selectedValue.key}-${selectedValue.value}-${this.defaultConfigurations["_name"]}`}
                    options={configItem.values}
                    value={selectedValue}
                    width={200}
                    onChange={selected => {
                        const selectedConfig = configItem.values.find(c => c.value === selected.value) as ValueOption;
                        this.defaultConfigurations[configItem.key] = selectedConfig.key;
                        this.defaultValuesConfigurations[configItem.clientKey] = selectedConfig.value;
                        this.setState({displayUpdateData: true});
                    }}/>
            </div>
        });

        return <div style={{marginTop: "50px"}} className={'ll_h_c'}>
            <div style={{flex: 1, marginLeft: "20px", alignSelf: "flex-start"}}>
                {this.renderEnvsDdl()}
                <div style={{marginLeft: "125px"}}>
                    <input style={{width: "200px", marginBottom: "20px"}} autoFocus onChange={this.handleEnvNameChange}
                           ref={this.inputRef} placeholder={"Env label..."}/>
                </div>
                {rows}
                <div>
                    <button style={{margin: "40px 125px", width: "200px"}} onClick={this.submitUnitEnv}>Submit</button>
                </div>
            </div>
            <div style={{flex: 1}} className={'ll_v_c'}>
                <div style={{flex: 1, width: "90%", height: "200px", marginBottom: "20px"}}>
                    <label className={configurationsTitleStyle}>Stored in db</label>
                    <textarea className={textAreaStyle} ref={this.textAreaRef}>
					</textarea>
                </div>
                <div style={{flex: 1, width: "90%", height: "200px", marginTop: "30px"}}>
                    <label className={configurationsTitleStyle}>For client</label>
                    <textarea className={textAreaStyle} ref={this.textValuesAreaRef}>
					</textarea>
                </div>
            </div>
        </div>
    }
}
