import * as React from "react";
import * as emotion from "emotion";
import {
	BaseComponent,
	Dialog_Builder,
	ToastModule,
	TooltipBuilder,
	TooltipModule,
	TS_Checkbox,
	TS_Input
} from "@intuitionrobotics/thunderstorm/frontend";
import {OnPairedUnitsByEnvsLoaded, UnitsModule} from "@modules/UnitsModule";
import {DeviceIdentity, FullUnitConfig, Unit} from "@app/ir-q-app-common/types/units";
import {
	_keys,
	addItemToArray,
	currentTimeMillies,
	Format_YYYYMMDD_HHmmss,
	formatTimestamp,
	generateHex,
	removeItemFromArray,
	sortArray
} from "@intuitionrobotics/ts-common";
import {ddlOption, dot, PeriodList} from "./Versions";
import {AreYouSureDialog} from "../permissions/AreYouSureDialog";
import {BatchActionsDialogContent} from "../permissions/BatchActionsDialog";
import {Loader} from "../../ui/Loader";
import {GSuiteAccount, GSuiteModule} from "@modules/GSuiteModule";
import {CustomDropdown} from "../../components/CustomDropdown";

const shortRowItem = emotion.css`
	margin-right: 10px;
	width: 60px;
	display: inline-block;
	white-space: nowrap;
`;

const longRowItem = emotion.css`
	margin-right: 10px;
	width: 180px;
	display: inline-block;
	white-space: nowrap;
`;

type Props = {};
type State = {
	filterPls: boolean
	ddlSelected: ddlOption
	batch: boolean
	batchUnits: Unit[]
	loading: boolean
	exclude: string
};

export class UnitsActivation
	extends BaseComponent<Props, State>
	implements OnPairedUnitsByEnvsLoaded {

	constructor(props: Props) {
		super(props);
		this.state = {
			filterPls: false,
			ddlSelected: PeriodList[0],
			batch: false,
			batchUnits: [],
			loading: true,
			exclude: ""
		};
	}

	componentDidMount(): void {
		// UnitsModule.fetchUnits();
		UnitsModule.fetchUnitsByEnvs();
		GSuiteModule.fetchGSuiteListWithNoAdmins();
	}

	__onPairedUnitsByEnvsLoaded = () => this.setState({loading: false, batchUnits: []});

	private getData = () => {
		const unitsByEnv = UnitsModule.getUnitsByEnvs();
		const unitsWithEnv: (FullUnitConfig & { env: string })[] = [];
		_keys(unitsByEnv).forEach(env => {
			const unitsByEnvElement = unitsByEnv[env];
			unitsByEnvElement.reduce((toRet, unit) => {
				const unitWithEnv = {
					...unit,
					env
				};
				const existUnit = toRet.find(item => item.unitId === unitWithEnv.unitId && item.product === unitWithEnv.product);
				if (!existUnit)
					return addItemToArray(toRet, unitWithEnv);

				const currentUnitLastTimestamp = Math.max(...unitWithEnv.identities.map(item => item._updated || 0));
				if (existUnit.identities.find(identity => (identity._updated || 0) > currentUnitLastTimestamp))
					return toRet;

				removeItemFromArray(toRet, existUnit);
				return addItemToArray(toRet, unitWithEnv);
			}, unitsWithEnv);

		});

		unitsWithEnv.sort((u1,u2) => {
			const hasSomeTimestamps1 = u1.identities.some(i => i._updated)
			const hasSomeTimestamps2 = u2.identities.some(i => i._updated)
			const hasAllTimestamps1 = u1.identities.every(i => i._updated)
			const hasAllTimestamps2 = u2.identities.every(i => i._updated)
			const m1 = Math.max(...u1.identities.map(item => item._updated || 0))
			const m2 = Math.max(...u2.identities.map(item => item._updated || 0))
			if(hasAllTimestamps1 && hasAllTimestamps2)
				return m1 > m2 ? -6 : 6;

			if(hasAllTimestamps1 && hasSomeTimestamps2)
				return -5;

			if(hasAllTimestamps2 && hasSomeTimestamps1)
				return 5;

			if(hasSomeTimestamps1 && hasSomeTimestamps2)
				return m1 > m2 ? -4 : 4;

			if(hasAllTimestamps1 && !hasAllTimestamps2)
				return -3;

			if(!hasAllTimestamps1 && hasAllTimestamps2)
				return 3;

			if(hasSomeTimestamps1 && !hasSomeTimestamps2)
				return -2;

			if(!hasSomeTimestamps1 && hasSomeTimestamps2)
				return 2;

			return u1.unitId > u2.unitId ? 1 : -1
		})
		return unitsWithEnv;
	};

	unpairUnitAndDeleteGoogleAccount = (units: Unit[]) => {
		const title = <div style={{fontSize: 18, fontWeight: "bold", color: "red", paddingLeft: 3}}>Attention! This
			action cannot be undone!</div>;

		if (units.length === 1) {
			const content = <div style={{padding: 12}}>
				<div>{`Unpair unit ${units[0].unitId} and delete its google account`}</div>
			</div>;
			return new AreYouSureDialog(content, () => {
				this.setState({loading: true});
				UnitsModule.unpairUnitsAndDeleteGoogleAccount(units);
			}, title).show();
		}

		new Dialog_Builder(<BatchActionsDialogContent
			action={{
				action: (u) => {
					this.setState({loading: true});
					UnitsModule.unpairUnitsAndDeleteGoogleAccount(u);
				}
			}}
			explain={`Unpair and delete google account`}
			units={units}/>)
			.setTitle(title)
			.setAllowIndirectClosing(true)
			.show();
	};

	render() {
		if (this.state.loading)
			return <Loader/>;

		const list = GSuiteModule.getGSuiteList();
		const unitsWithEnv: (FullUnitConfig & { env: string })[] = this.getData();
		const floatingGoogleAccounts = list.filter(a => !unitsWithEnv.some(u => this.unitToEmail(u.unitId) === a.primaryEmail));
		const unitsWithNoTimestamp: (FullUnitConfig & { env: string })[] = this.getUnitWithoutTimestamp(unitsWithEnv);
		const identical = this.state.batchUnits.length === unitsWithNoTimestamp.length &&
			this.state.batchUnits.filter(
				batch => !unitsWithNoTimestamp.find((unit: FullUnitConfig & { env: string }) => unit.unitId === batch.unitId && unit.product === batch.product)).length === 0;

		return <div style={{marginTop: "50px"}} className={`ll_v_c`}>
			<div style={{width: "59em", justifyContent: "space-between"}} className={`ll_h_c`}>
				<div className={`ll_h_c`} style={{width: 79}}>
					<button className="clickable"
					        onClick={() => this.setState(prev => ({batch: !prev.batch, batchUnits: []}))}>Batch
					</button>
					{this.state.batch && <div onClick={() => {
						if (!this.state.batchUnits.length)
							return ToastModule.toastInfo("You are in batch mode, please select unit(s)");
						this.unpairUnitAndDeleteGoogleAccount(this.state.batchUnits);
					}}
					                          className={emotion.css`
												cursor: pointer;
												background-color: red;
												width: 22px;
												height: 22px;
												-webkit-Mask: url(${(require("@res/images/icn-trash.svg"))}) no-repeat center;
												mask: url(${(require("@res/images/icn-trash.svg"))}) no-repeat center;
												display: inline-block;
											`}/>}
				</div>
				<div className={`ll_h_c`}>
					<div style={{marginRight: "20px"}}>{`${unitsWithEnv.length} pairs`}</div>
					<div style={{marginRight: "20px"}}>{`${GSuiteModule.getGSuiteList().length} google accounts`}</div>
					<div style={{marginRight: "20px"}}>{`${floatingGoogleAccounts.length} floating google accounts`}</div>
					<div style={{marginRight: "5px"}}>Active within:</div>
					<CustomDropdown options={PeriodList} value={this.state.ddlSelected}
									onChange={selected => {
										const ddlSelected = PeriodList.find(p => p.value === selected.value) as ddlOption;
						this.setState({ddlSelected});
					}}/>
				</div>
				<div/>
			</div>
			<div className={"ll_h_c"} style={{width: "59em", height: 30}}>
				{this.state.batch && <><TS_Checkbox
					checked={identical}
					value={identical}
					onCheck={() => {
						this.setState(() => {
							if (identical)
								return ({batchUnits: []});

							return {batchUnits:unitsWithNoTimestamp};
						});
					}}
					label={"Select exactly all units with no timestamp at all"}
				/>
					<TS_Input
						value={this.state.exclude}
						onChange={exclude => this.setState({exclude})}
						type={"text"}
						id={generateHex(4)}
						placeholder={'Type here to exclude auditBy'}
					/>
				</>}
			</div>
			{this.renderRows(unitsWithEnv, floatingGoogleAccounts)}
		</div>;
	}

	private getUnitWithoutTimestamp = (unitsWithEnv: (FullUnitConfig & { env: string })[]) => {
		return unitsWithEnv.reduce((carry: (FullUnitConfig & { env: string })[], unit) => {
			let noTimestamp = true;
			unit.identities.forEach(device => {
				if (device._updated)
					noTimestamp = false;
			});
			if (this.state.exclude && unit._audit?.auditBy?.includes(this.state.exclude))
				noTimestamp = false;

			if (noTimestamp)
				carry.push({...unit});
			return carry;
		}, []);
	};

	private renderRows = (unitsWithEnv: (FullUnitConfig & { env: string })[], floatingGoogleAccounts: GSuiteAccount[]) => {
		return <div
			style={{paddingBottom: 10, maxHeight: "calc(100vh - 80px)", overflow: "auto"}}
			className={'match_width ll_v_c'}
		>
			{unitsWithEnv.map(this.renderRow)}
			{floatingGoogleAccounts.map(this.renderFloating)}
		</div>;
	};

	private unitToEmail = (unitId: string) => unitId + "@elliq.co";

	private getDeleteButton = (unit: Unit) => {
		const gsuiteList = GSuiteModule.getGSuiteList();
		const foundAccount = gsuiteList.some(a => a.primaryEmail === this.unitToEmail(unit.unitId));
		return <>
			<img
			alt={"pah"}
			src={require("@res/images/icn-trash.svg")}
			className={`clickable`}
			width="18px"
			height="18px"
			onClick={() => this.unpairUnitAndDeleteGoogleAccount([{
				unitId: unit.unitId,
				product: unit.product
			}])}
		/>
			{!foundAccount && <img
				alt={"pah"}
				src={require("@res/images/icn-error.svg")}
				onMouseOver={(e) => {
					const rect = e.currentTarget.getBoundingClientRect();
					const x = rect.x + rect.width + 5;
					new TooltipBuilder('No G-Suite account for this pair')
						.setLocation(x, rect.y)
						// .setStyle({ ..._css})
						.setDuration(0)
						.show();
				}}
				onMouseLeave={() => TooltipModule.hide()}
				width="18px"
				height="18px"
			/>}
		</>;
	};

	private renderRow = (unit: FullUnitConfig & { env: string }) => {
		const identities = sortArray(unit.identities, identifier => identifier.type);
		const pairsDetails = identities.map((identifier: DeviceIdentity) => <div key={identifier.serial}>
			<span className={shortRowItem}>{identifier.type}</span>
			<span
				className={longRowItem}>{identifier._updated ? formatTimestamp(Format_YYYYMMDD_HHmmss, identifier._updated) : ""}</span>
		</div>);

		const selectedPeriod = this.state.ddlSelected.value.period;
		const isActiveWithin = unit.identities.every(i => !i._updated) ? false : unit.identities.find(
			item => (item._updated || 0) >= currentTimeMillies() - selectedPeriod);
		const activationColor = isActiveWithin ? "green" : "red";

		return <div key={unit.unitId} className={"ll_h_c"}>
			<div className={shortRowItem}>
				{this.state.batch ?
					<TS_Checkbox
						value={unit.unitId}
						checked={!!this.state.batchUnits.find(u => u.unitId === unit.unitId && u.product === unit.product)}
						onCheck={() => {
							this.setState(prev => {
								const item = prev.batchUnits.find(u => u.unitId === unit.unitId && u.product === unit.product);
								if (item)
									removeItemFromArray(prev.batchUnits, item);
								else
									addItemToArray(prev.batchUnits, {unitId: unit.unitId, product: unit.product});

								return prev;
							});
						}}
						label={null}
					/> : this.getDeleteButton(unit)}
			</div>
			<span className={shortRowItem}>{unit.product}</span>
			<span className={longRowItem}>{unit.unitId}</span>
			{pairsDetails}
			<span className={shortRowItem}>{unit.env}</span>
			<span className={dot(activationColor, 10)}/>
			<span className={shortRowItem}>{unit._audit?.auditBy}</span>
		</div>;


	};

	private renderFloating = (account: GSuiteAccount) => {
		return <div key={account.primaryEmail} className={"ll_h_c"}>
			<span className={shortRowItem}>{account.primaryEmail}</span>
		</div>;
	};
}
