import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {ProductsTabs} from "./ProductsTabs";
import {BackedUpDfAgents} from "./dialogflow-agents/BackedUpDfAgents";

export class Page_BackedUpAgents
	extends BaseComponent {

	constructor(props: {}) {
		super(props);
	}

	componentDidMount() {
		document.title = "Product Manage"
	}

	render() {
		return <>
			<BackedUpDfAgents/>
			<ProductsTabs/>
		</>;
	}
}
