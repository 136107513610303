import * as React from "react";
import * as apiCss from "./css/permissionsApiCss";
import {
	ApiCaller_PermissionsAccessLevel,
	ApiCaller_PermissionsApi,
	ApiCaller_PermissionsDomain,
	OnPermissionsApisLoaded,
	OnPermissionsDomainsLoaded,
	OnPermissionsLevelsLoaded
} from "@intuitionrobotics/permissions/frontend";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/app-frontend/core/BaseComponent";
import {
	DB_PermissionAccessLevel,
	DB_PermissionApi,
	DB_PermissionDomain
} from "@intuitionrobotics/permissions";
import {PermissionsApi} from "./PermissionsApi";
import {FilterInput} from "@intuitionrobotics/thunderstorm/frontend";
import * as permissionsCss from "../css/permissionsCss";
import {sortArray} from "@intuitionrobotics/ts-common";

type Props = {
	filterByProjectId: string
}

type State = {
	filteredApis?: DB_PermissionApi[]
}

export class PermissionsApis
	extends BaseComponent<Props, State>
	implements OnPermissionsApisLoaded, OnPermissionsLevelsLoaded, OnPermissionsDomainsLoaded {
	private apis: DB_PermissionApi[];
	private levels: DB_PermissionAccessLevel[];
	private domains: DB_PermissionDomain[];
	private apisByTextFilter: DB_PermissionApi[];
	private appCheckbox: boolean;
	private deprecatedCheckbox: boolean;
	private exactCheckbox: boolean;

	constructor(props: Props) {
		super(props);
		this.apis = [];
		this.apisByTextFilter = [];
		this.levels = [];
		this.domains = [];
		this.appCheckbox = false;
		this.deprecatedCheckbox = false;
		this.exactCheckbox = false;

		this.state = {}
	}

	async componentDidMount() {
		ApiCaller_PermissionsApi.query();
		ApiCaller_PermissionsAccessLevel.query();
		ApiCaller_PermissionsDomain.query();
	}

	__onPermissionsLevelsLoaded = () => {
		this.forceUpdate();
	}

	__onPermissionsDomainsLoaded = () => {
		this.forceUpdate();
	}

	__onPermissionsApisLoaded = () => {
		this.forceUpdate();
	}

	renderExactFilter() {
		return <div style={{marginLeft: "5px", position: "absolute", right: "252px"}}>
			<label className={apiCss.apiCheckboxLabel} htmlFor="exact">Exact</label>
			<input checked={this.exactCheckbox} onChange={(event) => {
				this.exactCheckbox = event.target.checked;
				this.setState({filteredApis: this.getFilteredUsers()});
			}} type="checkbox" id="exact" name="exact"/>
		</div>;
	}

	renderDeprecatedFilter() {
		return <div style={{marginLeft: "15px", position: "absolute", right: "150px"}}>
			<label className={apiCss.apiCheckboxLabel} htmlFor="deprecated">Deprecated</label>
			<input checked={this.deprecatedCheckbox} onChange={(event) => {
				this.deprecatedCheckbox = event.target.checked;
				this.setState({filteredApis: this.getFilteredUsers()})
			}} type="checkbox" id="deprecated" name="deprecated"/>
		</div>;
	}

	renderApplicationFilter() {
		return <div style={{marginLeft: "5px", position: "absolute", right: "90px"}}>
			<label className={apiCss.apiCheckboxLabel} htmlFor="application">App</label>
			<input checked={this.appCheckbox} onChange={(event) => {
				this.appCheckbox = event.target.checked;
				this.setState({filteredApis: this.getFilteredUsers()});
			}} type="checkbox" id="application" name="application"/>
		</div>;
	}

	getFilteredUsers = () => {
		const {exactCheckbox, deprecatedCheckbox, appCheckbox} = this;
		if (exactCheckbox)
			return this.apisByTextFilter.filter(api => !!api.deprecated === deprecatedCheckbox && !!api.onlyForApplication === appCheckbox);

		if (appCheckbox && deprecatedCheckbox)
			return this.apisByTextFilter.filter(api => !!api.deprecated === true && !!api.onlyForApplication === true);

		if (appCheckbox)
			return this.apisByTextFilter.filter(api => !!api.onlyForApplication === true);

		if (deprecatedCheckbox)
			return this.apisByTextFilter.filter(api => !!api.deprecated === true);

		return this.apisByTextFilter;
	}

	render() {
		const {filterByProjectId} = this.props;
		const {filteredApis} = this.state;
		this.apis = ApiCaller_PermissionsApi.getApis(this.props.filterByProjectId);
		this.levels = ApiCaller_PermissionsAccessLevel.getAllLevels();
		this.domains = ApiCaller_PermissionsDomain.getAllDomains();

		if (this.apis.length === 0)
			return "";

		return <div className={apiCss.apisAreaContainer}>
			<div style={{width: '90%', marginBottom: '20px', marginLeft: '20px'}}>
				<FilterInput
					placeholder={'Filter apis'}
					className={permissionsCss.filterInputClass}
					filter={(api: DB_PermissionApi) => [api.path]}
					list={this.apis}
					onChange={(subApis: DB_PermissionApi[]) => {
						this.apisByTextFilter = subApis;
						this.setState({filteredApis: this.getFilteredUsers()});
					}}/>
			</div>
			<div style={{position: "relative", marginBottom: "50px"}}>
				{this.renderExactFilter()}
				{this.renderDeprecatedFilter()}
				{this.renderApplicationFilter()}
			</div>
			{filteredApis && sortArray(filteredApis, _api => _api.path, true).map(api => <PermissionsApi key={api._id}
			                                                                                             filterByProjectId={filterByProjectId}
			                                                                                             api={api}
			                                                                                             apis={this.apis}
			                                                                                             domains={this.domains}
			                                                                                             levels={this.levels}/>)}
		</div>;
	}
}
