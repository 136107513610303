import * as React from "react";
import * as levelsCss from "./css/permissionLevelsCss";
import {
	ApiCaller_PermissionsAccessLevel,
	OnPermissionsLevelsLoaded
} from "@intuitionrobotics/permissions/frontend";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {DB_PermissionAccessLevel} from "@intuitionrobotics/permissions/shared/manager-types";
import {
	action,
	UpdateLevel,
	updateLevelDisplayMode
} from "./UpdateLevel";
import {sortArray} from "@intuitionrobotics/ts-common";
import {AreYouSureDialog} from "../AreYouSureDialog";

const icon__add = require('@res/images/icon_add_blue.svg');
const icon__remove = require('@res/images/icon_x.svg');

type State = {
	selectedLevelId: string,
	updateLevelMode: updateLevelDisplayMode
}

type Props = {
	filterByDomainId: string
}

export class PermissionLevels
	extends BaseComponent<Props, State>
	implements OnPermissionsLevelsLoaded {
	private methods: DB_PermissionAccessLevel[];
	private newLevelReference: DB_PermissionAccessLevel;

	constructor(props: Props) {
		super(props);

		this.methods = [];
		this.state = {
			selectedLevelId: '',
			updateLevelMode: updateLevelDisplayMode.editLevel
		};

		this.newLevelReference = { _id: '', name: '', value: 0, domainId: props.filterByDomainId};
		this.selectLevel = this.selectLevel.bind(this);
		this.deleteLevel = this.deleteLevel.bind(this);
		this.updateLevel = this.updateLevel.bind(this);
	}

	__onPermissionsLevelsLoaded = () => {
		this.forceUpdate();
	};

	async componentDidMount() {
		ApiCaller_PermissionsAccessLevel.query();
	}

	updateLevel = (level: DB_PermissionAccessLevel) => {
		ApiCaller_PermissionsAccessLevel.update(level);
	};

	selectLevel(methodId: string) {
		const newSelectedMethod = this.methods.find((method) => {
			return method._id === methodId;
		}) || {_id: ''};

		this.setState({selectedLevelId: newSelectedMethod._id, updateLevelMode: updateLevelDisplayMode.editLevel});
	}

	deleteLevel(levelId: string) {
		const {selectedLevelId} = this.state;

		ApiCaller_PermissionsAccessLevel.delete(levelId);

		if (selectedLevelId === levelId) {
			this.setState({selectedLevelId: ''});
		}
	}

	clearNewLevelReference = () => {
		this.newLevelReference.value = 0;
		this.newLevelReference.name = '';
	};

	getUpdateLevelAction = (mode: updateLevelDisplayMode) => {
		const action1: action = {
			text: '',
			callback: () => {}
		};

		const action2: action = {
			text: '',
			callback: () => {}
		};

		if (mode === updateLevelDisplayMode.newLevel) {
			action1.text = 'cancel';
			action1.callback = () => {
				this.clearNewLevelReference();
				this.setUpdateLevelMode(updateLevelDisplayMode.editLevel);
			};
			action2.text = 'add';
			action2.callback = (level: DB_PermissionAccessLevel) => {
				const {filterByDomainId} = this.props;
				if (!level.name)
					return;

				ApiCaller_PermissionsAccessLevel.create({name: level.name, domainId: filterByDomainId, value: level.value});
				this.clearNewLevelReference();
				this.setUpdateLevelMode(updateLevelDisplayMode.editLevel);
			};
		}


		return [action1, action2];
	};

	setUpdateLevelMode(mode: updateLevelDisplayMode) {
		this.setState({updateLevelMode: mode});
	}

	renderNewLevel() {
		const {updateLevelMode} = this.state;
		if (updateLevelMode !== updateLevelDisplayMode.newLevel)
			return null;

		const actions = this.getUpdateLevelAction(updateLevelDisplayMode.newLevel);
		return <UpdateLevel mode={updateLevelMode} level={this.newLevelReference} updateLevel={() => this.updateLevel(this.newLevelReference)} action1={actions[0]} action2={actions[1]}/>;
	}

	renderLevels() {
		const {filterByDomainId} = this.props;
		const actions = this.getUpdateLevelAction(updateLevelDisplayMode.editLevel);
		this.methods = ApiCaller_PermissionsAccessLevel.getLevels(filterByDomainId);
		const methods = sortArray(this.methods, level => level.value, true).map((level) => {
			return <div key={level._id} className={"ll_h_c"} style = {{marginBottom: "10px"}}>
				<UpdateLevel mode={updateLevelDisplayMode.editLevel} level={level} updateLevel={() => this.updateLevel(level)} action1={actions[0]} action2={actions[1]}/>
				{this.renderRowActions(level)}
			</div>
		});

		return <div style={{marginTop: "15px"}}>{methods}</div>;
	}

	renderRowActions = (level: DB_PermissionAccessLevel) => {
		return <img style={{cursor: "pointer"}} onClick={() => {
			new AreYouSureDialog(<div></div>, () => {this.deleteLevel(level._id)}, `Are you sure you want to delete level item: ${level.name}?`).show();
		}} height="15px" width="15px" src={icon__remove}/>;
	};

	renderNewLevelTitle() {
		return <div id={"specific"} className={`${levelsCss.methodsContainerStyle} ${levelsCss.addMethodContainer}`}>
			<img onClick={() => {
				this.setUpdateLevelMode(updateLevelDisplayMode.newLevel);
				this.setState({selectedLevelId: ''});
			}} className={levelsCss.addMethodIcon} src={icon__add}/>
		</div>;
	}

	render() {
		return <>
			{this.renderLevels()}
			{this.renderNewLevel()}
			<div className={'ll_h_c'}>
				{this.renderNewLevelTitle()}
			</div>
		</>
	}
}
