import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {PermissionsTabs} from "./PermissionsTabs";
import {PermissionsToUsers} from "./permissions/permissions-to-users/apps/PermissionsToUsers";
import {
	ApiCaller_PermissionsGroup,
	ApiCaller_PermissionsUser,
	OnPermissionsGroupsLoaded,
	OnPermissionsUsersLoaded
} from "@intuitionrobotics/permissions/frontend";
import {
	AccountModule,
	OnAccountsLoaded
} from "@intuitionrobotics/user-account/frontend";
import {Loader} from "../ui/Loader";

export class Page_Permissions_To_Users
	extends BaseComponent
	implements OnPermissionsUsersLoaded, OnPermissionsGroupsLoaded, OnAccountsLoaded {

	constructor(props: {}) {
		super(props);
	}

	__onAccountsLoaded = () => {
		this.forceUpdate();
	}

	__onPermissionsUsersLoaded = () => {
		this.forceUpdate();
	}

	__onPermissionsGroupsLoaded = () => {
		this.forceUpdate();
	}

	componentDidMount() {
		document.title = "Permission To Users"
		ApiCaller_PermissionsUser.query();
		ApiCaller_PermissionsGroup.query();
		AccountModule.listUsers();
	}

	render() {
		if (ApiCaller_PermissionsUser.getUsers().length === 0)
			return <Loader/>;

		if (AccountModule.getAccounts().length === 0)
			return <Loader/>;

		if (ApiCaller_PermissionsGroup.getGroups().length === 0)
			return <Loader/>;

		return <>
			<PermissionsToUsers/>
			<PermissionsTabs/>
		</>;
	}
}
