import * as emotion from "emotion";

export const userWithoutAccountStyle = emotion.css`
	background-color: #f4f5f6;
`;

export const userStyle = emotion.css`
	background-color: inherit;
`;

export const userCheckBox = emotion.css`
	padding: 0 .5rem;
`;
