import * as React from "react";
import * as domainsCss from "../permissions-domains/css/domainsCss";
import {
	BaseComponent,
	FilterInput
} from "@intuitionrobotics/thunderstorm/frontend";
import {
	DB_PermissionDomain,
	DB_PermissionsGroup
} from "@intuitionrobotics/permissions";
import {
	ApiCaller_PermissionsDomain,
	ApiCaller_PermissionsGroup,
	OnPermissionsDomainsLoaded,
	OnPermissionsGroupsLoaded
} from "@intuitionrobotics/permissions/frontend";
import {AddGroup} from "./AddGroup";
import {GroupItem} from "./GroupItem";
import {
	deepClone,
	sortArray
} from "@intuitionrobotics/ts-common";
import * as permissionsCss from "../css/permissionsCss";
import {Loader} from "../../../ui/Loader";


type State = {
	selectedGroupId: string,
	filteredGroups: DB_PermissionsGroup[]
	showLoader: boolean
}

type Props = {}

export class GroupList
	extends BaseComponent<Props, State>
	implements OnPermissionsGroupsLoaded, OnPermissionsDomainsLoaded {
	private groups: DB_PermissionsGroup[];
	private domains: DB_PermissionDomain[];

	constructor(props: Props) {
		super(props);
		this.state = {
			selectedGroupId: '',
			filteredGroups: [],
			showLoader: false
		};
		this.groups = [];
		this.domains = [];
	}

	__onPermissionsGroupsLoaded = () => {
		this.forceUpdate();
		this.setState({showLoader: false});
	}

	__onPermissionsDomainsLoaded = () => {
		this.forceUpdate();
	}

	async componentDidMount() {
		await ApiCaller_PermissionsGroup.query();
	}

	selectGroup = (domainId: string) => {
		if (!domainId)
			return;

		const {selectedGroupId} = this.state;
		if (selectedGroupId === domainId)
			return this.setState({selectedGroupId: ''});

		const newSelectedGroup = this.groups.find((group) => {
			return group._id === domainId;
		}) || {_id: ''};

		this.setState({selectedGroupId: newSelectedGroup._id});
	}

	deleteGroup = (groupId: string) => {
		this.setState({showLoader: true});

		ApiCaller_PermissionsGroup.delete(groupId);
		if (groupId === this.state.selectedGroupId)
			this.setState({selectedGroupId: ''});
	};

	getGroup(objectId: string) {
		return this.groups.find((item) => {
			return item._id === objectId;
		});
	}

	renderFilter = () => {
		if (!this.groups.length)
			return;

		return (
			<div style={{width: '90%', marginBottom: '20px', marginLeft: '20px'}}>
				<FilterInput placeholder={'Filter groups'}
				             className={permissionsCss.filterInputClass}
				             filter={(group: DB_PermissionsGroup) => [group.label.toString()]}
				             list={this.groups}
				             onChange={(subGroups: DB_PermissionsGroup[]) => this.setState({filteredGroups: subGroups})}/>
			</div>
		)
	}

	renderLoader() {
		const {showLoader} = this.state;
		if (!showLoader)
			return;

		return <Loader/>;
	}

	render() {
		const {selectedGroupId, filteredGroups} = this.state;
		this.groups = ApiCaller_PermissionsGroup.getGroups();
		this.domains = ApiCaller_PermissionsDomain.getAllDomains();
		const groups = sortArray(filteredGroups, filteredGroup => filteredGroup.label, true).map((group) => {
			const isOn = group._id === selectedGroupId;
			const tempGroup = deepClone(group)
			tempGroup.accessLevelIds = tempGroup.accessLevelIds || []
			tempGroup.customFields = tempGroup.customFields || []

			return <GroupItem key={group._id}
			                  onUpdateGroup={() => {
				                  ApiCaller_PermissionsGroup.update(tempGroup)
			                  }}
			                  onDeleteGroup={this.deleteGroup}
			                  onGroupSelected={this.selectGroup}
			                  isOn={isOn}
			                  domains={this.domains}
			                  group={tempGroup}/>
		});

		return <div className={domainsCss.domainContainer}>
			{this.renderFilter()}
			<AddGroup/>
			{groups}
			{this.renderLoader()}
		</div>;
	}
}
