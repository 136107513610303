import {DB_PermissionDomain, DB_PermissionProject} from "@intuitionrobotics/permissions/shared/manager-types";
import { DB_PermissionsGroup } from "@intuitionrobotics/permissions/shared/assign-types";
import {ApiCaller_PermissionsUser} from "@intuitionrobotics/permissions/frontend";
import {UI_User} from "./permissions-users/AccountsList";
import {AccountModule} from "@intuitionrobotics/user-account/frontend";

export function getDDlOptions(labelKey: string, valueKey: string, array: any[]) {
	return array.map((item) => {
		return {label: item[labelKey], value: item[valueKey]}
	});
}

export function getDomain(objectId: string, domains: DB_PermissionDomain[]) {
	return domains.find((item) => {
		return item._id === objectId;
	});
}

export function getProject(objectId: string, projects: DB_PermissionProject[]) {
	return projects.find((item) => {
		return item._id === objectId;
	});
}

export function getGroup(objectId: string, groups: DB_PermissionsGroup[]) {
	return groups.find((item) => {
		return item._id === objectId;
	});
}

export function getAllUsers() {
	const userPermissions  = ApiCaller_PermissionsUser.getUsers();
	const accounts: UI_User[] = AccountModule.getAccounts();
	const uPermissionsWithoutAccount = userPermissions.filter(userPermission => {
		return !accounts.find(account => userPermission.accountId === account._id) && userPermission.accountId;
	}).map(uP => {
		const userWithoutAccount: UI_User = {_id: uP._id, email: uP.accountId, userWithoutAccount: true};
		return userWithoutAccount;
	});

	return [...accounts, ...uPermissionsWithoutAccount];
}
