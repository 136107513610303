import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {ProductsTabs} from "./ProductsTabs";
import {AddUnitEnvItem} from "./unit-configurations/AddUnitEnvItem";

export class Page_AddingDBUnitConfItem
	extends BaseComponent {

	constructor(props: {}) {
		super(props);
	}

	componentDidMount() {
		document.title = "Unit Conf Item"
	}

	render() {
		return <>
			<ProductsTabs/>
			<AddUnitEnvItem/>
		</>;
	}
}
