import * as React from "react";

type Props = {
	message?: string
	overlay?: boolean
}

export class Loader
	extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
	}

	render() {
		const spinner = this.renderSpinner();
		if (!this.props.overlay)
			return spinner;

		return <div
			id={'loader'}
			className="match_width match_height full_screen"
			style={{background: 'rgba(237, 242, 246, 0.5)'}}>
			{spinner}
		</div>;
	}

	private renderSpinner = () => (
		<div className="ll_h_c match_height fill">
			<div className="ll_v_c match_width">
				<div className={`loader`}/>
				<div style={{marginTop: '20px', color: '#f9f9f9'}}>{this.props.message ? this.props.message : "Loading..."}</div>
			</div>
		</div>
	);
}