import * as React from "react";
import {
	Adapter,
	AdapterBuilder,
	BaseComponent,
	ToastModule,
	Tree,
	TreeNode,
	XhrHttpModule
} from "@intuitionrobotics/thunderstorm/frontend";
import * as emotion from "emotion";
import {ApiCaller_ContentVersions, OnContentVersionsLoaded} from "@modules/ApiCaller_ContentVersions";
import {ApiCaller_Products, OnProductsLoaded} from "@modules/ApiCaller_Products";
import {Product} from "@app/ir-q-app-common/types/products";
import {DB_DeletedUnitGsuit, DB_UnpairedUnit, DeviceIdentity, FullUnitConfig} from "@app/ir-q-app-common/types/units";
import {
	CONST_ContentVersionMapKey,
	ContentVersionLinks_ApiDelete,
	DB_ContentVersion,
	DB_UnitMetadata,
	FirebasePointer
} from "@app/ir-q-app-common/types/unit-config";
import {ApiCaller_UnitMetadata, OnUnitMetadataLoaded} from "@modules/ApiCaller_UnitMetadata";
import {OnPairedUnitsLoaded, OnUnpairedUnitsLoaded, UnitsModule} from "@modules/UnitsModule";
import {
	_keys,
	currentTimeMillies,
	Day,
	Format_YYYYMMDD_HHmmss,
	formatTimestamp,
	generateHex,
	ObjectTS,
	padNumber,
	sortArray,
	Week
} from "@intuitionrobotics/ts-common";
import {KasperoDeleteUnit} from "@app/ir-q-app-common/types/api";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {AreYouSureDialog} from "../permissions/AreYouSureDialog";
import {Loader} from "../../ui/Loader";
import {GSuiteModule, OnGSuiteListLoaded} from "@modules/GSuiteModule";
import {admin_directory_v1} from "googleapis";
import {CustomDropdown} from "../../components/CustomDropdown";
import Schema$User = admin_directory_v1.Schema$User;
import {config} from "../../../config";

export const dot = (color: string, size: number) => emotion.css`
  height: ${size}px;
	width: ${size}px;
	background-color: ${color};
	border-radius: 50%;
	display: inline-block;
	margin-left: 10px;
  margin-right: 4px;
`;

export type ddlOption = {
	key: string,
	label: string,
	value: {
		period: number
	}
}

export const PeriodList: ddlOption[] = [{key: "always", label: "Always", value: {period: Infinity}},
	{key: "day", label: "A day", value: {period: Day}},
	{key: "week", label: "A week", value: {period: Week}},
	{key: "thirtyDays", label: "30 days", value: {period: Day * 30}}];

type Props = {};
type State = {
	ddlSelected: ddlOption,
	showLoader: boolean
};

export class Versions
	extends BaseComponent<Props, State>
	implements OnProductsLoaded, OnUnitMetadataLoaded, OnPairedUnitsLoaded, OnUnpairedUnitsLoaded, OnContentVersionsLoaded, OnGSuiteListLoaded {

	constructor(props: Props) {
		super(props);
		this.state = {
			ddlSelected: PeriodList[0],
			showLoader: false
		}
	}

	componentDidMount() {
		ApiCaller_Products.query();
		ApiCaller_ContentVersions.query();
		ApiCaller_UnitMetadata.query();
		UnitsModule.fetchUnits();
		UnitsModule.fetchUnpairedUnits();
		GSuiteModule.fetchGSuiteListWithNoAdmins();
	}

	__onProductsLoaded = () => this.forceUpdate();

	__onUnitMetadataLoaded = () => this.forceUpdate();

	__onPairedUnitsLoaded = () => this.forceUpdate();

	__onUnpairedUnitsLoaded = () => this.forceUpdate();

	__onContentVersionsLoaded = () => this.forceUpdate();

	__onGSuiteListLoaded = () => this.forceUpdate();

	getProductNode(product: Product, versions: DB_ContentVersion[], units: FullUnitConfig[], unitsMetadatas: DB_UnitMetadata<any>[]) {
		const {period} = this.state.ddlSelected.value;
		let activeVersions = 0;
		const productItem = {name: product.name, onCount: activeVersions, offCount: versions.length - activeVersions};
		return {
			type: "product",
			item: productItem,
			_children: sortArray(versions, v => {
				return [...v.version.split("\."),
				        v.increment || 0].reduce((toRet, index) => `${toRet}${padNumber(index, 3)}`, "");
			}).map(version => {
				let activeUnitsPerVersion = 0;
				const versionUnits = units.filter(unit => {
					const unitMeta = unitsMetadatas.find((meta) => meta.unitId === unit.unitId);
					return unitMeta?.data === version._id;
				});

				versionUnits.forEach((unit) => {
					if (Math.max(...unit.identities.map(device => (device?._updated || 0))) >= currentTimeMillies() - period)
						activeUnitsPerVersion++;
				});

				if (activeUnitsPerVersion) {
					activeVersions++;
					productItem.onCount = activeVersions;
					productItem.offCount = versions.length - activeVersions;
				}

				const agentsByProduct = product.gcp.envs.find(env => env.name === config.Environment)?.agents;
				const defaultAgents: ObjectTS = version.agents || agentsByProduct || {};
				return {
					type: "version",
					item: {version, defaultAgents, onCount: activeUnitsPerVersion, offCount: versionUnits.length - activeUnitsPerVersion},
					_children:
						versionUnits.map(unit => {
							const lastActiveTimestamp = Math.max(...unit.identities.map(device => (device?._updated || 0)));
							const isUnitActive = lastActiveTimestamp >= currentTimeMillies() - period;
							return {
								type: "unit",
								item: {unitId: unit.unitId, product: unit.product, lastActiveTimestamp, active: isUnitActive, devUnit: unit.devUnit},
								_children:
									unit.identities.map(device => {
										return {
											type: "device",
											item: {device}
										}
									})
							};
						})
				};
			})
		};
	}

	deleteVersion = (version: DB_ContentVersion) => {
		const versionName = version.version;
		new AreYouSureDialog(<div></div>, () => {
			deleteV()
		}, `Are you sure you want to delete version: ${versionName}?`).show();

		const deleteV = () => {
			this.setState({showLoader: true});
			XhrHttpModule
				.createRequest<ContentVersionLinks_ApiDelete>(HttpMethod.POST, 'delete-version')
				.setRelativeUrl("/v1/content-version-links/delete-version")
				.setJsonBody(version)
				.setLabel(`Deleting version: '${versionName}'`)
				.setOnSuccessMessage(`Version: '${versionName}' has deleted successfully`)
				.setOnError(`Error deleting version: '${versionName}'`)
				.execute(async () => {
					ApiCaller_ContentVersions.query();
					this.setState({showLoader: false});
				});
		}
	}

	deleteUnit = (deleteObj: DB_DeletedUnitGsuit) => {
		const unitId = deleteObj.unpairedUnit.unitId;
		new AreYouSureDialog(<div></div>, () => {
			delUnit()
		}, `Are you sure you want to delete unit: ${unitId}?`).show();

		const delUnit = () => {
			this.setState({showLoader: true});
			XhrHttpModule
				.createRequest<KasperoDeleteUnit>(HttpMethod.POST, 'delete-unit')
				.setRelativeUrl("/v1/unit/delete-unit")
				.setJsonBody(deleteObj)
				.setLabel(`Deleting unit: '${unitId}'`)
				.setOnSuccessMessage(`Unit: '${unitId}' has deleted successfully`)
				.setOnError(`Error deleting unit: '${unitId}'`)
				.execute(async () => {
					this.setState({showLoader: false});
					UnitsModule.fetchUnpairedUnits();
				});
		}
	}

	deleteGSuiteAccount = (primaryEmail: string) => {
		if (!primaryEmail)
			return ToastModule.toastError('No email supplied');

		new AreYouSureDialog(<div></div>, () => {
			delGSuiteAccount()
		}, `Are you sure you want to delete google account: ${primaryEmail}?`).show();
		const delGSuiteAccount = () => {
			this.setState({showLoader: true});
			const callback = async () => {
				ToastModule.toastSuccess(`Gsuite account ${primaryEmail} was deleted successfully`);
				this.setState({showLoader: false});
			};

			GSuiteModule.deleteGSuiteAccount(primaryEmail, callback);
		}
	}

	renderLoader() {
		const {showLoader} = this.state;
		if (!showLoader)
			return null;

		return <Loader/>;
	}

	render() {
		const selectedPeriod = this.state.ddlSelected.value.period;
		const products = ApiCaller_Products.getProducts();
		const versions = ApiCaller_ContentVersions.getContentVersions();
		const units = UnitsModule.getUnits();
		const unpairedUnits = UnitsModule.getUnpairedUnits().filter(unpaired => unpaired.unitId);
		const unassociatedGoogleAccount: Schema$User[] = [];
		const gSuiteAccounts = GSuiteModule.getGSuiteList();
		const filteredUnpairedUnits: DB_UnpairedUnit[] = [];
		if (units.length && unpairedUnits.length) {
			gSuiteAccounts.forEach(googleAccountItem => {
				const unitId = googleAccountItem.primaryEmail?.substring(0, googleAccountItem.primaryEmail?.indexOf('@'));
				if (!unitId) {
					ToastModule.toastError(
						`GSuite account with name Obj: ${googleAccountItem.name}, and emails Obj: ${googleAccountItem.emails} -- Has No name.givenName`);
					return;
				}

				const findUnit = (unitItem: { unitId: string, deleted?: boolean }) => (unitItem.unitId === unitId);
				if (units.find(findUnit)) {
					return;
				}

				// cause of rename unit - so could be not in unpaired that's y comment
				// if (!unpairedUnits.find(findUnit)) {
				// 	return;
				// }

				unassociatedGoogleAccount.push(googleAccountItem);
			});

			unpairedUnits.forEach(unpaired => {
				const foundItemIndex = filteredUnpairedUnits.findIndex(filteredItem => filteredItem.unitId === unpaired.unitId);
				const isRepaired = (unpairedU: DB_UnpairedUnit) => units.find(unit => unit.unitId === unpairedU.unitId);
				const isDeleted = (unpairedU: DB_UnpairedUnit) => unpairedUnits.find(unpUnit => unpUnit.deleted && unpUnit.unitId === unpairedU.unitId
					&& (unpUnit._audit && unpairedU._audit && unpUnit._audit.auditAt.timestamp >= unpairedU._audit.auditAt.timestamp));

				if (isDeleted(unpaired) || isRepaired(unpaired))
					return;

				const foundItem = filteredUnpairedUnits[foundItemIndex];
				if (!foundItem) {
					filteredUnpairedUnits.push(unpaired);
					return;
				}

				if (foundItem._audit && unpaired._audit && unpaired._audit.auditAt.timestamp > foundItem._audit.auditAt.timestamp) {
					filteredUnpairedUnits.splice(foundItemIndex, 1);
					filteredUnpairedUnits.push(unpaired);
				}
			});
		}

		const unitsMetadatas = ApiCaller_UnitMetadata.getUnitMetadata().filter(metadata => metadata.dataKey === CONST_ContentVersionMapKey);
		const data: any[] = [];

		products.forEach(product => {
			const productUnits = units.filter((unit) => unit.product === product.key);

			data.push(this.getProductNode(product, versions, productUnits, unitsMetadatas));
		});

		const toggleSign = (props: { node: TreeNode }) => <div id={props.node.path} onClick={props.node.expandToggler}
		                                                       style={{marginRight: "8px", width: "10px"}}>{props.node.expanded ? "-" : "+"}</div>;
		const spaceTaker = (width: number) => <span style={{display: "inline-block", width: `${width}px`}}></span>;
		const adapter: Adapter = AdapterBuilder()
			.list()
			.multiRender({
				             reg: (props: { item: string }) => <div>{props.item}</div>,
				             device: (props: { item: { device: DeviceIdentity } }) => {
					             const activeTimestamp = props.item.device._updated;
					             return <>
						             <span style={{display: "inline-block", width: "100px"}}>{props.item.device.type}</span>
						             <span>{activeTimestamp ? formatTimestamp(Format_YYYYMMDD_HHmmss, activeTimestamp) : ""}</span>
					             </>
				             },
				             unit: (props: { item: { unitId: string, product: string, lastActiveTimestamp: number, active: boolean, devUnit?: boolean }, node: TreeNode }) => {
					             const lastActiveTimestamp = props.item.lastActiveTimestamp;
					             const isActive = props.item.active;
					             const isActiveColor = isActive ? "" : "red";
					             return <div className="ll_h_c">
						             {toggleSign(props)}
						             <span style={{display: "flex", color: isActiveColor}}>
						                <span style={{display: "flex", alignItems: "center", width: "170px"}}>{props.item.unitId}
							                {props.item.devUnit && <img style={{marginLeft: "3px"}} height="15px" width="15px" src={require('@res/images/bug.png')}/>}
						                </span>
						                <span style={{display: "inline-block", width: "230px"}}>{lastActiveTimestamp ? formatTimestamp(Format_YYYYMMDD_HHmmss,
						                                                                                                               lastActiveTimestamp) : ""}</span>
						             </span>
					             </div>
				             },
				             bold: (props: { item: string }) => <div><b>{props.item}</b></div>,
				             product: (props: { item: { name: string, onCount: number, offCount: number }, node: TreeNode }) => {
					             return <div className="ll_h_c">
						             {(props.item.onCount > 0 || props.item.offCount > 0) ? toggleSign(props) : spaceTaker(18)}
						             <b>{props.item.name}</b>
						             {props.item.onCount > 0 && <><span className={dot("green", 10)}/><label>{props.item.onCount}</label></>}
						             {props.item.offCount > 0 && <><span className={dot("red", 10)}/> <label>{props.item.offCount}</label></>}

					             </div>;
				             },
				             version: (props: { item: { version: DB_ContentVersion, defaultAgents: ObjectTS, onCount: number, offCount: number }, node: TreeNode }) => {
					             const version = props.item.version;
					             let label = `${version.version}`;
					             if (version.increment !== undefined)
						             label += `-${version.increment}`

					             if (version.label !== undefined && version.label !== 'default')
						             label += `-${version.label}`

					             const defaultAgents = props.item.defaultAgents;
					             const isActive = props.item.onCount > 0;
					             const allowToDelete = !isActive && selectedPeriod >= Day * 30;
					             const isActiveColor = isActive ? "" : "red";
					             return <div className="ll_h_c">
						             {isActive ? toggleSign(props) : spaceTaker(18)}

						             <span style={{display: "flex", alignItems: "center", width: "150px", color: isActiveColor}}>
						                <b style={{display: "inline-block", color: isActiveColor}}>{label}</b>
							             {allowToDelete &&
								             <img onClick={() => this.deleteVersion(version)} style={{marginLeft: "3px", cursor: "pointer"}} height="18px" width="18px"
								                  src={require('@res/images/icn-trash.svg')}/>}
							             {isActive && <>
								             <span style={{marginLeft: "10px"}} className={dot("green", 10)}/>
								             <label style={{display: "inline-block", width: "30px"}}>{props.item.onCount}</label>
							             </>}
						             </span>

						             {props.item.offCount > 0 ? <>
							             <span style={{marginLeft: "10px"}} className={dot("red", 10)}/>
							             <label style={{display: "inline-block", width: "30px"}}>{props.item.offCount}</label>
						             </> : spaceTaker(40)}

						             <a
							             target="_blank"
							             style={{marginLeft: "10px"}}
							             href={`https://console.firebase.google.com/project/${version.projectId}/database/${version.projectId}/data/${version.path}`}>Version
							             Data</a>
						             {
							             _keys(defaultAgents).sort().map((agentName) => {
								             return <a
									             key={agentName}
									             target="_blank"
									             style={{marginLeft: "10px"}}
									             href={`https://dialogflow.cloud.google.com/#/agent/${defaultAgents[agentName]}/intents`}>{agentName}
								             </a>
							             })}
					             </div>;
				             },
				             link: (props: { item: FirebasePointer }) => <div>
				             </div>
			             })
			.nested()
			.setData(data)
			.build();

		return <div className="ll_h_t" style={{userSelect: "none"}}>
			<div style={{flex: 1.5}}>
				<h2>Versions to units</h2>
				<div style={{marginLeft: "10px", marginBottom: "10px"}}>
					<div>Active within:</div>
					<CustomDropdown options={PeriodList}
									value={this.state.ddlSelected}
									onChange={selected => {
										const ddlSelected = PeriodList.find(p => p.value === selected.value) as ddlOption;
						this.setState({ddlSelected});
					}}/>
				</div>
				<Tree adapter={adapter} expanded={Tree.recursivelyExpand(adapter, (key, value, level, path) => level < 1)}/>
			</div>

			<div style={{flex: 1}}>
				<h3 style={{marginTop: "30px"}}>Unpaired units</h3>
				<div style={{marginLeft: "10px", marginBottom: "10px"}}>
					{filteredUnpairedUnits.map(unpairedUnit => <div key={generateHex(12)} className="ll_h_t">
						<div style={{display: "flex", alignItems: "center", width: "200px"}}>
							{unpairedUnit.unitId}
							<img onClick={() => {
								const email = gSuiteAccounts.find(gAccount => (gAccount.name?.givenName && gAccount.name.givenName === unpairedUnit.unitId))?.primaryEmail;
								this.deleteUnit({
									                unpairedUnit,
									                primaryAccountEmail: email ? email : undefined
								                })
							}
							} style={{marginLeft: "3px", cursor: "pointer"}} height="18px" width="18px" src={require('@res/images/icn-trash.svg')}/>
						</div>
						<div>{unpairedUnit._audit?.auditAt.pretty}</div>
					</div>)}
				</div>
			</div>

			<div style={{flex: 2}}>
				<div className="ll_h_r">
					<h3 style={{marginTop: "30px", width: "300px"}}>Unassociated google accounts</h3>
					<h6 style={{width: "200px"}}> Creation time - Last login time </h6>
				</div>
				<div style={{marginLeft: "10px", marginBottom: "10px"}}>
					{unassociatedGoogleAccount.map(googleAccount => <div key={generateHex(12)} className="ll_h_t">
						<div style={{display: "flex", alignItems: "center", width: "300px"}}>
							{googleAccount.primaryEmail}
							{/*delete gsuite only by delete unpair */}
							<img onClick={() => {
								this.deleteGSuiteAccount(googleAccount.primaryEmail || "")
							}} style={{marginLeft: "3px", cursor: "pointer"}} height="18px" width="18px" src={require('@res/images/icn-trash.svg')}/>
						</div>
						<div className="ll_h_c">
							<div style={{width: "215px"}}>{googleAccount.creationTime}</div>
							<div style={{width: "20px"}}> -</div>
							<div>{googleAccount.lastLoginTime}</div>
						</div>
					</div>)}
				</div>
			</div>
			{this.renderLoader()}
		</div>;
	}
}
