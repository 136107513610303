import * as React from "react";
import * as domainsCss from "./css/domainsCss";
import {DomainItem} from "./DomainItem";
import {AddDomain} from "./AddDomain";
import {BaseComponent, FilterInput} from "@intuitionrobotics/thunderstorm/frontend";
import {DB_PermissionDomain} from "@intuitionrobotics/permissions";
import { OnPermissionsDomainsLoaded, ApiCaller_PermissionsDomain } from "@intuitionrobotics/permissions/frontend";

import * as permissionsCss from "../css/permissionsCss";
import {
	generateHex,
	sortArray
} from "@intuitionrobotics/ts-common";


type State = {
	selectedDomainId: string,
	filteredDomains: DB_PermissionDomain[],
	filterInputKey: string
}

type Props = {
	filterByProjectId: string
}

export class DomainList
	extends BaseComponent<Props, State>
	implements OnPermissionsDomainsLoaded {
	private domains: DB_PermissionDomain[];

	constructor(props: Props) {
		super(props);
		this.state = {
			selectedDomainId: '',
			filteredDomains: [],
			filterInputKey: ''
		};
		this.domains = [];
		this.selectDomain = this.selectDomain.bind(this);
	}

	__onPermissionsDomainsLoaded = () => {
		this.forceUpdate();
		this.makeFilterRender();
	}

	async componentDidMount() {
		ApiCaller_PermissionsDomain.query();
	}

	componentDidUpdate(prevProps: Readonly<Props>) {
		const {filterByProjectId} = this.props;
		if (prevProps.filterByProjectId !== filterByProjectId) {
			this.makeFilterRender();
		}
	}

	makeFilterRender() {
		this.setState({filterInputKey: generateHex(32)});
	}

	selectDomain = (domainId: string) => {
		if (!domainId)
			return;

		const {selectedDomainId} = this.state;
		if (selectedDomainId === domainId)
			return this.setState({selectedDomainId: ''});

		const newSelectedDomain = this.domains.find((domain) => {
			return domain._id === domainId;
		}) || {_id: ''};

		this.setState({selectedDomainId: newSelectedDomain._id});
	}

	deleteDomain = (domainId: string) => {
		ApiCaller_PermissionsDomain.delete(domainId);

		if (domainId === this.state.selectedDomainId)
			this.setState({selectedDomainId: ''});
	};

	updateDomain = () => {
		const {selectedDomainId} = this.state;
		const selectDomain = this.getDomain(selectedDomainId);
		if (!selectDomain)
			return;

		ApiCaller_PermissionsDomain.update(selectDomain);
	};

	getDomain(objectId: string) {
		return this.domains.find((item) => {
			return item._id === objectId;
		});
	}

	renderFilter = () => {
		if (!this.domains.length) {
			if (this.state.filteredDomains.length) {
				this.setState({filteredDomains: []});
			}

			return null;
		}

		const {filterInputKey} = this.state;

		return <div style={{width: '90%', marginBottom: '20px', marginLeft: '20px'}}>
			<FilterInput key={filterInputKey} placeholder={'Filter domains'} className={permissionsCss.filterInputClass} filter={(domain: DB_PermissionDomain) => {return [domain.namespace.toString()]}}
			             list={this.domains}
			             onChange={(subDomains: DB_PermissionDomain[]) => {
				             this.setState({filteredDomains: subDomains})}
			             }/>
		</div>
	}

	render() {
		const {selectedDomainId, filteredDomains} = this.state;
		const {filterByProjectId} = this.props;
		this.domains = ApiCaller_PermissionsDomain.getDomains(filterByProjectId);
		const domains = sortArray(filteredDomains, _domain => _domain.namespace, true).map((domain) => {
			const isOn = domain._id === selectedDomainId;
			return <DomainItem key={domain._id} onUpdateDomain={this.updateDomain} onDeleteDomain={this.deleteDomain} onDomainSelected={this.selectDomain} isOn={isOn} domain={domain} />
		});

		return <div className={domainsCss.domainContainer}>
			{this.renderFilter()}
			<AddDomain projectId={filterByProjectId} />
			{domains}
		</div>;
	}
}
