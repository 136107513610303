import * as emotion from "emotion";

export const levelLineBarStyle = emotion.css`
	border: solid 0.5px #b1b4b5;
	height: 0px;
	width: 200px;
	position: relative;
`;

export const lineBarContainerStyle = emotion.css`
	height: 18px;
  display: flex;
  align-items: center;
`;

export const levelBulletStyle = emotion.css`
  background-color: #157dd2;
  border-radius: 100%;
	height: 18px;
	width: 18px;
	position: absolute;
	top: -9px;
`;

export const lowerBoundaryValueStyle = emotion.css`
  font-size: 12px;
  color: #b1b4b5;
  margin-right: 11px;
`;

export const upperBoundaryValueStyle = emotion.css`
  font-size: 12px;
  color: #b1b4b5;
  margin-left: 11px;
`;

export const levelContainerStyle = emotion.css`
	display: flex;
	align-items: center;
`;

export const levelValueInputStyle = emotion.css`
	background-color: inherit;
	margin-left: 15px;
	outline: none;
	border: solid 1px #363f46;
	border-radius: 2px;
	color: #1588d8;
	font-size: 14px;
`;
