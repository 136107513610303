

/**
 * Created by tacb0ss on 28/07/2018.
 */
module.exports = {
	Text_HelloWorld: "Hello World",
	Text_About: "About",
	Text_Home: "Home",
};
