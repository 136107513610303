import * as React from "react";
import * as emotion from "emotion";

import {AccountModule, StorageKey_JWT, StorageKey_UserEmail,} from "@intuitionrobotics/user-account/frontend";
import {Route_Login} from "../components/navigation-bar";
import {RoutingModule} from "@intuitionrobotics/thunderstorm/frontend";
import {LoginModule} from "@modules/LoginModule";
import {QueryParam_RedirectUrl} from "@intuitionrobotics/user-account/shared/api";
import {Redirect} from "react-router-dom";
import {Component_Login} from "../components/Component_Login";
import {Component_Register} from "../components/Component_Register";
import {LoginsParent} from "./LoginsParent";
import {EnvironmentModule} from "@modules/EnvironmentModule";
import {Loader} from "../ui/Loader";
import {loginTitleColor} from "../pages/Login_Mobile";

// background: url(${icons.desktopBackground}) no-repeat top center fixed;
export const desktopBackgroundStyle = emotion.css`
	height: ${window.innerHeight}px;
`;

const rectangleStyle = emotion.css`
	background-color: #fff;
	box-shadow: 2px 4px 24px 0px #0000000D;
  color: rgb(131, 146, 166);
  justify-content: center;  
  height: fit-content;
  margin: auto;
  pointer-events: unset;
  width: 906px;
  height: 682px;
  border-radius: 20px;
  opacity: 0.699999988079071px;
`;

export const roundedButton = emotion.css`
	height: 38px;
	border-radius: 25px;
	background-color: #9c9ccd;
	border-width: 0;
	text-align: left;
	padding: 0 15px;
	color: white;
	margin-top: 10px;
`;

const emailValidationRe = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const buttonStyle = emotion.css({
    "backgroundColor": "#487CFF",
    "borderWidth": "0",
    borderRadius: 10,
    fontSize: 17,
    "textAlign": "center",
    "padding": "0 15px",
    "color": "#fbfbfb",
    marginBottom: 25,
    outline: "none",
    ":active": {
        backgroundColor: "#0081b5"
    }
});

const grayLine = emotion.css`
	width: 93px;
	height: 1px;
	margin: 2px;
`;

export class Page_Login
    extends LoginsParent<{}, { loading: boolean }> {
    constructor(props: {}) {
        super(props, 'IR Login');
        this.state = {
            loading: false
        }
    }

    renderContent = () => {
        return <div id="login-desktop" className={desktopBackgroundStyle}
                    style={{height: "100%", width: "100%", position: "fixed", top: 0, backgroundColor: "white", display: "flex"}}>
            <div className={`ll_v_c ${rectangleStyle}`}>
                <div style={{color: loginTitleColor, fontWeight: 500, fontSize: "27px", lineHeight: "34px", marginTop: "20px", marginBottom: "30px"}}>Welcome!
                </div>
                {this.renderSamlBtn()}
                <div className={`ll_h_c`} style={{marginBottom: "25px"}}>
                    <div className={grayLine}/>
                    <span style={{padding: "0px 7px", color: loginTitleColor, fontWeight: 500, fontSize: "18px", lineHeight: "21.48px"}}>OR</span>
                    <div className={grayLine}/>
                </div>
                {this.renderUserLogin()}
            </div>
            {this.state.loading && <Loader overlay={true}/>}
        </div>;
    };

    private renderUserLogin = () => {
        return <>
            <Component_Login
                validate={(data => {
                    const errs: { [field: string]: string } = {};
                    if (!data.email || !emailValidationRe.test(data.email))
                        errs['email'] = '- Please provide a valid email address';
                    if (!data.password || data.password.length < 8)
                        errs['password'] = '- Please enter a password at least 8 characters';

                    return errs;
                })}
            />
            {/*<div className={redirectLabelStyle}>*/}
            {/*	New here? <a href={RoutingModule.getRoute(Route_Register).path} className={hrefStyle}>Register</a>*/}
            {/*</div>*/}
        </>;
    };

    private renderSamlBtn = () => {
        const loginContext = {
            [QueryParam_RedirectUrl]: LoginModule.getSamlRedirectUrl()
        };
        return <div className="ll_v_c" style={{color: "white", width: "375px"}}>
            <button onClick={() => {
                AccountModule.loginSAML(loginContext);
                this.setState({loading: true})
            }} className={`clickable ${buttonStyle}`} style={{width: "100%", height: 37}}>
                Login with IR account
            </button>
        </div>;
    };
}

export class Page_Register
    extends LoginsParent {
    constructor(props: {}) {
        super(props, 'IR Register');
    }

    renderContent = () => {
        return <div className={'ll_v_c'}>
            <Component_Register validate={data => {
                const errs: { [field: string]: string } = {};
                if (!data.email || !emailValidationRe.test(data.email))
                    errs['email'] = '- Please provide a valid email address';

                if (!data.password || data.password.length < 8)
                    errs['password'] = '- Please enter a password which is at least 8 characters long';

                if (data.password !== data.password_check)
                    errs['password_check'] = '- Password mismatch, check your spelling!';

                return errs;
            }}/>
            {/*<div className={redirectLabelStyle}>*/}
            {/*    One of us? <a href={RoutingModule.getRoute(Route_Login).path} className={hrefStyle}>Login</a>*/}
            {/*</div>*/}
        </div>;
    };
}

export class Page_Logout
    extends React.Component {
    constructor(props: {}) {
        super(props);
        StorageKey_JWT.delete();
        StorageKey_UserEmail.delete();
    }

    render() {
        const search = EnvironmentModule.getSearch();
        return <Redirect to={`${RoutingModule.getRoute(Route_Login).path}${search ? `?${search}` : ''}`}/>;
    }
}
