import * as React from "react";
import {
	BaseComponent,
	RoutingModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {Route_Logout} from "../components/navigation-bar";
import * as emotion from "emotion";
import {
	Route_PairedListActivationByEnv,
	Route_Versions
} from "../components/logged-bar";

const logoutClass = emotion.css`
	position: fixed;
	top: 0;
	left: 0;
`;

const tab = emotion.css`
	margin-left: 30px;
`;

export class UnitsActivationTabs
	extends BaseComponent {

	constructor(props: {}) {
		super(props);
	}

	render() {
		return <>
			<div className={logoutClass}>
				<a href={RoutingModule.getRoute(Route_Logout).path}>Logout</a>
				<a className={tab} href={RoutingModule.getRoute(Route_PairedListActivationByEnv).path}>Units last activation by 3 envs</a>
				<a className={tab} href={RoutingModule.getRoute(Route_Versions).path}>Gsuite accounts</a>
			</div>
		</>;
	}
}
