import * as React from "react";
import * as projectsCss from "../../permissions-projects/css/permissionsProjectsCss";
import {DB_PermissionProject} from "@intuitionrobotics/permissions";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";

import {
	OnPermissionsProjectsLoaded,
	ApiCaller_PermissionsProject
} from "@intuitionrobotics/permissions/frontend";
import {DomainToUsersList} from "../domains/DomainToUsersList";
import { ProjectName } from "../../permissions-projects/ProjectName";

type State = {
	selectedProjectId: string
}

type Props = {}

export class PermissionsToUsers
	extends BaseComponent<Props, State>
	implements OnPermissionsProjectsLoaded {
	private projectsList: DB_PermissionProject[];

	constructor(props: Props) {
		super(props);
		this.state = {
			selectedProjectId: ''
		};

		this.selectProject = this.selectProject.bind(this);
		this.projectsList = [];
	}

	__onPermissionsProjectsLoaded = () => {
		this.forceUpdate();
	};

	componentDidMount() {
		ApiCaller_PermissionsProject.query();
	}

	private renderProjects = () => {
		return <>
			<div className={projectsCss.projectsListContainer}>
				{this.renderProjectNames()}
				{this.renderDomains()}
			</div>
		</>
	};

	private renderDomains = () => {
		const {selectedProjectId} = this.state;
		if (!selectedProjectId)
			return null;

		return <DomainToUsersList filterByProjectId={selectedProjectId}/>;
	};

	selectProject(projectId: string) {
		const newSelectedProject = this.projectsList.find((project) => {
			return project._id === projectId;
		}) || {_id: ''};

		this.setState({selectedProjectId: newSelectedProject._id});
	}

	private renderProjectNames = () => {
		const {selectedProjectId} = this.state;
		this.projectsList = ApiCaller_PermissionsProject.getProjects();

		if (!selectedProjectId && this.projectsList.length)
			this.setState({selectedProjectId: this.projectsList[0]._id});

		const projectNames = this.projectsList.map((project, index) => {
			const isOn = project._id === selectedProjectId;
			return <ProjectName key={index} onProjectSelected={this.selectProject} project={project} isOn={isOn}>{project}</ProjectName>;
		});

		return <div className={projectsCss.projectsListNamesContainer}>
			{projectNames}
		</div>;
	}

	render() {
		return <div className={projectsCss.projectsPageContainer}>
			<div className={projectsCss.projectsTitleStyle}>VIEW OF USERS' PERMISSIONS</div>
			<div className={projectsCss.sectionsWrapperStyle}>
				{this.renderProjects()}
			</div>
		</div>
	}
}
