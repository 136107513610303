import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {PermissionsGroupsContainer} from "./permissions/permissions-groups-container/PermissionsGroupsContainer";
import {PermissionsTabs} from "./PermissionsTabs";

export class Page_Permissions_Groups
	extends BaseComponent {

	constructor(props: {}) {
		super(props);
	}

	componentDidMount() {
		document.title = "Permission Groups"
	}

	render() {
		return <>
			<PermissionsGroupsContainer/>
			<PermissionsTabs/>
		</>;
	}
}
