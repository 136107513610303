import {BaseDB_ApiGeneratorCaller} from "@intuitionrobotics/db-api-generator/frontend";
import {
	ThunderDispatcher
} from "@intuitionrobotics/thunderstorm/frontend";
import {DfAgentBackup} from "@app/ir-q-app-common/types/df-agents";


export interface OnDfAgentsBackupLoaded {
	__onDfAgentsBackupLoaded: () => void;
}

const dispatch_onDfAgentsBackupLoaded = new ThunderDispatcher<OnDfAgentsBackupLoaded, "__onDfAgentsBackupLoaded">("__onDfAgentsBackupLoaded");

export class DfAgentsBackupModule_Class
	extends BaseDB_ApiGeneratorCaller<DfAgentBackup> {
	private dfBackups: DfAgentBackup[] = [];

	constructor() {
		super({key: "df-agent-backup", relativeUrl: "/v1/dialogflow/df-agent-backup"}, "DfAgentsBackupModule");
	}

	protected init(): void {
		super.init();
	}

	protected async onEntryCreated(response: DfAgentBackup): Promise<void> {
		this.query();
	}

	protected async onEntryDeleted(response: DfAgentBackup): Promise<void> {
		this.query();
	}

	protected async onEntryUpdated(response: DfAgentBackup): Promise<void> {
		this.query();
	}

	protected async onGotUnique(response: DfAgentBackup): Promise<void> {
	}

	protected async onQueryReturned(response: DfAgentBackup[]): Promise<void> {
		this.dfBackups = response;
		dispatch_onDfAgentsBackupLoaded.dispatchUI();
	}

	getDgAgentsBackups() {
		return this.dfBackups;
	}

}

export const ApiCaller_DfAgentsBackup = new DfAgentsBackupModule_Class();
