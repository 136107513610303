import * as React from "react";
import * as projectCss from "./css/permissionsProjectCss";
import {DB_PermissionProject} from "@intuitionrobotics/permissions";

type State = {
	edit?: boolean
}

type Props = {
	project: DB_PermissionProject,
	isOn: boolean,
	onProjectSelected: (src: string) => void;
}

export class ProjectName
	extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
	}

	render() {
		const {project, isOn, onProjectSelected} = this.props;
		const className = isOn ? projectCss.projectNameFlagOnStyle : projectCss.projectNameStyle;

		return <div onClick={() => {
			onProjectSelected(project._id)
		}} className={className}>{project.name}</div>;
	}
}
