import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {PermissionsProjects} from "./permissions/permissions-projects/PermissionsProjects";
import {PermissionsTabs} from "./PermissionsTabs";

export class Page_Permissions
	extends BaseComponent {

	constructor(props: {}) {
		super(props);
	}

	componentDidMount() {
		document.title = "Permission Manage"
	}

	render() {
		return <>
			<PermissionsProjects/>
			<PermissionsTabs/>
		</>;
	}
}
