import {
	ThunderDispatcher,
	XhrHttpModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {Api_MigrateFamilyMemberContactFact} from "@app/ir-q-app-common/types/api";

export interface OnMigrationComplete {
	__onMigrationComplete: () => void;
}

const dispatch_onMigrationComplete = new ThunderDispatcher<OnMigrationComplete, "__onMigrationComplete">("__onMigrationComplete");

export class ApiCaller_MigrateFMContacts_Class {

	runMigration(runMigration: boolean) {
		XhrHttpModule
			.createRequest<Api_MigrateFamilyMemberContactFact>(HttpMethod.POST, 'migrate-fm-contacts')
			.setJsonBody({runMigration})
			.setRelativeUrl("/v2/migration/familyMemberContacts-facts")
			.execute(() => {
				dispatch_onMigrationComplete.dispatchUI()
			});
	}
}

export const ApiCaller_MigrateFMContacts = new ApiCaller_MigrateFMContacts_Class();
