import {Module} from "@intuitionrobotics/ts-common";
import {
	ThunderDispatcher,
	XhrHttpModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {
	KasperoGSuiteAllListUsers,
	KasperoGSuiteDeleteUser,
	UserListQuery
} from "@app/ir-q-app-common/types/gsuite";
import {admin_directory_v1} from "googleapis";
import Schema$User = admin_directory_v1.Schema$User;
import Schema$Users = admin_directory_v1.Schema$Users;

export type GSuiteAccount = Schema$User;

export interface OnGSuiteListLoaded {
	__onGSuiteListLoaded: () => void;
}

const dispatch_onGSuiteListLoaded = new ThunderDispatcher<OnGSuiteListLoaded, "__onGSuiteListLoaded">("__onGSuiteListLoaded");

export class GSuiteModule_Class
	extends Module {
	private gSuiteList: Schema$User[] = [];

	getGSuiteList = () => this.gSuiteList;

	fetchGSuiteListWithNoAdmins = () => {
		const body: UserListQuery = {maxResults: 500};

		XhrHttpModule
			.createRequest<KasperoGSuiteAllListUsers>(HttpMethod.POST, 'all-list')
			.setRelativeUrl("/v1/gsuite/all-list")
			.setJsonBody(body)
			.execute(async (response: Schema$Users) => {
				if(response.users) {
					const gSuiteList: Schema$User[] = (response.users).filter((gSuite: Schema$User) => !gSuite.isAdmin && !gSuite.isDelegatedAdmin);
					this.gSuiteList = gSuiteList;
				}
				dispatch_onGSuiteListLoaded.dispatchUI();
			});
	}

	deleteGSuiteAccount = (email: string, callback = () => {
	}) => {
		XhrHttpModule
			.createRequest<KasperoGSuiteDeleteUser>(HttpMethod.GET, 'delete')
			.setUrlParams({email})
			.setRelativeUrl("/v1/gsuite/delete")
			.execute(async response => {
				this.fetchGSuiteListWithNoAdmins();
				callback();
			});
	}
}

export const GSuiteModule = new GSuiteModule_Class("GSuiteModule");
