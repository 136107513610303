import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {ProductsTabs} from "./ProductsTabs";
import {AddProduct} from "./products/AddProduct";

export class Page_Products
	extends BaseComponent {

	constructor(props: {}) {
		super(props);
	}

	componentDidMount() {
		document.title = "Product Manage"
	}

	render() {
		return <>
			<AddProduct/>
			<ProductsTabs/>
		</>;
	}
}
