import * as React from 'react'
import * as emotion from 'emotion'
import { ExportToCsv } from 'export-to-csv'
import { BaseComponent } from '@intuitionrobotics/thunderstorm/frontend'
import { OnPairedUnitsLoaded, UnitsModule } from '@modules/UnitsModule'
import { DeviceIdentity, FullUnitConfig } from '@app/ir-q-app-common/types/units'
import { createReadableTimestampObject, sortArray, Format_YYYYMMDD_HHmmss } from '@intuitionrobotics/ts-common'

const shortRowItem = emotion.css`
	margin-right: 10px;
	width: 60px;
	display: inline-block;
	white-space: nowrap;
`

const longRowItem = emotion.css`
	margin-right: 10px;
	width: 180px;
	display: inline-block;
	white-space: nowrap;
`

const inputStyle = emotion.css`
	border: 1px solid aquamarine;
  outline: none;
`

type Props = {}
type State = {
  filterPls: boolean
}

export class PairedList extends BaseComponent<Props, State> implements OnPairedUnitsLoaded {
  private inputRef: React.RefObject<HTMLInputElement> = React.createRef()
  private units: FullUnitConfig[] = []

  constructor(props: Props) {
    super(props)
    this.state = {
      filterPls: false,
    }
  }

  componentDidMount(): void {
    UnitsModule.fetchUnits()
  }

  __onPairedUnitsLoaded = () => this.forceUpdate()

  exportToCsv = () => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Paired List CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    }

    const csvExporter = new ExportToCsv(options)

    const unitsForCSV = this.units.map((unit) => {
      const tabletIdentity = unit.identities.find((identity) => identity.type === 'tablet')
      const somIdentity = unit.identities.find((identity) => identity.type === 'som')

      let tabletLastUpdateDate
      let somLastUpdateDate
      let pairedAuditAt

      if (tabletIdentity?._updated) {
        tabletLastUpdateDate = createReadableTimestampObject(Format_YYYYMMDD_HHmmss, tabletIdentity._updated).pretty
      }

      if (somIdentity?._updated) {
        somLastUpdateDate = createReadableTimestampObject(Format_YYYYMMDD_HHmmss, somIdentity._updated).pretty
      }

      if (unit._audit?.auditAt.timestamp) {
        pairedAuditAt = createReadableTimestampObject(Format_YYYYMMDD_HHmmss, unit._audit.auditAt.timestamp).pretty
      }

      return {
        unitId: unit.unitId,
        auditAt: pairedAuditAt,
        auditBy: unit._audit?.auditBy,
        tabletLastUpdate: tabletLastUpdateDate,
        somLastUpdate: somLastUpdateDate,
      }
    })
    csvExporter.generateCsv(unitsForCSV)
  }

  render() {
    this.units = UnitsModule.getUnits()
    const date = new Date()

    const dateFilter = Number(this.inputRef?.current?.value)
    if (dateFilter) {
      this.units = this.units.filter((unit) => {
        return (
          unit.identities.find((identity) => identity._updated && date.getTime() - identity._updated <= dateFilter) ===
          undefined
        )
      })
    }

    const unitItems = this.units.map((unit) => {
      const identities = sortArray(unit.identities, (identifier) => identifier.type)
      const pairsDetails = identities.map((identifier: DeviceIdentity) => (
        <React.Fragment key={identifier.serial}>
          <span className={shortRowItem}>{identifier.type}</span>
          <span className={longRowItem}>{identifier._updated}</span>
        </React.Fragment>
      ))
      return (
        <div key={unit.unitId}>
          <span className={shortRowItem}>{unit.product}</span>
          <span className={longRowItem}>{unit.unitId}</span>
          {pairsDetails}
        </div>
      )
    })

    return (
      <div style={{ marginTop: '50px' }} className={`ll_v_c`}>
        <div style={{ marginBottom: '20px' }} className={`ll_h_c`}>
          <button style={{ marginRight: '30px' }} onClick={this.exportToCsv}>
            Export to CSV
          </button>
          <div style={{ marginRight: '20px' }}>{`${this.units.length} rows,`}</div>
          <div style={{ marginRight: '10px' }}>{`Filter by milliseconds time`}</div>
          <div>
            <input
              title="updated"
              ref={this.inputRef}
              onBlur={() => this.setState({ filterPls: true })}
              className={inputStyle}
              type="number"
            />
          </div>
        </div>
        <div style={{ marginBottom: '50px' }}>{unitItems}</div>
      </div>
    )
  }
}
