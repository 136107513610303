import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {Versions} from "./units-activation/Versions";
import {UnitsActivationTabs} from "./UnitsActivationTabs";

export class Page_Versions
	extends BaseComponent {

	constructor(props: {}) {
		super(props);
	}

	componentDidMount() {
		document.title = "Versions"
	}

	render() {
		return <>
			<Versions/>
			<UnitsActivationTabs/>
		</>;
	}
}
