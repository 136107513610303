import * as emotion from "emotion";

export const customFieldContainer = emotion.css`
	margin-bottom: 30px;
	margin-top: 15px;
	padding: 15px;
	background-color: rgba(228, 231, 233, 0.4);
	border-radius: 3px;
`;

export const customFieldRow = emotion.css`
	margin-bottom: 5px;
`;

export const customFieldAddRow = emotion.css`
	margin-bottom: 10px;
`;