import {BaseDB_ApiGeneratorCaller} from "@intuitionrobotics/db-api-generator/frontend";
import {
	ThunderDispatcher
} from "@intuitionrobotics/thunderstorm/frontend";
import {DB_ContentVersion} from "@app/ir-q-app-common/types/unit-config";


export interface OnContentVersionsLoaded {
	__onContentVersionsLoaded: () => void;
}

const dispatch_onContentVersionsLoaded = new ThunderDispatcher<OnContentVersionsLoaded, "__onContentVersionsLoaded">("__onContentVersionsLoaded");

export class ContentVersionsModule_Class
	extends BaseDB_ApiGeneratorCaller<DB_ContentVersion> {
	private contentVersions: DB_ContentVersion[] = [];

	constructor() {
		super({key: "content-version-links", relativeUrl: "/v1/content-version-links"}, "ContentVersionsModule");
	}

	protected init(): void {
		super.init();
	}

	protected async onEntryCreated(response: DB_ContentVersion): Promise<void> {
		this.query();
	}

	protected async onEntryDeleted(response: DB_ContentVersion): Promise<void> {
		this.query();
	}

	protected async onEntryUpdated(response: DB_ContentVersion): Promise<void> {
		this.query();
	}

	protected async onGotUnique(response: DB_ContentVersion): Promise<void> {
	}

	protected async onQueryReturned(response: DB_ContentVersion[]): Promise<void> {
		this.contentVersions = response;
		dispatch_onContentVersionsLoaded.dispatchUI();
	}

	getContentVersions() {
		return this.contentVersions;
	}

}

export const ApiCaller_ContentVersions = new ContentVersionsModule_Class();
