import * as React from "react";
import {
	BaseComponent,
	RoutingModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {Route_Logout} from "../components/navigation-bar";
import * as emotion from "emotion";
import {
	Route_Permissions,
	Route_Permissions_Groups,
	Route_Permissions_Users,
	Route_Permissions_To_Users
} from "../components/logged-bar";

const logoutClass = emotion.css`
	position: fixed;
	top: 0;
	left: 0;
`;

const tab = emotion.css`
	margin-left: 30px;
	display: inline-block;
`;

export class PermissionsTabs
	extends BaseComponent {

	constructor(props: {}) {
		super(props);
	}

	render() {
		return <>
			<div className={logoutClass}>
				<a href={RoutingModule.getRoute(Route_Logout).path}>Logout</a>
				<div className={tab}>{RoutingModule.getLink(Route_Permissions_To_Users)}</div>
				<div className={tab}>{RoutingModule.getLink(Route_Permissions)}</div>
				<div className={tab}>{RoutingModule.getLink(Route_Permissions_Users)}</div>
				<div className={tab}>{RoutingModule.getLink(Route_Permissions_Groups)}</div>

			</div>
		</>;
	}
}
