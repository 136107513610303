// tslint:disable-next-line:no-import-side-effect
import './res/styles/styles.scss';

import {BeLogged, LogClient_Browser, Module} from "@intuitionrobotics/ts-common";
import {App} from "./app/App";
import {Thunder} from "@intuitionrobotics/thunderstorm/frontend";
import {LoginModule} from "@modules/LoginModule";
import {Frontend_ModulePack_Users} from '@intuitionrobotics/user-account/frontend';
import {EnvironmentModule} from '@modules/EnvironmentModule';
import {Frontend_ModulePack_Permissions, PermissionsFE} from "@intuitionrobotics/permissions/frontend";
import {UnitsModule} from "@modules/UnitsModule";
import {ServicesModule} from "@modules/ServicesModule";

BeLogged.addClient(LogClient_Browser);
// Terrible
PermissionsFE.setDefaultConfig({projectId: 'kaspero-backend'});

const modules: Module<any>[] = [
    LoginModule,
    EnvironmentModule,
    UnitsModule,
    ServicesModule
];

new Thunder()
    .setConfig(require("./config").config)
    .addModules(...Frontend_ModulePack_Users)
    .addModules(...Frontend_ModulePack_Permissions)
    .addModules(...modules)
    .setMainApp(App)
    .build();
