import * as React from "react";
import Select from "react-select";

type State = {
}

type Props = {
	height?: number | "auto",
	width?: number | "auto",
	value?: CustomSelectOption,
	onChange: (e: CustomSelectOption) => void
	options: CustomSelectOption[],
	menuDirection?: "auto" | "top" | "bottom",
	isSearchable?: boolean,
	isDisabled?: boolean,
	placeholder?: string
}

export type CustomSelectOption = {
	value: any,
	label: string
}

export class CustomDropdown
	extends React.Component<Props, State> {
	private readonly selectStyles: object;

	constructor(props: Props) {
		super(props);

		this.selectStyles = {
			container: (provided: any) => ({
				...provided,
				fontSize: "14px",
				outline: "none"
			}),
			menuList: () => ({
				zIndex: 9999,
				maxHeight: "200px",
				overflow: "auto",
				backgroundColor: "#f1f1f1"
			}),
			control: () => ({
				border: "1px solid #d5d8da",
				borderTop: "none",
				borderRight: "none",
				borderLeft: "none",
				display: "flex",
				fontSize: "14px",
				outline: "none",
				height: props.height || 23,
				width: props.width || 150,
			}),
			singleValue: (provided: any) => ({
				...provided,
				fontSize: "14px",
				fontWeight: 500
			}),
			input: (provided: any) => ({
				...provided,
			}),
			option: (provided: any, state: any) => ({
				...provided,
				color: "#363f46",
				backgroundColor: "unset",
				borderBottom: 'rgba(216, 216, 216, 0.5)',
				fontWeight: state.isSelected ? 500 : 100,
				':hover': {
					backgroundColor: 'rgba(216, 216, 216, 0.5)'
				}
			}),
			placeholder: () => ({
				color: "#babcbe",
				alignSelf: "center",
				fontStyle: "italic",
				fontSize: 12
			})
		};
	}

	render() {
		const { value, onChange, options, menuDirection, isSearchable, isDisabled, placeholder} = this.props;
		const menuPlacementDirection = menuDirection || "auto";
		return <Select
			isSearchable={isSearchable}
			isDisabled={isDisabled}
			menuPlacement={menuPlacementDirection}
			placeholder={placeholder}
			styles={this.selectStyles}
			value={value}
			onChange={(e) => onChange(e as CustomSelectOption)}
			options={options}
			components={{
				IndicatorSeparator: () => null
			}}
		/>
	}
}
