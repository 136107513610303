import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {ProductsTabs} from "./ProductsTabs";
import { PairedList } from "./products/PairedList";

export class Page_PairedUnits
	extends BaseComponent {

	constructor(props: {}) {
		super(props);
	}

	componentDidMount() {
		document.title = "Paired List"
	}

	render() {
		return <>
			<PairedList/>
			<ProductsTabs/>
		</>;
	}
}
