import * as emotion from "emotion";

export const apiContainer = emotion.css`
	padding-right: 100px;
	position: relative;
`;

export const apiCheckboxLabel = emotion.css`
	font-size: 12px;
`;


export const apiAreaContainer = emotion.css`
	display: flex;
	flex-direction: column;
	object-fit: contain;
	padding: 15px 0 35px 30px;
	margin-bottom: 15px;
	
  border-bottom: 1px solid rgba(0, 0, 0, 0.28);
  border-radius: 0;
`;

export const apisAreaContainer = emotion.css`
	display: flex;
	flex-direction: column;
	object-fit: contain;
	border-radius: 8px;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.23);
	padding: 15px 0 25px 00px;
	margin-bottom: 15px;
`;

export const ddlLabelStyle = emotion.css`
	font-family: Lato;
  font-size: 14px;
  color: #157dd2;
  margin-left: 10px;
`;

export const addLevelRow = emotion.css`
	width: 100%;
  margin: 20px 0 -25px -30px;
	padding: 0 0 30px 30px;
	border-bottom: 1px solid rgba(0,0,0,0.28);
`;

export const domainAndLevelContainer = emotion.css`
	position: relative;
	display: flex;
	margin-top: 20px;
`;

export const rowActions = emotion.css`
	position: absolute;
	cursor: pointer;
	right: 70px;
	bottom: 5px;
`;

export const newLevelRowActions = emotion.css`
	position: absolute;
	cursor: pointer;
	right: 45px;
	bottom: 5px;
`;

export const domainDiv = emotion.css`
	flex: auto;
	margin-right: 25px;
`;

export const levelDiv = emotion.css`
	flex: auto;
	margin-left: 25px;
`;
