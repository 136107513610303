import {BaseDB_ApiGeneratorCaller} from "@intuitionrobotics/db-api-generator/frontend";
import {
	ThunderDispatcher
} from "@intuitionrobotics/thunderstorm/frontend";
import {UnitEnvs} from "@app/ir-q-app-common/types/unit-env-config";


export interface OnUnitEnvsLoaded {
	__onUnitEnvsLoaded: () => void;
}

const dispatch_onUnitEnvsLoaded = new ThunderDispatcher<OnUnitEnvsLoaded, "__onUnitEnvsLoaded">("__onUnitEnvsLoaded");

export class UnitEnvsModule_Class
	extends BaseDB_ApiGeneratorCaller<UnitEnvs> {
	private unitEnvs: UnitEnvs[] = [];

	constructor() {
		super({key: "unit-envs", relativeUrl: "/v1/unit-envs"}, "UnitEnvsModule");
	}

	protected init(): void {
		super.init();
	}

	protected async onEntryCreated(response: UnitEnvs): Promise<void> {
		this.query();
	}

	protected async onEntryDeleted(response: UnitEnvs): Promise<void> {
		this.query();
	}

	protected async onEntryUpdated(response: UnitEnvs): Promise<void> {
		this.query();
	}

	protected async onGotUnique(response: UnitEnvs): Promise<void> {
	}

	protected async onQueryReturned(response: UnitEnvs[]): Promise<void> {
		this.unitEnvs = response;
		dispatch_onUnitEnvsLoaded.dispatchUI();
	}

	getUnitEnvs() {
		return this.unitEnvs;
	}

}

export const ApiCaller_UnitEnvs = new UnitEnvsModule_Class();
