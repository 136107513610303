import * as React from 'react';
import {UI_User} from "./AccountsList";
import {UserGroups} from "./UserGroups";
import {User_Group} from "@intuitionrobotics/permissions/shared/assign-types";
import {
	DialogButton_Cancel,
	DialogButton_Submit,
	DialogModule
} from "@intuitionrobotics/thunderstorm/frontend";

type Props = {
	users: UI_User[],
	onSave: (groups?: User_Group[]) => void
}

type State = {
	showSelectedAccounts: boolean,
	groups: User_Group[]
}

const confirmationButtons = (onSubmit: () => void, label?: string) => {
	const cancel = DialogButton_Cancel(DialogModule.close)
		.addStyle({border: "none", cursor: "pointer"});
	const submit = DialogButton_Submit(onSubmit, label)
		.addStyle({border: "none", cursor: "pointer"});
	return [cancel,
	        submit];
}

export class SetGroupForMultipleUsersDialog extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			showSelectedAccounts: false,
			groups: []
		}
	}

	toggleShowSelectedAccounts = () => {
		const {showSelectedAccounts} = this.state;
		this.setState({showSelectedAccounts: !showSelectedAccounts});
	}

	onGroupsUpdate = () => {} // no-op

	onSave = () => {
		this.props.onSave(this.state.groups);
		DialogModule.close();
	}

	renderAccounts = () => {
			const {users} = this.props;
			return (
				<details open={this.state.showSelectedAccounts} style={{maxWidth:"80%"}}>
					<summary onClick={this.toggleShowSelectedAccounts}>{users.length} accounts selected.</summary>
					<p style={{fontSize:"0.7rem"}}>{users.map(user=>user.email).join(", ")}</p>
				</details>
			)
	}

	render() {
		const {groups} = this.state;
		return <div style={{width:"50vw", padding:"1rem"}}>
			<div style={{textAlign:"center", color:"darkred", padding:"1rem"}}>CAUTION: Use only to <strong>add</strong> permission groups to multiple users.</div>
			{this.renderAccounts()}
			<UserGroups groups={groups} onGroupsUpdate={this.onGroupsUpdate} multiUserMode={true} />
			<div className={`ll_h_c`} style={{
				marginTop: "auto",
				justifyContent: "flex-end",
				padding: "12px 0"
			}}>
				{confirmationButtons(this.onSave, "Save").map(
					(button, idx) => <div key={idx}>
						<button
							className={button.className}
							style={button.style}
							onClick={button.action}>{button.content}</button>
					</div>)}
			</div>
		</div>
	}
}
