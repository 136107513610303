import {BaseDB_ApiGeneratorCaller} from "@intuitionrobotics/db-api-generator/frontend";
import {
	ThunderDispatcher
} from "@intuitionrobotics/thunderstorm/frontend";
import {DB_UnitMetadata} from "@app/ir-q-app-common/types/unit-config";


export interface OnUnitMetadataLoaded {
	__onUnitMetadataLoaded: () => void;
}

const dispatch_onUnitMetadataLoaded = new ThunderDispatcher<OnUnitMetadataLoaded, "__onUnitMetadataLoaded">("__onUnitMetadataLoaded");

export class UnitMetadataModule_Class
	extends BaseDB_ApiGeneratorCaller<DB_UnitMetadata<any>> {
	private unitMetadata: DB_UnitMetadata<any>[] = [];

	constructor() {
		super({key: "unit-metadata", relativeUrl: "/v1/unit-metadata"}, "UnitMetadataModule");
	}

	protected init(): void {
		super.init();
	}

	protected async onEntryCreated(response: DB_UnitMetadata<any>): Promise<void> {
		this.query();
	}

	protected async onEntryDeleted(response: DB_UnitMetadata<any>): Promise<void> {
		this.query();
	}

	protected async onEntryUpdated(response: DB_UnitMetadata<any>): Promise<void> {
		this.query();
	}

	protected async onGotUnique(response: DB_UnitMetadata<any>): Promise<void> {
	}

	protected async onQueryReturned(response: DB_UnitMetadata<any>[]): Promise<void> {
		this.unitMetadata = response;
		dispatch_onUnitMetadataLoaded.dispatchUI();
	}

	getUnitMetadata() {
		return this.unitMetadata;
	}

}

export const ApiCaller_UnitMetadata = new UnitMetadataModule_Class();
