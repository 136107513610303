import * as React from "react";
import {RoutingModule} from "@intuitionrobotics/thunderstorm/frontend";
import {Page_Login, Page_Logout} from "../pages/Account_Pages";
import {Login_Mobile} from "../pages/Login_Mobile";

export const Route_Login = "login";
export const Route_Register = "register";
export const Route_Logout = "logout";
export const Route_Home = "home";

export const registerRoutes = () => {
    RoutingModule.clearRoutes();
    RoutingModule.addRoute(Route_Logout, "/logout", Page_Logout);
    if (navigator.userAgent.toLowerCase().match(/mobile/i)) {
        RoutingModule.addRoute(Route_Login, "/", Login_Mobile).setExact(false);
    } else {
        RoutingModule.addRoute(Route_Login, "/", Page_Login).setExact(false);
    }
    // RoutingModule.addRoute(Route_Home, "/", Component_Login).setLabel("Home").setExact(false);
};

export class NavigationBar
    extends React.Component {

    render() {
        registerRoutes();

        return <div>{RoutingModule.getRoutesMap()}</div>
    }
}

