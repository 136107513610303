import * as React from "react";
import {
	BaseComponent,
	RoutingModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {Route_Logout} from "../components/navigation-bar";
import * as emotion from "emotion";
import {
	Route_Add_DB_Unit_Conf_Item,
	Route_Backed_Up_Agents_List,
	Route_PairedList,
	Route_Products,
	Route_Unit_Configurations
} from "../components/logged-bar";

const logoutClass = emotion.css`
	position: fixed;
	top: 0;
	left: 0;
`;

const tab = emotion.css`
	margin-left: 30px;
`;

export class ProductsTabs
	extends BaseComponent {

	constructor(props: {}) {
		super(props);
	}

	render() {
		return <>
			<div className={logoutClass}>
				<a href={RoutingModule.getRoute(Route_Logout).path}>Logout</a>
				<a className={tab} href={RoutingModule.getRoute(Route_Products).path}>Products</a>
				<a className={tab} href={RoutingModule.getRoute(Route_PairedList).path}>Paired list</a>
				<a className={tab} href={RoutingModule.getRoute(Route_Unit_Configurations).path}>Unit environments</a>
				<a className={tab} href={RoutingModule.getRoute(Route_Add_DB_Unit_Conf_Item).path}>Add DB Unit Conf</a>
				<a className={tab} href={RoutingModule.getRoute(Route_Backed_Up_Agents_List).path}>List of backed up agents</a>
			</div>
		</>;
	}
}
