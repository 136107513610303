import * as React from "react";
import * as domainsCss from "../permissions-domains/css/domainsCss";
import * as usersCss from "./css/permissionUsersCss";
import {
	BaseComponent,
	Dialog_Builder,
	FilterInput,
	RoutingModule,
	ToastModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {ApiCaller_PermissionsUser} from "@intuitionrobotics/permissions/frontend";
import {
	deepClone,
	sortArray
} from "@intuitionrobotics/ts-common";
import * as permissionsCss from "../css/permissionsCss";
import {UI_Account} from "@intuitionrobotics/user-account";
import {UserGroups} from "./UserGroups";
import {DB_PermissionsUser} from "@intuitionrobotics/permissions";
import {User_Group} from "@intuitionrobotics/permissions/shared/assign-types";
import {
	Route_Permissions_Users,
	ParamKey_UserPermissions
} from "../../../components/logged-bar";
import {getAllUsers} from "../permissions-utils";
import {SetGroupForMultipleUsersDialog} from "./SetGroupForMultipleUsersDialog";


export type UI_User = UI_Account & { userWithoutAccount?: boolean }

type State = {
	selectedAccount?: UI_User,
	filteredAccounts: UI_User[],
	multiSelectedAccounts: UI_User[],// maybe change collection to a hashmap instead of an array for better performance.
}

type Props = {}

export class AccountsList
	extends BaseComponent<Props, State> {
	private allUsers!: UI_User[];

	constructor(props: Props) {
		super(props);
		this.state = {
			filteredAccounts: [],
			multiSelectedAccounts: [],
		};
		this.allUsers = getAllUsers();
	}

	componentDidMount() {
		this.setUserUrlParam();
	}

	selectUser = (account: UI_User) => {
		const removeSelection = this.state.selectedAccount === account;
		this.setState({selectedAccount: removeSelection ? undefined : account});
		if (removeSelection) {
			RoutingModule.goToRoute(Route_Permissions_Users);
			return;
		}

		RoutingModule.goToRoute(Route_Permissions_Users, {[ParamKey_UserPermissions]: account.email});
	}

	hasMultipleSelection = (): boolean => (this.state.multiSelectedAccounts?.length || 0) > 1

	onSaveUserGroupsForMultipleUsers = async (groups: User_Group[] = []) => {
		const {multiSelectedAccounts} = this.state;
		const promises = multiSelectedAccounts.map(account => {
			const user = this.prepareUserForUpsert(account);
			user.groups = [...user.groups ?? [],
			               ...groups];
			return new Promise((resolve, reject) => {
				this.upsert(user).setHandleRequestSuccess(resolve).setHandleRequestFailure(reject);
			});
		});
		ToastModule.toastInfo("Multiple users permissions are being updated. Please wait...");
		try {
			await Promise.all(promises);
			ToastModule.toastSuccess(`Finished updating multiple users permissions.`);
		} catch (e) {
			ToastModule.toastError("There was a problem saving permissions.")
		}
	}

	openMultiUserModal = () => {
		const {multiSelectedAccounts} = this.state;
		new Dialog_Builder(<SetGroupForMultipleUsersDialog onSave={this.onSaveUserGroupsForMultipleUsers} users={multiSelectedAccounts}/>)
			.setTitle(<div style={{padding: "1rem"}}>Set Permission Groups</div>)
			.setAllowIndirectClosing(true)
			.show();
	}

	selectForMultiple = (account: UI_User) => {
		const {multiSelectedAccounts} = this.state;
		if (multiSelectedAccounts.includes(account)) {
			this.setState({multiSelectedAccounts: multiSelectedAccounts.filter(acc => acc !== account)});
		} else {
			this.setState({
				              multiSelectedAccounts: [...multiSelectedAccounts,
				                                      account]
			              });
		}
	}

	toggleSelectList = () => {
		const {multiSelectedAccounts, filteredAccounts} = this.state;
		const numFiltered = filteredAccounts?.length || 0;
		const numSelected = multiSelectedAccounts?.length || 0;
		if(numSelected < numFiltered) { // non selected, select all
			this.setState({multiSelectedAccounts: [...filteredAccounts]});
			return;
		}
		if(numSelected === numFiltered) {
			this.setState({multiSelectedAccounts: []});
			return;
		}
	}

	isMultiSelected = (account: UI_User) => this.state.multiSelectedAccounts.includes(account);


	upsert = (user: DB_PermissionsUser) => {
		if (user._id)
			return ApiCaller_PermissionsUser.update(user);
		else
			return ApiCaller_PermissionsUser.create(user);
	}

	renderFilter = () => {
		const filter = (user: UI_User) => [user.email];
		return <>
			<div style={{width: '90%', marginBottom: '20px', marginLeft: '20px'}}>
				<FilterInput placeholder={'Filter users'}
				             className={permissionsCss.filterInputClass}
				             filter={filter}
				             list={this.allUsers}
				             onChange={(filteredAccounts: UI_User[]) => this.setState({filteredAccounts})}/>
			</div>
			<div style={{textAlign: 'right'}}>
				<button
					onClick={this.openMultiUserModal}
					title="At least 2 users need to be selected."
					disabled={!this.hasMultipleSelection()}
					type="button">
					Set Permissions For Multiple Users
				</button>
			</div>
		</>
	}

	renderListHeader = () => {
		const {multiSelectedAccounts, filteredAccounts} = this.state;
		const hasMultiSelected = multiSelectedAccounts?.length !== 0 && (multiSelectedAccounts?.length === filteredAccounts?.length);
		return (
			<div style={{margin:"0.5rem 0", borderBlockEnd:"1px #ccc solid"}}>
				<div className={`${domainsCss.domainRow} ll_h_c`}>
					<div className={usersCss.userCheckBox}><input type="checkbox" title="Select / Deselect All" checked={hasMultiSelected} onChange={this.toggleSelectList}/></div>
					<div className={domainsCss.domainNameStyle}>Accounts</div>
				</div>
			</div>
		)
	}

	renderAccount = (account: UI_User) => {
		const isSelected = account === this.state.selectedAccount;
		return (
			<div className={account.userWithoutAccount ? usersCss.userWithoutAccountStyle : usersCss.userStyle} key={account._id}>
				<div className={`${domainsCss.domainRow} ll_h_c`}>
					<div className={usersCss.userCheckBox}><input type="checkbox" checked={this.isMultiSelected(account)} onChange={() => this.selectForMultiple(account)}/></div>
					<div onClick={() => this.selectUser(account)} className={isSelected ? domainsCss.domainOnNameStyle : domainsCss.domainNameStyle}>{account.email}</div>
				</div>
				{isSelected && this.renderGroupEditor(account)}
			</div>
		)
	}

	setUserUrlParam = () => {
		const urlUserParam = BaseComponent.getQueryParameter(ParamKey_UserPermissions);
		if (!this.state.selectedAccount && urlUserParam) {
			this.setState({selectedAccount: this.allUsers.find(account => account.email === urlUserParam)});
		}
	}

	render() {
		return <div className={domainsCss.domainContainer}>
			{this.renderFilter()}
			{this.renderListHeader()}
			{sortArray(this.state.filteredAccounts, account => account.email, true).map(this.renderAccount)}
		</div>;
	}

	private prepareUserForUpsert(account: UI_User) {
		const user = ApiCaller_PermissionsUser.getUserByAccountId(account._id)

		const tempUser = deepClone(user || {groups: [] as User_Group[], accountId: account._id} as DB_PermissionsUser)
		tempUser.groups = tempUser.groups || [];
		// @ts-ignore
		if (tempUser.groupIds)
			// @ts-ignore
			tempUser.groups = tempUser.groupIds.map(groupId => ({groupId}))

		// @ts-ignore
		delete tempUser.customKeys;
		return tempUser;
	}

	private renderGroupEditor(account: UI_User) {
		const tempUser = this.prepareUserForUpsert(account);
		return (
			<div className={domainsCss.domainOnItemStyle}>
				<UserGroups groups={tempUser.groups || []}
				            onGroupsUpdate={() => this.upsert(tempUser)}/>
			</div>
		)
	}
}
