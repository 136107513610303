import * as emotion from "emotion";


export const domainRowActionBackground = emotion.css`
	// background-color: #419af926;
`;

export const domainRow = emotion.css`
	cursor: pointer;
	// display: flex;
	// justify-content: space-between;
`;

export const domainItemStyle = emotion.css`
	font-family: Lato;
	font-size: 14px;
	padding: 10px 35px 10px 25px;
`;

export const domainHoverItemStyle = emotion.css`
	font-family: Lato;
	font-size: 14px;
	padding: 10px 35px 10px 25px;
	background-color: #eef4fa;
`;

export const newLevelTitle = emotion.css`
	// margin-left: 25px;
	margin-top: -18px;
  margin-left: 115px;
	cursor: pointer;
	width: fit-content;
`;

export const domainOnItemStyle = emotion.css`
	font-family: Lato;
	font-size: 14px;
	margin: 2px 100px 10px 15px;
  padding: 10px 10px 20px 10px;
	border-top: solid 1px #d5d8da;
	border-bottom: solid 1px #d5d8da;
`;

export const domainNameStyle = emotion.css`
	color: #3d474f;
	cursor: pointer;
`;

export const newLevelIcon = emotion.css`
	width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-bottom: 2px;
`;

export const domainOnNameStyle = emotion.css`
	color: #157dd2;
	cursor: pointer;
	font-weight: bold;
`;

export const domainContainer = emotion.css`
	margin-top: 15px;
	margin-bottom: 15px;
	overflow: auto;
	height: 100%;
`;
