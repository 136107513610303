import * as emotion from "emotion";



export const methodsContainerStyle = emotion.css`
	margin-top: 12px;
	margin-bottom: 22px;
`;

// export const overlay = emotion.css`
// 	position: fixed;
// 	width: 100%;
// 	height: 100%;
// `;

export const levelNameReadMode = emotion.css`
	color: #363f46;
`;

export const addMethodContainer = emotion.css`
	margin-right: 3px;
	height: 22px;
	width: 22px !important;
`;

export const addMethodIcon = emotion.css`
	vertical-align: middle;
	height: 22px;
	width: 22px;
	cursor: pointer;
`;

export const openAddMethodIcon = emotion.css`
	margin-left: 5px;
`;

export const updateLevelContainer = emotion.css`
	background-color: #f4f5f6;
  padding: 10px;
`;

export const inputContainer = emotion.css`
	// width: 10%;
	// transition: width 0.5s;
	margin-right: 10px;
	width: 115px;
`;

export const levelNameReadModeContainer = emotion.css`
	// width: 10%;
	// transition: width 0.5s;
	margin-right: 10px;
	min-width: 115px;
`;

export const domainInputStyle = emotion.css`
	::-webkit-input-placeholder {
		color: #babcbe
	};
	background-color: inherit;
	font-style: italic;
	font-size: 12px;
	outline: none;
	border-bottom: solid 1px rgba(0, 0, 0, 0.23);
	width: 100%;
`;

export const cancelTextStyle = emotion.css`
	color: #6c777f;
	font-size: 14px;
	margin-left: 15px;
	cursor: pointer;
`;

export const levelValueInputStyle = emotion.css`
	margin-left: 15px;
	width: 50px;
`;
