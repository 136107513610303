import * as React from "react";
import * as emotion from "emotion";
import {ApiCaller_Products, OnProductsLoaded} from "@modules/ApiCaller_Products";
import {CustomDropdown} from "../../components/CustomDropdown";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {Product} from "@app/ir-q-app-common/types/products";
import {PartialProperties} from "@intuitionrobotics/ts-common";

const textAreaStyle = emotion.css`
	height: 500px;
	width: 90%;
	font-family: monospace !important;
`;

const addBtnStyle = emotion.css`
	margin-top: 20px;
	width: 100px
`;

type Props = {};
type State = {
	ddlChosenProductId: string
};

export class AddProduct
	extends BaseComponent<Props, State>
	implements OnProductsLoaded {
	private inputRef: React.RefObject<HTMLTextAreaElement> = React.createRef();
	private ddlDefaultOption = {value: '', label: "Example - new product"};

	constructor(props: Props) {
		super(props);
		this.state = {
			ddlChosenProductId: ''
		}
	}

	componentDidMount() {
		ApiCaller_Products.query();
	}

	__onProductsLoaded = () => this.forceUpdate();

	addProduct = () => {
		const inputRef = this.inputRef.current;
		if (!inputRef)
			return;

		const productStringObt = inputRef.value;
		if (!productStringObt)
			return;

		const productJson = JSON.parse(productStringObt);
		ApiCaller_Products.create(productJson)
			.setOnError("error create product")
			.setOnSuccessMessage("product created successfully")
			.execute(res => {
				inputRef.value = "";
				ApiCaller_Products.query();
			});
	}

	getDdlOptions = () => {
		const arrayProducts = ApiCaller_Products.getProducts();
		return [...arrayProducts.map(product => {
			return {value: product._id, label: product.name};
		}),
			this.ddlDefaultOption];
	}

	render() {
		const {ddlChosenProductId} = this.state;
		const ddlChosenProduct = ApiCaller_Products.getProducts().find(p => p._id === ddlChosenProductId);

		const ddlValue = ddlChosenProduct ? {value: ddlChosenProduct._id, label: ddlChosenProduct.name} : this.ddlDefaultOption;
		const exampleProduct: PartialProperties<Product, "_id"> = {
			"key": "test",
			"name": "Test",
			"devices": {},
			"git": {
				"main": {
					"repoUrl": "git@github.com:intuition-robotics/ir-test-of-test.git",
					"repoFolder": "ir-test-of-test"
				},
				"submodules": {
					"plans": {
						"repoUrl": "git@github.com:intuition-robotics/ir-test-plans-test.git",
						"repoFolder": "ir-test-plans-test"
					}
				}
			},
			"repoConfig": {
				"default": {
					"main": {
						"repoUrl": "git@github.com:intuition-robotics/ir-test-of-test.git",
						"repoFolder": "ir-test-of-test"
					},
					"submodules": {
						"plans": {
							"repoUrl": "git@github.com:intuition-robotics/ir-test-plans-test.git",
							"repoFolder": "ir-test-plans-test"
						}
					}
				}
			},
			"gcp": {
				"envs": [
					{
						"pathToData": "envs/test",
						"bucket": "gs://test-env-dev.appspot.com",
						"projectId": "test-env-dev",
						"apiKey": "AIzaSyBa2opDpwHm8oYvn0Ckvj-test",
						"agents": {
							"reactive": "test-reactive",
							"nonReactive": "test-nonReactive"
						},
						"name": "dev"
					}
				],
				"mapping": {
					"pathToData": "envs/test",
					"bucket": "gs://test-env-mapping.appspot.com",
					"projectId": "test-env-dev",
					"apiKey": "AIzaSyBa2opDpwHm8oYvn0Ckvj-test",
					"name": "mapping",
					"agents": {
						"reactive": "test-reactive",
						"nonReactive": "test-nonReactive"
					}
				}
			}
		};

		const productObjText = ddlChosenProduct ? JSON.stringify(ddlChosenProduct) : JSON.stringify(exampleProduct);

		if (this.inputRef.current)
			this.inputRef.current.value = JSON.stringify(JSON.parse(productObjText), null, 2);

		return <div style={{marginTop: "50px"}} className={'ll_h_c'}>
			<div className={'ll_v_c'} style={{flex: 0.2, alignSelf: "flex-start", marginTop: "100px"}}>
				<div style={{width: "90%"}}>
					<h4>Example by select:</h4>
					<div style={{marginBottom: "10px"}}>
						<CustomDropdown placeholder="Select product..." options={this.getDdlOptions()} onChange={(option) => {
							this.setState({ddlChosenProductId: option.value});
						}} value={ddlValue}/>
					</div>
				</div>
			</div>
			<div className={'ll_v_c'} style={{flex: 0.6}}>
				<h2>Add Product</h2>
				<textarea className={textAreaStyle} ref={this.inputRef}>
					</textarea>
				<button className={addBtnStyle} onClick={this.addProduct}>Add</button>
			</div>
		</div>
	}
}
