

import * as React from 'react';
import {
	BaseComponent,
	Dialog,
	Toaster,
	Tooltip,
	WrapperProps
} from "@intuitionrobotics/thunderstorm/frontend";
import {NavigationBar} from './components/navigation-bar';
import {VersionOnScreen} from './components/version-on-screen';
import {
	AccountModule,
	LoggedStatus,
	OnLoginStatusUpdated
} from "@intuitionrobotics/user-account/frontend";
import {
	OnUnauthenticatedResponse
} from "@intuitionrobotics/thunderstorm/frontend";
import {BadImplementationException} from '@intuitionrobotics/ts-common';
import {Loader} from "./ui/Loader";
import {LoggedBar} from "./components/logged-bar";

export class App
	extends BaseComponent<WrapperProps>
	implements OnLoginStatusUpdated, OnUnauthenticatedResponse {

	public static dropBlocker<T>(ev: React.DragEvent<T>) {
		ev.preventDefault();
		ev.stopPropagation();
	};

	onLoginStatusUpdated = () => {
		this.forceUpdate()
	};

	onUnauthenticatedResponse = () => {
		this.forceUpdate()
	}

	render() {
		return (
			<div onDrop={App.dropBlocker} onDragOver={App.dropBlocker}>
				{this.renderLoginState()}

				<Dialog/>
				<Toaster/>
				<Tooltip/>
			</div>);
	}


	renderLoginState(): React.ReactNode {
		const status = AccountModule.getLoggedStatus();
		switch (status) {
			case LoggedStatus.LOGGED_IN:
				return this.renderApp();

			case LoggedStatus.LOGGED_OUT:
				return this.renderLogin();

			case LoggedStatus.VALIDATING:
				return <Loader/>;

			default:
				throw new BadImplementationException(`logged status can only be one of 'LOGGED_IN', 'LOGGED_OUT' or 'VALIDATING'`)
		}
	}

	renderLogin = () => {
		return <>
			<NavigationBar/>
			<VersionOnScreen/>
		</>
	};

	renderApp = () => {
		return <>
				<LoggedBar/>
				<VersionOnScreen/>
			</>
	}
}

