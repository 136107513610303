import * as React from "react";
import * as domainsCss from "../permissions-domains/css/domainsCss";
import {
	DB_PermissionDomain,
	DB_PermissionsGroup
} from "@intuitionrobotics/permissions";
import * as levelsCss from "../permissions-levels/css/permissionLevelsCss";
import {PermissionUnitLevels} from "../permissions-levels/PermissionUnitLevels";
import {CustomFields} from "../permissions-custom-fields/CustomFields";
import {StringMap} from "@intuitionrobotics/ts-common";
import { AreYouSureDialog } from "../AreYouSureDialog";

const icon__trash = require('@res/images/icn-trash.svg');


type State = {
	displayHoverClass: boolean,
	editMode: boolean,
	inputGroupNameChange: boolean
}

type Props = {
	isOn: boolean,
	group: DB_PermissionsGroup,
	domains: DB_PermissionDomain[],
	onGroupSelected: (src: string) => void;
	onDeleteGroup: (src: string) => void;
	onUpdateGroup: () => void;
}

export class GroupItem
	extends React.Component<Props, State> {
	private inputRef: React.RefObject<HTMLInputElement> = React.createRef();

	constructor(props: Props) {
		super(props);
		this.state = {
			displayHoverClass: false,
			editMode: false,
			inputGroupNameChange: false,
		}

		this.handleClickOnGroupName = this.handleClickOnGroupName.bind(this);
		this.handleGroupNameChange = this.handleGroupNameChange.bind(this);
	}

	deleteGroup = () => {
		const {onDeleteGroup, group} = this.props;
		new AreYouSureDialog(<div></div>, () => {onDeleteGroup(group._id)}, `Are you sure you want to delete group item: ${group.label}?`).show();
	}

	renderRowActions = () => {
		const {isOn} = this.props;
		if (!isOn) {
			return null;
		}

		return <div style={{position: "absolute", right: 0}}>
			<img onClick={(event) => {
				event.stopPropagation();
				this.deleteGroup()
			}} style={{marginLeft: '20px', verticalAlign: 'middle'}} height="24px" width="24px" src={icon__trash}/>
		</div>;
	}

	setDisplayHoverClass(value: boolean) {
		const {isOn} = this.props;
		if (isOn) {
			this.setState({displayHoverClass: false});
			return;
		}

		this.setState({displayHoverClass: value});
	}

	handleGroupSelected() {
		const {group, onGroupSelected} = this.props;
		onGroupSelected(group._id);
		this.setDisplayHoverClass(false);
	}

	handleInputOnBlur() {
		this.setState({editMode: false});
		this.updateGroup();
	}

	private updateGroup() {
		this.props.onUpdateGroup();
	}

	handleGroupNameChange() {
		const {group} = this.props;
		const groupName = this.inputRef?.current?.value;
		group.label = groupName || '';
		this.setState({inputGroupNameChange: true});
	}

	handleClickOnGroupName(event: React.MouseEvent) {
		const {isOn} = this.props;
		if (!isOn)
			return;

		event.stopPropagation();
		this.setState({editMode: true});
	}

	renderGroupHeader() {
		const {isOn, group} = this.props;
		const {editMode} = this.state;

		if (editMode) {
			return <div className={levelsCss.inputContainer}>
				<input style={{width: "300px"}} autoFocus value={group.label} onChange={this.handleGroupNameChange} onBlur={() => {
					this.handleInputOnBlur()
				}} ref={this.inputRef} className={levelsCss.domainInputStyle} placeholder={"Type domain name"}/>
			</div>;
		}

		return (
			<div onClick={() => this.handleGroupSelected()}
			     onMouseEnter={() => this.setDisplayHoverClass(true)}
			     onMouseLeave={() => this.setDisplayHoverClass(false)}
			     style={{position: "relative"}}
			     className={`${domainsCss.domainRow} ll_h_c`}>
				<div onClick={this.handleClickOnGroupName}
				     className={isOn ? domainsCss.domainOnNameStyle : domainsCss.domainNameStyle}>{group.label || 'Input name'}</div>
				{this.renderRowActions()}
			</div>
		);
	}

	renderCustomFields() {
		const {group, isOn} = this.props;
		if (!isOn)
			return null;

		return <>
			<div style={{color: '#3d474f'}}>Custom fields:</div>
			<CustomFields onCustomFieldsUpdate={(customFields: StringMap[]) => {
				const customFieldsForUpdate = customFields.filter(cf => Object.keys(cf).length !== 0);
				this.props.group.customFields = customFieldsForUpdate;
				this.props.onUpdateGroup();
			}} customFields={group.customFields || []}/>
		</>;
	}

	renderAccessLevels() {
		const {group, isOn, domains} = this.props;
		if (!isOn)
			return null;

		return (
			<PermissionUnitLevels
				updateUnit={(accessLevelIds: string[]) => {
					this.props.group.accessLevelIds = accessLevelIds;
					this.updateGroup();
				}}
				hideExistDomains={group.__accessLevels?.map(level => level.domainId) || []}
				domains={domains}
				accessLevelIds={group.accessLevelIds || []}/>
		);
	}

	render() {
		const {isOn} = this.props;
		const {displayHoverClass} = this.state;

		return <div className={isOn ? domainsCss.domainOnItemStyle : (displayHoverClass ? domainsCss.domainHoverItemStyle : domainsCss.domainItemStyle)}>
			{this.renderGroupHeader()}
			{this.renderAccessLevels()}
			{this.renderCustomFields()}
		</div>;
	}
}
