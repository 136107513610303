import * as React from "react";
import {
	ApiCaller_PermissionsAccessLevel,
	OnPermissionsLevelsLoaded
} from "@intuitionrobotics/permissions/frontend";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {DB_PermissionAccessLevel} from "@intuitionrobotics/permissions/shared/manager-types";
import {updateLevelDisplayMode} from "../../permissions-levels/UpdateLevel";
import {sortArray} from "@intuitionrobotics/ts-common";
import {LevelToUsersItem} from "./LevelToUsersItem";
import {
	AccountModule,
	OnAccountsLoaded
} from "@intuitionrobotics/user-account/frontend";

type State = {
	selectedLevelId: string,
	updateLevelMode: updateLevelDisplayMode
}

type Props = {
	filterByDomainId: string
}

export class LevelsToUsersList
	extends BaseComponent<Props, State>
	implements OnPermissionsLevelsLoaded, OnAccountsLoaded {
	private methods: DB_PermissionAccessLevel[];

	constructor(props: Props) {
		super(props);

		this.methods = [];
		this.state = {
			selectedLevelId: '',
			updateLevelMode: updateLevelDisplayMode.editLevel
		};

		this.selectLevel = this.selectLevel.bind(this);
	}

	__onAccountsLoaded = () => {
		this.forceUpdate();
	}

	__onPermissionsLevelsLoaded = () => {
		this.forceUpdate();
	};

	async componentDidMount() {
		ApiCaller_PermissionsAccessLevel.query();
		AccountModule.listUsers();
	}

	selectLevel(methodId: string) {
		const newSelectedMethod = this.methods.find((method) => {
			return method._id === methodId;
		}) || {_id: ''};

		this.setState({selectedLevelId: newSelectedMethod._id});
	}


	renderLevels() {
		const {filterByDomainId} = this.props;
		const {selectedLevelId} = this.state;
		this.methods = ApiCaller_PermissionsAccessLevel.getLevels(filterByDomainId);
		const methods = sortArray(this.methods, level => level.value, true).map((level) => {
			const isOn = level._id === selectedLevelId;
			return <LevelToUsersItem key={level._id} isOn={isOn} level={level} domainId={filterByDomainId} onLevelSelected={this.selectLevel}/>
		});

		return <div style={{marginTop: "15px"}}>{methods}</div>;
	}

	render() {
		return <>
			{this.renderLevels()}
		</>
	}
}
