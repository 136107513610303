import * as React from "react";
import * as levelCss from "./css/permissionsLevelCss";
import { User_Group } from "@intuitionrobotics/permissions";
import {AreYouSureDialog} from "../AreYouSureDialog";

const icon__remove = require('@res/images/icon_x.svg');

type State = {
	isHover: boolean
}

export type BulletData = {
	_id: string,
	name: string
}

type Props = {
	bulletData: BulletData,
	isOn: boolean,
	noConfirm?: boolean,
	onMethodSelected: (src: string, refObj?: User_Group) => void,
	onLevelDeleted: (src: string, refObj?: User_Group) => void,
	refObj?: User_Group
}


export class PermissionBulletData
	extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			isHover: false
		}
	}

	render() {
		const { bulletData, isOn, onMethodSelected, onLevelDeleted, refObj, noConfirm } = this.props;
		const { isHover } = this.state;
		const className = isOn ? levelCss.methodNameOnStyle : levelCss.methodNameStyle;

		return <div onMouseEnter={() => {this.setState({isHover: true})}} onMouseLeave={() => {this.setState({isHover: false})}} className={'ll_h_c'}>
			<div onClick={() => {onMethodSelected(bulletData._id, refObj)}} className={className}>{bulletData.name || 'Input name'}</div>
			{isHover && <div style={{marginLeft: '3px', cursor: 'pointer'}}><img alt="" onClick={() => {
				if(noConfirm)
					onLevelDeleted(bulletData._id, refObj);
				else
					new AreYouSureDialog(<div></div>, () => {onLevelDeleted(bulletData._id, refObj)}, `Are you sure you want to delete item: ${bulletData.name}?`).show();
			}} src={icon__remove}/></div>}
		</div>;
	}
}
