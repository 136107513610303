import * as React from 'react';
import * as emotion from "emotion";
import {KeyboardEvent} from 'react';

const error = require('@res/images/icn-warning.svg');

const labelStyle = emotion.css`
	color: #3B3B3B;
	font-size: 15px;
	font-weight: 300;
  letter-spacing: -0.41px;
  padding-bottom: 4px;
`;

const inputStyle = emotion.css`
	&:-internal-autofill-selected {
	    background-color: #2e304f !important;
	}
	background-color: inherit;
  padding-left: 10px;
  width: 87%;
  height: inherit;
  color: #000000e8;
  outline: none;
  ::placeholder {
    color: #979797;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.41px;
  }
`;

const inputContainerStyle = emotion.css`
	border: 0.9px solid #C0C0C0;
	margin-bottom: 20px;
	width: 100%;
	border-radius: 10px;
	height: 50px;
`;

type Props<Key> = {
	onChange: (value: string, id: Key) => void
	onAccept?: () => void

	disabled: boolean
	value?: string
	error?: string
	label: string
	type: 'text' | 'number' | 'password'
	placeholder?: string
	icon?: string
	height?: number
	id: Key
}

type State = {}

export class NewStyledInput<Key extends string>
	extends React.Component<Props<Key>, State> {
	static defaultProps: Partial<Props<"">> = {
		disabled: false,
	};

	constructor(props: Props<Key>) {
		super(props);

		this.state = {};
	}

	changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onChange(event.target.value, event.target.id as Key)
	};

	handleKeyPress = (event: KeyboardEvent) => {
		if (!this.props.onAccept)
			return;

		if (event.keyCode === 13)
			this.props.onAccept();

	};

	render() {
		const {label, id, type, value, placeholder} = this.props;
		let style = {height: "50px", borderBottom: ""}
		if (this.props.error)
			style={borderBottom: "1px solid #e6004a", height: "50px"};
		if (this.props.height)
			style.height = this.props.height + "px";

		return (
			<div key={id} className="ll_v_l">
				<div className={labelStyle}>{`${label}`}</div>
				<div className={`ll_h_c`} style={{width: "100%"}}>
					<div className={inputContainerStyle} style={style}>
						{this.props.icon && <img src={this.props.icon}/>}
						<input
							key={id}
							id={id}
							className={inputStyle}
							type={type}
							value={value || ''}
							placeholder={placeholder || label}
							onChange={this.changeValue}
							onKeyPress={this.handleKeyPress}
							autoComplete={'off'}
							// onFocus={}
							//// onBlur={}
						/>
					</div>
					{this.props.error && <img src={error} style={{marginBottom: 25, width: "13%"}}/>}
				</div>
			</div>
		);
	}

}
