

import {Module} from "@intuitionrobotics/ts-common";

type Config = {
	kasperoUrl: string
}

export class EnvironmentModule_Class
	extends Module<Config> {

	constructor() {
		super("EnvironmentModule");
	}

	getOrigin = () => {
		return `${this.getOriginUrl()}${this.getPathname()}${this.getSearch()}`;
	};

	getPathname() {
		return window.location.pathname
	}

	getKasperoUrl() {
		return this.config.kasperoUrl;
	}

	getOriginUrl() {
		return window.location.origin;
	}

	getQuery() {
		return window.location.pathname + window.location.search;
	}

	getSearch() {
		const search = window.location.search;
		return search.startsWith('?') ? search.slice(1) : search
	}
}

export const EnvironmentModule = new EnvironmentModule_Class();
