import {BaseDB_ApiGeneratorCaller} from "@intuitionrobotics/db-api-generator/frontend";
import {
	ThunderDispatcher
} from "@intuitionrobotics/thunderstorm/frontend";
import {Product} from "@app/ir-q-app-common/types/products";


export interface OnProductsLoaded {
	__onProductsLoaded: () => void;
}

const dispatch_onProductsLoaded = new ThunderDispatcher<OnProductsLoaded, "__onProductsLoaded">("__onProductsLoaded");

export class ProductsModule_Class
	extends BaseDB_ApiGeneratorCaller<Product> {
	private products: Product[] = [];

	constructor() {
		super({key: "product", relativeUrl: "/v1/product"}, "ProductsModule");
	}

	protected init(): void {
		super.init();
	}

	protected async onEntryCreated(response: Product): Promise<void> {
		this.query();
	}

	protected async onEntryDeleted(response: Product): Promise<void> {
		this.query();
	}

	protected async onEntryUpdated(response: Product): Promise<void> {
		this.query();
	}

	protected async onGotUnique(response: Product): Promise<void> {
	}

	protected async onQueryReturned(response: Product[]): Promise<void> {
		this.products = response;
		dispatch_onProductsLoaded.dispatchUI();
	}

	getProducts() {
		return this.products;
	}

}

export const ApiCaller_Products = new ProductsModule_Class();
