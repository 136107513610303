import {BaseDB_ApiGeneratorCaller} from "@intuitionrobotics/db-api-generator/frontend";
import {
	ThunderDispatcher
} from "@intuitionrobotics/thunderstorm/frontend";
import {UnitEnvConfigItem} from "@app/ir-q-app-common/types/unit-env-config";


export interface OnEnvConfItemsLoaded {
	__onEnvConfItemsLoaded: () => void;
}

const dispatch_onEnvConfItemsLoaded = new ThunderDispatcher<OnEnvConfItemsLoaded, "__onEnvConfItemsLoaded">("__onEnvConfItemsLoaded");

export class UnitEnvConfModule_Class
	extends BaseDB_ApiGeneratorCaller<UnitEnvConfigItem> {
	private confItems: UnitEnvConfigItem[] = [];

	constructor() {
		super({key: "unit-env-conf-item", relativeUrl: "/v1/unit-env-conf-item"}, "UnitEnvConfModule");
	}

	protected init(): void {
		super.init();
	}

	protected async onEntryCreated(response: UnitEnvConfigItem): Promise<void> {
		this.query();
	}

	protected async onEntryDeleted(response: UnitEnvConfigItem): Promise<void> {
		this.query();
	}

	protected async onEntryUpdated(response: UnitEnvConfigItem): Promise<void> {
		this.query();
	}

	protected async onGotUnique(response: UnitEnvConfigItem): Promise<void> {
	}

	protected async onQueryReturned(response: UnitEnvConfigItem[]): Promise<void> {
		this.confItems = response;
		dispatch_onEnvConfItemsLoaded.dispatchUI();
	}

	getConfItems() {
		return this.confItems;
	}

}

export const ApiCaller_UnitEnvItems = new UnitEnvConfModule_Class();
