import * as emotion from "emotion";

export const projectNameStyle = emotion.css`{
		height: 60px;
		font-size: 16px;
    color: #363f46;
    font-family: Lato;
    padding: 0 10px 0 10px;
    line-height: 71px;
    cursor: pointer;
    :hover{
			background-color: rgba(216, 216, 216, 0.5);
			border-radius: 0 0 4px 4px;
		}
`;

export const projectNameFlagOnStyle = emotion.css`{
	height: 60px;
	font-size: 16px;
  color: white;
  font-family: Lato;
	background-color: #157dd2;
	padding: 0 10px 0 10px;
	line-height: 71px;
	border-radius: 0 0 4px 4px;
  background-color: #157dd2;
  cursor: pointer;
`;