import * as React from "react";
import { Dialog_Builder, DialogButton_Cancel, DialogButton_Save, DialogModule } from "@intuitionrobotics/thunderstorm/frontend";

export class AreYouSureDialog {
	private title: React.ReactNode = "";
	private actionBtnLabel: string = "";
	private action: () => void = () => {};
	private content: React.ReactNode;

	constructor(content: React.ReactNode, saveAction: () => void, title: React.ReactNode, actionBtnLabel?: string) {
		this.setContent(content);
		this.setSaveAction(saveAction);
		this.setTitle(title);
		this.setActionBtnLabel(actionBtnLabel || 'Delete');
	}

	setContent = (content: React.ReactNode) => {
		this.content = content;
		return this;
	}

	setTitle = (title: React.ReactNode) => {
		this.title = title;
		return this;
	}

	setActionBtnLabel = (setActionBtnLabel: string) => {
		this.actionBtnLabel = setActionBtnLabel;
		return this;
	}

	setSaveAction = (action: () => void) => {
		this.action = action;
		return this;
	}

	show = () => {
		new Dialog_Builder(this.content)
			.setAllowIndirectClosing(true)
			.setTitle(this.title)
			.setButtons(DialogButton_Cancel().build(), DialogButton_Save(() => {
				this.action();
				DialogModule.close();
			}, this.actionBtnLabel).build())
			.show();
	}
}
