import * as React from "react";
import * as apiCss from "./css/permissionsApiCss";
import {ApiCaller_PermissionsApi, OnPermissionsApisLoaded} from "@intuitionrobotics/permissions/frontend";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/app-frontend/core/BaseComponent";
import {
	DB_PermissionAccessLevel,
	DB_PermissionApi,
	DB_PermissionDomain
} from "@intuitionrobotics/permissions";
import {
	LevelIdObject,
	PermissionsDomainLevel
} from "./PermissionsDomainLevel";
import {Loader} from "../../../ui/Loader";
import {filterDuplicates} from "@intuitionrobotics/ts-common";
import {AreYouSureDialog} from "../AreYouSureDialog";

const icon__trash = require('@res/images/icn-trash.svg');
const icon__add = require('@res/images/icon_add_blue.svg');

type State = {
	openAddLevelArea: boolean,
	showLoader: boolean,
	deprecatedCheckbox: boolean,
	appCheckbox: boolean
}

type Props = {
	filterByProjectId: string,
	api: DB_PermissionApi,
	levels: DB_PermissionAccessLevel[],
	apis: DB_PermissionApi[],
	domains: DB_PermissionDomain[]
}

export class PermissionsApi
	extends BaseComponent<Props, State>
	implements OnPermissionsApisLoaded {
	private referenceLevelArray: LevelIdObject[];

	constructor(props: Props) {
		super(props);
		this.referenceLevelArray = [];
		this.state = {
			openAddLevelArea: false,
			showLoader: false,
			deprecatedCheckbox: !!props.api.deprecated,
			appCheckbox: !!props.api.onlyForApplication
		}
	}

	__onPermissionsApisLoaded = () => {
		this.setState({showLoader: false});
	}

	getLevel = (objectId: string) => {
		const {levels} = this.props;
		return levels.find((item) => {
			return item._id === objectId;
		});
	};

	handleLevelUpdated = (level: LevelIdObject, deleteOriginalLevelId: string) => {
		const {api} = this.props;
		const accessLevelIds: string[] = filterDuplicates(
			this.referenceLevelArray.filter(referenceLevel => referenceLevel.id).map(referenceLevel => referenceLevel.id));

		const existLevels = api.accessLevelIds || [];
		const newSelectedApiLevels = filterDuplicates([...existLevels, ...accessLevelIds].filter(levelId => levelId !== deleteOriginalLevelId));
		const toUpdate = {_id: api._id, path: api.path, accessLevelIds: newSelectedApiLevels, projectId: api.projectId};
		this.setState({showLoader: true, openAddLevelArea: false});
		ApiCaller_PermissionsApi.update(toUpdate);
	};

	renderApiLevels = () => {
		const {api, filterByProjectId, domains} = this.props;
		const apiLevelsIds = api.accessLevelIds || [];

		this.referenceLevelArray = apiLevelsIds.map((levelId) => {
			return {id: levelId}
		});
		this.referenceLevelArray.unshift({id: ''});

		const apiLevels = apiLevelsIds.map((levelId) => {
			const level = this.getLevel(levelId);
			if (!level)
				return null;

			const referenceLevel = this.referenceLevelArray.find((levelObj) => {
				return levelObj.id === level._id
			}) || {id: ''};

			return <PermissionsDomainLevel domains={domains} domainId={level.domainId} key={referenceLevel.id} filterByProjectId={filterByProjectId}
			                               levelIdObject={referenceLevel} onLevelUpdated={this.handleLevelUpdated}/>
		});

		return <>
			{this.renderAddLevelHeader()}
			{this.renderLevels(apiLevels, api)}
		</>;
	};

	private renderLevels(apiLevels: any[], api: DB_PermissionApi) {
		if (apiLevels.length === 0)
			return;

		return <div className="ll_h_c">
			<div className={apiCss.addLevelRow}>{apiLevels}</div>
			{apiLevels.length > 1 && <img style={{cursor: "pointer", marginRight: "10px"}} onClick={() => {
				ApiCaller_PermissionsApi.update({...api, accessLevelIds: []});
			}} height="24px" width="24px" src={icon__trash}/>}
		</div>;
	}

	renderAddLevelHeader = () => {
		return <div style={{position: "relative"}} className="ll_v_l">
			<div className="ll_h_c">
				{this.renderApiTitle()}
				{this.renderAddLevelAction()}
				{this.renderDeprecatedAction()}
				{this.renderApplicationAction()}
				{this.renderDeleteApiAction()}

			</div>
			<div className="match_width">{this.renderNewLevelRow()}</div>
		</div>;
	};

	renderApiTitle = () => {
		return <div style={{display: "inline-block"}}>{this.props.api.path}</div>;
	};

	renderNewLevelRow() {
		const {filterByProjectId, domains} = this.props;
		if (!this.state.openAddLevelArea)
			return null;

		return <PermissionsDomainLevel domains={domains}
		                               hideRowActions={true}
		                               domainId={''}
		                               filterByProjectId={filterByProjectId}
		                               levelIdObject={this.referenceLevelArray[0]}
		                               onCloseNewRow={() => {
			                               this.setOpenAddLevelArea(false)
		                               }} onLevelUpdated={this.handleLevelUpdated}/>;
	}

	setOpenAddLevelArea(value: boolean) {
		this.setState({openAddLevelArea: value});
	}

	renderAddLevelAction() {
		return <img style={{cursor: "pointer", marginLeft: "10px"}} onClick={() => {
			this.setOpenAddLevelArea(true)
		}} src={icon__add}/>;
	}

	renderDeprecatedAction() {
		return <div style={{marginLeft: "15px", position: "absolute", right: "150px"}}>
			<label className={apiCss.apiCheckboxLabel} htmlFor="deprecated">Deprecated</label>
			<input checked={this.state.deprecatedCheckbox} onChange={(event) => {
				const checkedValue = event.target.checked;
				ApiCaller_PermissionsApi.update({...this.props.api, deprecated: checkedValue});
				this.setState({deprecatedCheckbox: checkedValue});
			}} type="checkbox" id="deprecated" name="deprecated"/>
		</div>;
	}

	renderApplicationAction() {
		return <div style={{marginLeft: "5px", position: "absolute", right: "90px"}}>
			<label className={apiCss.apiCheckboxLabel} htmlFor="application">App</label>
			<input checked={this.state.appCheckbox} onChange={(event) => {
				const checkedValue = event.target.checked;
				ApiCaller_PermissionsApi.update({...this.props.api, onlyForApplication: checkedValue});
				this.setState({appCheckbox: checkedValue});
			}} type="checkbox" id="application" name="application" />
		</div>;
	}

	renderDeleteApiAction() {
		return <img style={{cursor: "pointer", position: "absolute", right: "50px"}} onClick={() => {
			new AreYouSureDialog(<div></div>, () => {ApiCaller_PermissionsApi.delete(this.props.api._id)}, `Are you sure you want to delete api item: ${this.props.api.path}?`).show();
		}} height="24px" width="24px" src={icon__trash}/>;
	}

	renderLoader() {
		const {showLoader} = this.state;
		if (!showLoader)
			return;

		return <Loader/>;
	}

	render() {
		return <div className={apiCss.apiAreaContainer}>
			{this.renderApiLevels()}
			{this.renderLoader()}
		</div>;
	}
}
