import * as React from "react";
import * as apiCss from "./css/permissionsApiCss";
import * as permissionsCss from "../css/permissionsCss";
import {CustomDropdown, CustomSelectOption} from "../../../components/CustomDropdown";
import {
	OnPermissionsDomainsLoaded,
	OnPermissionsLevelsLoaded,
	ApiCaller_PermissionsAccessLevel
} from "@intuitionrobotics/permissions/frontend";
import { BaseComponent } from "@intuitionrobotics/thunderstorm/app-frontend/core/BaseComponent";
import { DB_PermissionDomain, DB_PermissionAccessLevel } from "@intuitionrobotics/permissions/shared/manager-types";
import {AreYouSureDialog} from "../AreYouSureDialog";

const icon__remove = require('@res/images/icon_x.svg');


type State = {
	selectedDomainId: string,
	levelSelected: boolean
}

export type LevelIdObject = {
	id: string
}

type Props = {
	filterByProjectId: string,
	levelIdObject: LevelIdObject,
	onLevelUpdated: (level: LevelIdObject, deleteLevelId: string) => void,
	onLevelDeleted?: (level: LevelIdObject) => void,
	onCloseNewRow?: () => void,
	domainId?: string,
	isDisabled?: boolean,
	hideRowActions?: boolean,
	hideExistDomains?: string[],
	domains: DB_PermissionDomain[]
}

export class PermissionsDomainLevel
	extends BaseComponent<Props, State>
	implements OnPermissionsDomainsLoaded, OnPermissionsLevelsLoaded {
	private domains: DB_PermissionDomain[];
	private levels: DB_PermissionAccessLevel[];

	static defaultProps: Partial<Props> = {
		isDisabled: false,
		domainId: '',
		hideRowActions: false
	};

	constructor(props: Props) {
		super(props);
		this.domains = [];
		this.levels = [];
		this.state = {
			selectedDomainId: '',
			levelSelected: false
		}
	}

	componentDidMount() {
		const {domainId} = this.props;
		if (domainId)
			this.setState({selectedDomainId: domainId});
	}

	__onPermissionsDomainsLoaded = () => {
		this.forceUpdate();
	};

	__onPermissionsLevelsLoaded = () => {
		this.forceUpdate();
	};

	handleDomainSelected = (option: CustomSelectOption) => {
		this.setState({selectedDomainId: option.value});
		ApiCaller_PermissionsAccessLevel.query();
	}

	handleLevelUpdated = (levelId: string) => {
		const { levelIdObject, onLevelUpdated } = this.props;
		const { selectedDomainId } = this.state;
		if(!selectedDomainId)
			return;

		const deleteOriginalLevelId = levelIdObject.id;
		levelIdObject.id = levelId;
		onLevelUpdated(levelIdObject, deleteOriginalLevelId);
		this.setState({levelSelected: true});
	}

	getDDlOptions(labelKey: string, valueKey: string, array: any[]) {
		return array.map((item) => {
			return {label: item[labelKey], value: item[valueKey]}
		});
	}

	getDomain(objectId: string) {
		return this.domains.find((item) => {
			return item._id === objectId;
		});
	}

	getLevel(objectId: string) {
		return this.levels.find((item) => {
			return item._id === objectId;
		});
	}

	renderRowActions() {
		const {hideRowActions} = this.props;
		if (hideRowActions) {
			return this.renderNewLevelRowAction();
		}

		return <div className={apiCss.rowActions}>
			<img onClick={() => {
				new AreYouSureDialog(<div></div>, () => {this.handleLevelUpdated('')}, `Are you sure you want to delete level?`).show();
			}} height="15px" width="15px" src={icon__remove}/>
		</div>;
	}

	renderNewLevelRowAction() {
		const {onCloseNewRow} = this.props;
		if (!onCloseNewRow)
			return;

		return <div className={apiCss.newLevelRowActions}>
			<span onClick={() => {onCloseNewRow()}} style={{marginLeft: "25px"}} className={permissionsCss.cancelTextStyle}>close</span>
		</div>;
	}

	setDisplayDomains() {
		const { filterByProjectId, hideExistDomains, domains } = this.props;
		const projectDomains = domains.filter(domain => domain.projectId === filterByProjectId);
		this.domains = hideExistDomains ? projectDomains.filter(projectDomain => !hideExistDomains.find(existDomainId => projectDomain._id === existDomainId)): projectDomains;
	}

	render() {
		const { levelIdObject, isDisabled, hideRowActions, onCloseNewRow } = this.props;
		const { selectedDomainId } = this.state;
		this.setDisplayDomains();
		this.levels = ApiCaller_PermissionsAccessLevel.getLevels(selectedDomainId);
		const selectedDomain = this.getDomain(selectedDomainId) || {namespace: '', _id: ''};
		const selectedLevel = this.getLevel(levelIdObject.id) || {name: '', _id: ''};
		const isPaddingRight = !!(onCloseNewRow || !hideRowActions);
		return <>
			<div style={{paddingRight: isPaddingRight ? '100px': 0}} className={apiCss.domainAndLevelContainer}>
				<div className={apiCss.domainDiv}>
					<div className={apiCss.ddlLabelStyle}>Domain</div>
					<CustomDropdown isDisabled={isDisabled} value={{label: selectedDomain.namespace, value: selectedDomain._id}} isSearchable={true} menuDirection={"top"} placeholder={"Choose"}
					                onChange={this.handleDomainSelected} options={this.getDDlOptions("namespace", "_id", this.domains)}/>
				</div>
				<div className={apiCss.levelDiv}>
					<div className={apiCss.ddlLabelStyle}>Level</div>
					<CustomDropdown isDisabled={isDisabled} value={{label: selectedLevel.name, value: selectedLevel._id}} isSearchable={true} menuDirection={"top"} placeholder={"Choose"}
					                onChange={(option) => {this.handleLevelUpdated(option.value)}} options={this.getDDlOptions("name", "_id", this.levels)}/>
				</div>
				{this.renderRowActions()}
			</div>
		</>;
	}
}
