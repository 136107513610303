import * as React from "react";
import * as emotion from "emotion";
import {AppPage} from "@intuitionrobotics/thunderstorm/frontend";

export const icons = {
	background: require('@res/images/loginBg.svg'),
	lines: require('@res/images/lines.svg'),
	mobileBackground: require('@res/images/mobile_login_background.svg'),
	desktopBackground: require('@res/images/desktop_login_background.svg')
};

const backgroundStyle = emotion.css`
	background: url(${icons.background}) no-repeat center center fixed;
	background-size: 49%;
	height: ${window.innerHeight}px;
`;

// background: url(${icons.mobileBackground}) no-repeat top center fixed;
export const mobileBackgroundStyle = emotion.css`
	height: ${window.innerHeight}px;
`;

const containerStyle = emotion.css`
	position: relative;
  top: ${window.innerHeight > 1000 ? '10%' : '5%'};
`;

const welcomeStyle = emotion.css`
	color: #224270;
	font-size: 36px;
  font-weight: 200;
  letter-spacing: -1px;
  padding-bottom: 10px;
  text-align: center;
`;

const logoStyle = emotion.css({position: "fixed", top: 28, right: 35});

// const subStyle = emotion.css`
// 	color: #224270;
// 	font-size: 20px;
//   font-weight: 200;
// 	letter-spacing: -0.56px;
//   text-align: center;
//   margin-top: 5px;
// `;

const IRLogo = () => <img src={require('@res/images/IRLOGO.svg')} className={logoStyle}/>;

export abstract class LoginsParent<P = {}, S = {}>
	extends AppPage<P, S> {

	constructor(props: P, title?: string) {
		super(props, title);
	}

	renderContent = () => <></>;

	render() {
		return (
			<div className={backgroundStyle}>
				<IRLogo/>
				<div className={containerStyle}>
					<div className={welcomeStyle}>Welcome</div>
					{/*<div className={subStyle}>to Intuition Robotics where all</div>*/}
					{/*<div className={subStyle}>the coolest stuff happen</div>*/}
					{this.renderContent()}
				</div>
			</div>
		);
	}
}
