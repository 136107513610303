import * as emotion from "emotion";

export const projectsTitleStyle = emotion.css` {
	height: 28px;
	font-family: Lato;
	font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #3d474f;
	margin-top: 5%;
`;

export const projectsListContainer = emotion.css` {
	display: flex;
	min-width: 400px;
	flex-direction: column;
	object-fit: contain;
	border-radius: 8px;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.23);
	margin-top: 25px;
	padding-right: 15px;
	padding-left: 15px;
	overflow: hidden;
	margin-bottom: 15px;
`;

export const sectionsWrapperStyle = emotion.css`
	max-width: 70%;
	min-width: 50%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 10px;
`;

export const projectsListNamesContainer = emotion.css` {
	height: 60px;
	min-height: fit-content;
	display: flex;
	overflow-x: auto;
  align-items: stretch;
  align-content: stretch;
`;

export const projectsPageContainer = emotion.css` {
		// position: fixed;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
`;
