

import {Module} from "@intuitionrobotics/ts-common";

type Config = {
	services:ServiceDetails[]
}
export type ServiceDetails = { name: string; key: string; link: string; logo: string };

export class ServicesModule_Class
	extends Module<Config> {

	getServices(){
		return this.config.services
	}
}

export const ServicesModule = new ServicesModule_Class("ServicesModule");
